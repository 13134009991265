var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "c-grid"
  }, [!_vm.isReadOnly ? _c('div', {
    staticClass: "col-full mb-2"
  }, [_c('ButtonCreate', {
    staticClass: "pr-2",
    attrs: {
      "disabled": _vm.shouldDisableAddToListButton,
      "small": ""
    },
    on: {
      "click": _vm.onClickOfHardwareListPartReferenceCreate
    }
  }, [_vm._v(" Add to list ")]), _c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE],
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showTemplate = !_vm.showTemplate;
      }
    }
  }, [_vm.showTemplate ? [_vm._v("Hide template")] : [_vm._v("Show template")]], 2)], 1) : _vm._e(), !_vm.tableItems.length ? _c('div', {
    staticClass: "col-full mb-2"
  }, [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v("No hardware items yet.")])], 1) : _vm._e(), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.tableItems.length ? _c('div', {
    staticClass: "col-full"
  }, [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "headers": _vm.computedTableHeaders,
      "items": _vm.tableItems,
      "loading": _vm.isFetching,
      "rows-per-page-items": [-1],
      "disable-initial-sort": "",
      "dense-rows": "",
      "hide-icon": "",
      "hide-pagination": "",
      "show-count": "",
      "sticky": "",
      "title": "List items"
    },
    on: {
      "dblclick": item => _vm.onClickOfHardwareListPartReferenceInfo(item)
    },
    scopedSlots: _vm._u([{
      key: "_hardwareTypeIcon",
      fn: function ({
        item
      }) {
        return [item._isTemplate ? _c('c-icon-fa', {
          attrs: {
            "tooltip": "Templated item",
            "tooltip-position": "right",
            "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE],
            "icon-color": "var(--v-item_instances-base)"
          }
        }) : !item._partReference || !item._partReference.itemInstanceId ? _c('c-icon-fa', {
          attrs: {
            "icon": "fa-regular fa-grid",
            "icon-color": "var(--v-item_instances-base)",
            "tooltip": "List item is a non-COSMIC item",
            "tooltip-position": "right"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
            "icon-color": "var(--v-item_instances-base)",
            "tooltip": "List item is a COSMIC instance",
            "tooltip-position": "right"
          }
        })];
      }
    }, {
      key: "_partReference._location",
      fn: function ({
        item
      }) {
        return [!item._partReference || item._partReference._location && item._partReference._location.includes('not_applicable') ? _c('span', {
          staticClass: "not_applicable monospace_font",
          attrs: {
            "tooltip": "This is not applicable."
          }
        }, [_vm._v(" N/A ")]) : _c('span', [_vm._v(_vm._s(item._partReference._location))])];
      }
    }, {
      key: "_quantity",
      fn: function ({
        item
      }) {
        return [item._isTemplate ? _c('div', [_c('span', {
          staticClass: "monospace_font font-weight-bold"
        }, [_vm._v(_vm._s(item._minQuantity))]), _vm._v("/"), _c('span', {
          staticClass: "monospace_font font-weight-bold"
        }, [_vm._v(_vm._s(item._maxQuantity))])]) : _c('div', [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._quantity) + " ")])])];
      }
    }, {
      key: "_partReference._itemClasses",
      fn: function ({
        item
      }) {
        return [item._isTemplate ? _c('div', [_c('span', {
          staticClass: "not_applicable monospace_font",
          attrs: {
            "tooltip": "This is not applicable."
          }
        }, [_vm._v(" N/A ")])]) : item._partReference && !item._partReference._itemClasses.length ? _c('div', [_vm._v(_vm._s(_vm.DEFAULT_DASH))]) : item._partReference && item._partReference._itemClasses ? _vm._l(item._partReference._itemClasses, function (itemClass, idx) {
          return _c('div', {
            key: itemClass
          }, [idx > 0 ? [_vm._v(",")] : _vm._e(), _c('span', {
            staticClass: "monospaced_font"
          }, [_vm._v(_vm._s(itemClass))])], 2);
        }) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [!_vm.isReadOnly && item._isTemplate && !item.isFilled ? _c('ButtonMiniAction', {
          attrs: {
            "disabled": _vm.isSaving,
            "icon": "fad fa-fill-drip",
            "icon-color": "var(--v-success-base)",
            "icon-size": "22",
            "tooltip-position": "left",
            "tooltip": "Fill this template slot with inventory data"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfTemplateSlotToFill(item);
            }
          }
        }) : _vm._e(), !item._isTemplate ? _c('ButtonMiniActionInfo', {
          attrs: {
            "disabled": _vm.isSaving
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListPartReferenceInfo(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && !item._isTemplate ? _c('ButtonMiniActionEdit', {
          attrs: {
            "disabled": _vm.isSaving
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListPartReferenceToEdit(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && item._isTemplate ? _c('ButtonMiniActionEdit', {
          attrs: {
            "disabled": _vm.isSaving
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfTemplateSlotToEdit(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && !item._isTemplate ? _c('ButtonMiniActionRemoveFromList', {
          attrs: {
            "disabled": _vm.isSaving
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListPartToRemove(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 2621090027)
  })], 1) : _vm._e()]), _vm.showHardwareListPartInfoModal ? _c('ModalHardwareListPartInfo', {
    key: _vm.selectedHardwareListPartReference.id,
    attrs: {
      "hardware-list-id": _vm.hardwareListId,
      "hardware-list-part-id": _vm.selectedHardwareListPartReference.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showCreateHardwareListPartModal ? _c('ModalHardwareListAddToList', {
    attrs: {
      "hardware-list-id": _vm.hardwareListId
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showHardwareListPartEditModal && _vm.selectedHardwareListPartReference ? _c('ModalHardwareListPartEdit', {
    key: _vm.selectedHardwareListPartReference.id,
    attrs: {
      "hardware-list-id": _vm.hardwareListId,
      "hardware-list-part-id": _vm.selectedHardwareListPartReference.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };