import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { uniqBy } from 'lodash';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentRelationType, DocumentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import BaseModalWorkOrderStepCompletion from '~/nasa_ui/base/BaseModalWorkOrderStepCompletion';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { IS_EXTERNAL_ICON_COL, SHELF_LIFE_EXP_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { DocumentHardwareListRepo } from '~/nasa_ui/DAL/document/HardwareLists';
import { setRelatedDocumentsMutation } from '~/nasa_ui/DAL/document/relatedDocuments';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { AlertType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
import { STANDARD_HARDWARE_COLUMNS, StepApprovalTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformHardwareItems } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalWorkOrderStepReject = class ModalWorkOrderStepReject extends Mixins(BaseCosmic, BaseModalWithUser, BaseModalWorkOrderStepCompletion, DocumentHardwareListRepo, EmailNotificationRepoMixin) {
    authorizedDate = null;
    changeSet = null;
    commentText = null;
    expandedTableItem = null;
    formDataProp = {};
    originalDocument = null;
    rejectionNeedsDr = false;
    savedDr = null;
    // previous MF said this is okay for now
    selectedCalloutHardware = [];
    stepGeneratedDr = null;
    tableHeaders = [...STANDARD_HARDWARE_COLUMNS, IS_EXTERNAL_ICON_COL, SHELF_LIFE_EXP_DATE_COL];
    changeSetId;
    get approvalsTableHeaders() {
        const headers = [...StepApprovalTableHeaders].filter((header) => header.value !== '_stepNumber');
        return this.isRca || this.isWorkflow ? headers.slice(0, 5) : headers;
    }
    get changeSetNextAuthorityGroupName() {
        return this.changeSet?.status?.nextAuthority?.userGroup?.name;
    }
    get documentSubTypeDisplay() {
        return this.changeSet?.document?.subType
            ? DocumentTypeDisplay.get(this.changeSet?.document?.subType)?.replace('(COSMIC)', '')
            : '';
    }
    get gqlRejectAuthorityInput() {
        if (!this.changeSet?.status?.nextAuthority) {
            return null;
        }
        const input = {
            authorizedDate: this.authorizedDate,
            changeSetAuthorityId: this.changeSet?.status?.nextAuthority?.id,
            comment: this.commentText,
            status: ChangeSetStatus.REJECTED
        };
        return input;
    }
    get hardwareTableItems() {
        if (!this.originalDocumentHardware) {
            return [];
        }
        return transformHardwareItems(this.originalDocumentHardware).map((item) => {
            const tableItem = {
                ...item
            };
            if (tableItem.isExternal) {
                tableItem._itemNumber = tableItem._itemNumber = tableItem.externalDrawingNumber ?? DEFAULT_DASH;
                tableItem._serialNumber = tableItem.externalSerialNumber ?? DEFAULT_DASH;
                tableItem._lotNumber = tableItem.externalLotNumber ?? DEFAULT_DASH;
                tableItem._description = tableItem.externalDescription ?? DEFAULT_DASH;
            }
            tableItem._shelfLifeExp = this.$dateDisplay(tableItem.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.valueDueDate || tableItem.externalShelfLifeDate);
            tableItem._isExternal = this.$booleanIconDisplay(tableItem.isExternal);
            return tableItem;
        });
    }
    get isFormValid() {
        return true;
    }
    get isRca() {
        return this.originalDocument?.subType === DocumentType.C_RCA;
    }
    get isWorkflow() {
        return this.originalDocument?.subType === DocumentType.C_WORKFLOW;
    }
    get originalDocumentHardware() {
        if (!this.originalDocument) {
            return [];
        }
        if (!this.sourceDoc) {
            return [];
        }
        const flatList = this.sourceDoc.combinedHardwareList.nodes.flatMap((list) => list?.hardwareListAssemblies);
        return uniqBy(flatList, (i) => i?.itemInstance ? i.itemInstance.id : i?.externalSerialNumber);
    }
    get rejectButtonText() {
        const onBehalfText = this.changeSetNextAuthorityGroupName
            ? ` on behalf of ${this.changeSetNextAuthorityGroupName}`
            : '';
        return `Reject ${this.stepDisplayText} ${onBehalfText}`;
    }
    get shouldDisableRejectButton() {
        return this.isSaving || (this.shouldRequireComment && !this.commentText);
    }
    get shouldShowCreateDrButton() {
        const hasPermission = this.currentUserHasPermission(UserPermission.DOCUMENT_ADMIN && UserPermission.C_DOCS_DR_ADMIN);
        const hasDocumentAndDocumentTypeIsTps = this.originalDocument && this.originalDocument.cosmicDocumentTps;
        return hasPermission && hasDocumentAndDocumentTypeIsTps;
    }
    get sourceDoc() {
        return this.originalDocument?.cosmicDocumentDr ?? this.originalDocument?.cosmicDocumentTps;
    }
    get stepDisplayText() {
        let text = '';
        switch (this.originalDocument?.subType) {
            case DocumentType.C_WORKFLOW:
                text = 'Task';
                break;
            case DocumentType.C_RCA:
                text = 'CAP task';
                break;
            default:
                text = 'Step';
                break;
        }
        return text;
    }
    async createHardwareListAssembly(mutationVariables) {
        return await this.$apollo.mutate({
            mutation: gql `
        mutation CreateHardwareListAssemblyForManage($hardwareListAssembly: HardwareListAssemblyInput!) {
          createHardwareListAssembly(input: { hardwareListAssembly: $hardwareListAssembly }) {
            clientMutationId
          }
        }
      `,
            variables: {
                hardwareListAssembly: mutationVariables
            }
        });
    }
    async fetchChangeSet(changeSetId) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${this.changeSetFragment}
          ${CosmicDocumentSlimFragment}
          ${CosmicContactSlimFragment}

          fragment ChangeSetDocumentOnWorkOrderStepReject on Document {
            ...CosmicDocumentSlim

            initiatorContact {
              ...CosmicContactSlim
            }
          }

          query FetchChangeSetOnRejectModal($changeSetId: Int!) {
            changeSetById(id: $changeSetId) {
              ...ChangeSetOnWorkOrderStepCompletion

              document {
                ...ChangeSetDocumentOnWorkOrderStepReject
              }
            }
          }
        `,
                variables: {
                    changeSetId
                }
            });
            if (resp.data.changeSetById) {
                this.changeSet = this.transformWorkOrderStepChangeSet(resp.data.changeSetById);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async fetchDocumentDetails() {
        const resp = await this.$apollo.query({
            query: gql `
        ${CosmicContactSlimFragment}

        query FetchDocumentInformation($id: UUID!) {
          documentById(id: $id) {
            id
            nodeId
            subType
            cosmicDocumentDr {
              id
              nodeId
              contractNumber
              computedStatus
              description
              incidentDate
              incidentDescription
              initializationStatus
              initiatorContactId
              number
              projectCode
              subType
              tags
              title

              hardwareList {
                id
                nodeId

                hardwareListAssemblies {
                  nodes {
                    id
                  }
                }
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }
          }
        }
      `,
            variables: {
                id: this.stepGeneratedDr?.id
            }
        });
        this.savedDr = resp?.data?.documentById?.cosmicDocumentDr;
    }
    async getOriginalDocument() {
        const data = await this.getDocumentWithAssociatedHardware({ id: this.changeSet?.document?.id });
        this.originalDocument = data?.documentById;
    }
    //  called from UI
    async onDocumentCreated(document) {
        this.stepGeneratedDr = document;
        this.fetchDocumentDetails();
        await this.setRelatedDocuments();
        this.selectedCalloutHardware.forEach((hardwareListAssemblyItem, index) => {
            const mutationVariables = {
                addendumDate: hardwareListAssemblyItem._addendumDate === DEFAULT_DASH ? null : hardwareListAssemblyItem._addendumDate,
                addendumNumber: hardwareListAssemblyItem._addendumNumber === DEFAULT_DASH ? null : hardwareListAssemblyItem._addendumNumber,
                assemblyTemplateId: hardwareListAssemblyItem?._assemblyTemplateId,
                externalCalibrationNumber: hardwareListAssemblyItem?.externalCalibrationNumber ?? null,
                externalDescription: hardwareListAssemblyItem?.externalDescription ?? null,
                externalDrawingNumber: hardwareListAssemblyItem?.externalDrawingNumber ?? null,
                externalIsTool: hardwareListAssemblyItem?.externalIsTool ?? null,
                externalLotNumber: hardwareListAssemblyItem?.externalLotNumber ?? null,
                externalSerialNumber: hardwareListAssemblyItem?.externalSerialNumber ?? null,
                hardwareListId: this.savedDr?.hardwareList?.id,
                inventoryId: hardwareListAssemblyItem?.inventory ? hardwareListAssemblyItem?.inventory.id : null,
                isExternal: hardwareListAssemblyItem?.isExternal ?? false,
                notes: hardwareListAssemblyItem._notes === DEFAULT_DASH ? null : hardwareListAssemblyItem._notes,
                quantity: hardwareListAssemblyItem._inventoryQuantity === DEFAULT_DASH
                    ? 0
                    : hardwareListAssemblyItem._inventoryQuantity ?? 0,
                sequence: index + 1
            };
            try {
                this.isSaving = true;
                // leaving off the message since we are doing this for each
                this.createHardwareListAssembly(mutationVariables);
            }
            catch (err) {
                this.$errorUtility({ err });
            }
        });
        this.isSaving = false;
    }
    async setRelatedDocuments() {
        // mutation variables for adding the document as a source document on DR
        const mutationVariablesForAddingToDR = {
            forDocumentId: this.stepGeneratedDr?.id,
            relatedDocuments: [
                {
                    relationType: DocumentRelationType.SOURCE_DOCUMENT,
                    documentId: this.changeSet?.document?.id
                }
            ]
        };
        try {
            this.isSaving = true;
            const resp = await setRelatedDocumentsMutation(mutationVariablesForAddingToDR);
            if (resp?.data?.setRelatedDocuments) {
                this.$notification.add({ text: 'Added related Documents.', type: AlertType.SUCCESS });
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    updateChangeSetAuthorityMutation(input) {
        if (!input) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation UpdateChangeSetAuthorityOnStepRejection($input: UpdateChangeSetAuthorityInput!) {
          updateChangeSetAuthority(input: $input) {
            changeSetAuthority {
              id
              nodeId
            }
          }
        }
      `,
            variables: {
                input
            }
        });
    }
    // reject next authority
    async onClickOfRejectWorkOrderStep() {
        try {
            if (this.gqlRejectAuthorityInput) {
                this.isSaving = true;
                const resp = await this.updateChangeSetAuthorityMutation(this.gqlRejectAuthorityInput);
                if (resp?.data) {
                    this.$notification.add({ type: AlertType.SUCCESS, text: 'Step rejected.' });
                    this.$message('workorder-steps-updated');
                    this.sendNotificationOfRejectedTaskEmail(this.gqlRejectAuthorityInput);
                }
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
    }
    onChangeSetChange() {
        this.formDataProp = {
            contractNumber: this.currentUserActiveSupportContext,
            description: '',
            initiatorContactId: this.currentUser?.contactId,
            isExportControlled: false,
            isPracaReportable: null,
            isProprietary: false,
            projectCode: this.changeSet?.document?.projectCode,
            tags: [],
            title: `cDR for work order step # ${this.changeSet?.workOrderStep?.stepNumber} on cTPS ${this.changeSet?.document?.number}`
        };
    }
    async onChangeSetIdChange() {
        await this.fetchChangeSet(this.changeSetId);
        this.getOriginalDocument();
    }
    sendNotificationOfRejectedTaskEmail(rejectionInput) {
        const worfklowTitle = this.changeSet?.document.title;
        const workflowDocumentId = this.changeSet?.document.id ?? undefined;
        const workflowInitiatorContactId = this.changeSet?.document.initiatorContactId ?? undefined;
        const workflowNumber = this.changeSet?.document.number;
        const workflowTaskOverviewUrl = `${window.location.origin}${window.location.pathname}#tasks_overview`;
        const STYLES = `
      <link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,400' rel='stylesheet'>
      
      <style>
        html {
          font-family: 'Roboto';
        }

        .mt-2 {
          margin-top:8px!important
        }

        .mb-2 {
          margin-bottom:8px!important
        }
        
        h2 { margin-bottom: 0;}
        h3 { margin-bottom: 0;}
      </style>
    `;
        try {
            this.createEmailNotification({
                emailDetails: {
                    attributes: {
                        documentId: workflowDocumentId
                    },
                    data: {},
                    messageTextTemplate: '',
                    messageHtmlTemplate: `
          ${STYLES}
          
          <p>
            Task rejected on <a href="${workflowTaskOverviewUrl}">${workflowNumber} - ${worfklowTitle}</a> by ${this.currentUserFirstLastNameDisplay}.
          </p>

            
          <h3>Rejection comment (<small>${this.$dateTimeDisplay(rejectionInput.authorizedDate || new Date())}</small>)</h3>


          ${markdownToHtml(rejectionInput.comment)}
          `,
                    recipientContactId: [workflowInitiatorContactId],
                    subjectTemplate: `${this.currentSystemName} Workflow task rejection ${workflowNumber} - ${worfklowTitle}`
                }
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Number,
        required: true
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepReject.prototype, "changeSetId", void 0);
__decorate([
    Emit('step-rejected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepReject.prototype, "onClickOfRejectWorkOrderStep", null);
__decorate([
    Watch('changeSet', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepReject.prototype, "onChangeSetChange", null);
__decorate([
    Watch('changeSetId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepReject.prototype, "onChangeSetIdChange", null);
ModalWorkOrderStepReject = __decorate([
    Component
], ModalWorkOrderStepReject);
export default ModalWorkOrderStepReject;
