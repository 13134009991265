import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UsState } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UsStateDisplay } from '~/nasa_ui/types/enums/us-states';
import { getLocation, putLocation } from '~/nasa_ui/utils';
import { transformContactResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabLocationsInformation = class TabLocationsInformation extends Mixins(BaseTab) {
    UsState = UsState;
    UsStateDisplay = UsStateDisplay;
    selectedEntity = null;
    formData = {
        address: null,
        bin: null,
        building: null,
        city: null,
        contactId: null,
        contractNumber: null,
        isArchived: null,
        isOffEarth: null,
        phone: null,
        room: null,
        state: null,
        stock: null,
        zip: null
    };
    get contactInformation() {
        const transformedContact = this.selectedEntity && this.selectedEntity.contactId
            ? transformContactResponses([this.selectedEntity.contact])[0]
            : null;
        return transformedContact ? transformedContact._displayName : DEFAULT_DASH;
    }
    get contractDisplay() {
        return this.selectedEntity?.contractNumber || DEFAULT_DASH;
    }
    get hasContract() {
        return Boolean(this.formData?.contractNumber);
    }
    get locationContainsInventory() {
        // TODO: this should be provided by the api https://gitlab.com/mri-technologies/cosmic/api/-/issues/342
        // return Boolean(this.selectedEntity?.inventories?.length);
        return true;
    }
    get hasRequiredFields() {
        const requiredFields = ['bin', 'building', 'stock'];
        return requiredFields.every((field) => {
            if (!this.formData) {
                return false;
            }
            return !!this.formData[field];
        });
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get isLocationArchived() {
        return Boolean(this.selectedEntity?.isArchived);
    }
    get isLocationManaged() {
        return Boolean(this.selectedEntity?.contractNumber);
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid;
    }
    created() {
        this.$listen(`reload_${this.id}`, this.fetchEntityById);
    }
    async onClickOfSave() {
        if (this.isFormValid) {
            try {
                const body = {
                    address: this.formData.address,
                    bin: this.formData.bin,
                    building: this.formData.building,
                    city: this.formData.city,
                    contactId: this.formData.contactId,
                    contractNumber: this.formData.contractNumber,
                    isArchived: this.formData.isArchived,
                    isOffEarth: this.formData.isOffEarth,
                    phone: this.formData.phone,
                    room: this.formData.room,
                    state: this.formData.state ?? undefined,
                    stock: this.formData.stock,
                    zip: this.formData.zip
                };
                await putLocation(this.id, body);
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Saved.'
                });
                this.$message(`reload_${this.id}`);
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
    }
    onContractRemoval() {
        if (!this.formData) {
            return;
        }
        this.formData.contractNumber = null;
    }
    async fetchEntityById() {
        if (!this.id) {
            return;
        }
        this.isFetching = true;
        const resp = await getLocation(this.id);
        this.selectedEntity = resp?.data;
        this.isFetching = false;
    }
    onSelectedEntityChange() {
        if (this.selectedEntity) {
            this.formData = {
                address: this.selectedEntity.address,
                bin: this.selectedEntity.bin,
                building: this.selectedEntity.building,
                city: this.selectedEntity.city,
                contactId: this.selectedEntity.contactId,
                contractNumber: this.selectedEntity.contractNumber,
                isArchived: this.selectedEntity.isArchived,
                isOffEarth: this.selectedEntity.isOffEarth,
                phone: this.selectedEntity.phone,
                room: this.selectedEntity.room,
                state: this.selectedEntity.state,
                stock: this.selectedEntity.stock,
                zip: this.selectedEntity.zip
            };
        }
    }
    // called from ui
    onLocationDeleted() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => this.$router.push(`/${EntityType.LOCATION}`), 2000);
    }
};
__decorate([
    Watch('selectedEntity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabLocationsInformation.prototype, "onSelectedEntityChange", null);
TabLocationsInformation = __decorate([
    Component
], TabLocationsInformation);
export default TabLocationsInformation;
