var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserRole } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { ContactRepoMixin } from '~/nasa_ui/DAL/contact';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { AlertType, EntityType, Maybe } from '~/nasa_ui/types';
import { UserPermission, UserRoleDisplay } from '~/nasa_ui/types/enums/user';
import { UserTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabUserInformation = class TabUserInformation extends Mixins(BaseTab, ContactRepoMixin, HttpMixin, UserRepoMixin) {
    delegates = null;
    previousDelegateIds = [];
    isDeleting = false;
    selectedDelegateForRemoval = null;
    shouldAlsoDeleteContact = true;
    showDelegateModal = false;
    showDeletionModal = false;
    user = null;
    userForDelegate = null;
    UserRole = UserRole;
    UserRoleDisplay = UserRoleDisplay;
    UserTableHeaders = [...UserTableHeaders];
    overrideRowsPerPageItems = [
        20,
        50,
        {
            text: '$vuetify.dataIterator.rowsPerPageAll',
            value: -1
        }
    ];
    adminFormData = {
        role: null
    };
    userInformationFormData = {
        auid: null,
        email: [],
        firstName: null,
        lastName: null,
        middleName: null,
        uupic: null
    };
    hideAuidField;
    hideUupicField;
    get codeForUserDelegateGroup() {
        const delegateGroup = this.user?.userGroups?.find((group) => group.delegateGroupForUserId === this.userId);
        return delegateGroup?.code || '';
    }
    get doesSelectedUserSupportCurrentContext() {
        return Boolean((this.user?.supports || []).filter((support) => support.organizationCode === this.currentUserActiveSupportContext)
            .length);
    }
    get existingDelegateIds() {
        return (this.delegates || []).map((delegate) => delegate?.id);
    }
    get isEditingYourself() {
        return this.currentUserId === this.$route.params.id;
    }
    get isFormValid() {
        return Boolean(this.userInformationFormData.email?.length);
    }
    get isRoleAdmin() {
        return this.currentUserIsAdmin || this.currentUserIsSystem;
    }
    get isUserAdmin() {
        return this.currentUserHasPermission(UserPermission.USER_ADMIN) || this.currentUserIsAdmin;
    }
    get isSelectedDelegateYourself() {
        return this.selectedDelegateForRemoval?.id === this.user?.id;
    }
    get selectedUserHasCurrentContextAccess() {
        return ((this.user?.supports || []).filter((ctx) => ctx?.organizationCode === this.currentUserActiveSupportContext)
            .length === 1);
    }
    get shouldShowInputs() {
        return !this.isReadOnly || this.isEditingYourself || this.isRoleAdmin;
    }
    get shouldShowDeleteUser() {
        return this.user?.isPendingFirstLogin && this.isRoleAdmin;
    }
    get shouldShowUserRoleInput() {
        return this.isRoleAdmin;
    }
    get supportContextsPlayload() {
        const supports = this.user?.supports?.map((support) => {
            return {
                permissions: support.permissions || {},
                contextOrganizationCode: support.organizationCode || ''
            };
        }) || [];
        supports.push({
            permissions: {},
            contextOrganizationCode: this.currentUserActiveSupportContext || ''
        });
        return supports;
    }
    get tabNamePrefix() {
        return this.isEditingYourself ? 'My ' : '';
    }
    get userId() {
        return this.user?.id;
    }
    get userInformationPayload() {
        const payload = this.userInformationFormData
            ? {
                id: this.$route.params.id,
                auid: this.userInformationFormData.auid || null,
                email: this.userInformationFormData.email,
                firstName: this.userInformationFormData.firstName,
                lastName: this.userInformationFormData.lastName,
                middleName: this.userInformationFormData.middleName,
                uupic: this.userInformationFormData.uupic
            }
            : null;
        return payload;
    }
    get userDelegateChanges() {
        const added = this.existingDelegateIds.filter((item) => !this.previousDelegateIds.includes(item));
        const removed = this.previousDelegateIds.filter((item) => !this.existingDelegateIds.includes(item));
        return {
            added,
            removed
        };
    }
    /**
     * A User's delegate group is named === to user.auid
     */
    async fetchDelegates() {
        if (!this.user?.id) {
            return [];
        }
        const resp = await this.$http.get(`/user-groups/delegate/${this.user.id}`);
        return (resp.data?.users || []).filter((user) => user.id !== this.user?.id);
    }
    async fetchEntity(id) {
        const resp = await this.$http.get(`/users/${id}`);
        this.user = resp.data;
        if (this.user) {
            // set user information for form
            this.updateForm(this.user);
            if (this.doesSelectedUserSupportCurrentContext) {
                // initialize delegates
                const delegates = await this.fetchDelegates();
                this.delegates = transformUserResponses(delegates);
                this.previousDelegateIds = delegates ? delegates.map((user) => user.id || '') : [];
            }
        }
    }
    fetchErrorLogs() {
        this.$message('fetch_error_log');
        this.$message('fetch_telemetry_log');
    }
    async mutateDelegates() {
        if (this.userDelegateChanges?.added?.length) {
            // add new users
            await this.$http.put(`/user-groups/${this.codeForUserDelegateGroup}/users`, this.userDelegateChanges.added);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
        }
        // if removing users, remove each user with delete user endpoint
        if (this.userDelegateChanges?.removed?.length) {
            const deleteUsers = this.userDelegateChanges?.removed?.map((userId) => this.$http.delete(`/user-groups/${this.codeForUserDelegateGroup}/users/${userId}`));
            try {
                await Promise.all(deleteUsers);
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'User Group members updated.'
                });
            }
            catch (err) {
                this.$errorUtility({
                    err,
                    backupErrorText: 'Failed to remove group member.'
                });
            }
        }
    }
    async mutateUserInformation() {
        if (!this.user?.contactId) {
            throw new Error('No user.');
        }
        if (!this.userInformationPayload) {
            throw new Error('No userInformationPayload');
        }
        return this.updateContact(this.user.contactId, this.userInformationPayload);
    }
    // called from ui
    async onConfirmDeletion() {
        try {
            this.isDeleting = true;
            await this.$http.delete(`/users/${this.user?.id}?includeContact=${this.shouldAlsoDeleteContact}`);
            this.isDeleting = false;
            this.$router.push(`/users/search`);
        }
        catch (err) {
            this.isDeleting = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to delete User.'
            });
        }
    }
    onCloseOfDeleteUserDialog() {
        this.showDeletionModal = false;
    }
    onClickOfDeleteUser() {
        this.showDeletionModal = true;
    }
    onClickOfRemoveDelegate(selectedDelegate) {
        this.selectedDelegateForRemoval = selectedDelegate;
        if (this.selectedDelegateForRemoval) {
            this.delegates = this.delegates?.filter((delegate) => delegate?.id !== this.selectedDelegateForRemoval?.id);
            this.saveDelegates();
            this.previousDelegateIds = (this.delegates || []).map((delegate) => delegate.id);
            this.selectedDelegateForRemoval = null;
            this.userForDelegate = null;
        }
    }
    async onClickOfPromoteUserAdminRole() {
        try {
            if (!this.user) {
                return;
            }
            const resp = await this.saveUserRole({ role: UserRole.ADMIN });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'User role set to ADMIN.'
            });
            this.fetchEntity(this.id);
            this.$message('reload', true);
            this.$emit('complete');
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.$notification.add({
                type: AlertType.ERROR,
                text: `You are not authorized to make this update. Requires ADMIN role.`
            });
        }
    }
    async onClickOfDemoteUser() {
        try {
            if (!this.user) {
                return;
            }
            const resp = await this.saveUserRole({ role: UserRole.USER });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'User role set to USER.'
            });
            this.fetchEntity(this.id);
            this.$message('reload', true);
            this.$emit('complete');
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.$notification.add({
                type: AlertType.ERROR,
                text: `You are not authorized to make this update. Requires ADMIN role.`
            });
        }
    }
    async onClickOfDisableUser() {
        try {
            if (!this.user) {
                return;
            }
            await this.saveUserRole({ role: UserRole.DISABLED });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'User role set to DISABLED.'
            });
            this.fetchEntity(this.id);
            this.$message('reload', true);
            this.$emit('complete');
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfGrantAccessToCurrentContext() {
        try {
            if (!this.user || !this.currentUserActiveSupportContext) {
                return;
            }
            await this.$http.put(`/users/${this.user.id}/support-contexts`, {
                supportContexts: this.supportContextsPlayload
            });
            this.fetchEntity(this.id);
            this.$message('reload', true);
            this.$emit('complete');
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Access granted to ${this.currentUserActiveSupportContext}`
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // called from ui
    async onClickOfSave() {
        try {
            await this.mutateUserInformation();
            this.$message('reload', true);
            this.scrollToTop();
            this.$notification.add({
                text: 'User information updated.',
                type: this.AlertType.SUCCESS
            });
            this.fetchEntity(this.id);
            this.$emit('complete');
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
        }
    }
    saveUserRole(dataObj) {
        return this.$http.put(`/${EntityType.USER}/${this.user?.id}/role`, dataObj);
    }
    async onClickOfSaveUserRole() {
        if (!this.adminFormData.role) {
            this.$notification.add({
                text: 'Please select a new User role.',
                type: this.AlertType.SUCCESS
            });
            return;
        }
        try {
            this.isSaving = true;
            await this.saveUserRole({ role: UserRole.USER });
            this.$message('reload', true);
            this.scrollToTop();
            this.$notification.add({
                text: 'User role updated.',
                type: this.AlertType.SUCCESS
            });
            this.fetchEntity(this.id);
            this.$emit('complete');
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async saveDelegates() {
        try {
            await this.mutateDelegates();
            this.$message('reload', true);
            this.$notification.add({
                text: 'Delegates updated.',
                type: this.AlertType.SUCCESS
            });
            this.$emit('complete');
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
        }
    }
    updateForm(user) {
        this.userInformationFormData.auid = user.auid || '';
        this.userInformationFormData.email = (user.email || []);
        this.userInformationFormData.firstName = user.firstName || '';
        this.userInformationFormData.lastName = user.lastName || '';
        this.userInformationFormData.middleName = user.middleName || '';
        this.userInformationFormData.uupic = user.uupic || '';
        this.adminFormData.role = user.role;
    }
    addDelegate(user) {
        if (user) {
            this.delegates?.unshift(user);
            this.saveDelegates();
        }
    }
    onIdChange(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], TabUserInformation.prototype, "hideAuidField", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], TabUserInformation.prototype, "hideUupicField", void 0);
__decorate([
    Watch('userForDelegate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TabUserInformation.prototype, "addDelegate", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TabUserInformation.prototype, "onIdChange", null);
TabUserInformation = __decorate([
    Component
], TabUserInformation);
export default TabUserInformation;
