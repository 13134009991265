import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, SERIAL_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformPartReferenceResponseDisplay } from '~/nasa_ui/utils/helpers/transforms/hardware';
let SearchPartReference = class SearchPartReference extends BaseSearch {
    entityType = null;
    hint = 'Drawing number, description, AsBuilt, serial, lot, location';
    placeholder = 'search part references';
    userSelectedMaxResults = 1000;
    tableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hw type',
            value: '_hardwareTypeIcon'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: 'drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            noWrap: true,
            value: '_asBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_description'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_side'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_lotNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_itemClasses'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_contractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_location'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_birthDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration<br/>number',
            value: '_calibrationNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Calibration',
            value: '_lastCalibrationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage<br/>expiry',
            value: '_usageLifeExpirationDate'
        })
    ];
    searchFilters = {};
    get entityTypeDisplay() {
        return 'Part Reference';
    }
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformPartReferenceResponseDisplay([...this.rawSearchResponse]);
        transformed.sort(sortObjectNumericallyBy('_updatedDateTimeTicks'));
        return transformed;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                query: this.searchInput,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            };
            // Execute query
            const resp = await this.$http.get(`/part-references`, {
                params
            });
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = resp.data.results;
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected err: ${err}`);
            }
        }
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchPartReference.prototype, "performSearch", null);
SearchPartReference = __decorate([
    Component
], SearchPartReference);
export default SearchPartReference;
