import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { BASE_ICON_COL, BASE_TEXT_COL, END_DATE_COL, START_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { deleteOutage, getOutages, sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { addDateDisplays } from '~/nasa_ui/utils/helpers/displayMappers';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
export var OutageReason;
(function (OutageReason) {
    OutageReason["HOT_FIX"] = "HOT_FIX";
    OutageReason["NOMINAL_RELEASE"] = "NOMINAL_RELEASE";
})(OutageReason || (OutageReason = {}));
export const OutageReasonDisplay = new Map([
    [OutageReason.HOT_FIX, 'Hot Fix'],
    [OutageReason.NOMINAL_RELEASE, 'Nominal Release']
]);
let TabAdminOutage = class TabAdminOutage extends Vue {
    isDeleting = false;
    isLoading = false;
    outages = [];
    selectedRow = null;
    showDeleteOutageConfirmationModal = false;
    showModalOutage = false;
    get outageItems() {
        return this.outages
            .map(addDateDisplays)
            .map((outage) => {
            return {
                _isActiveIcon: this.$booleanIconDisplay(!outage.isDisabled),
                _reason: outage.reason
                    ? OutageReasonDisplay.get(outage.reason) || DEFAULT_DASH
                    : DEFAULT_DASH,
                _userFacingMessage: markdownToHtml(outage.userFacingMessage) || DEFAULT_DASH,
                rowClass: outage.isDisabled ? '' : 'success lighten-4',
                ...outage
            };
        })
            .sort(sortObjectBy('_activeStartDateTicks', true));
    }
    get outageHeaders() {
        return [
            composeTableHeader(START_DATE_COL, {
                value: '_activeStartDateTime'
            }),
            composeTableHeader(END_DATE_COL, {
                value: '_activeEndDateTime'
            }),
            composeTableHeader(BASE_TEXT_COL, {
                text: 'Reason',
                value: '_reason',
                sortable: false,
                noEscape: true
            }),
            composeTableHeader(BASE_TEXT_COL, {
                text: 'User-facing message',
                value: '_userFacingMessage',
                sortable: false,
                noEscape: true
            }),
            composeTableHeader(BASE_ICON_COL, {
                text: 'Active?',
                value: '_isActiveIcon',
                sortable: true
            })
        ];
    }
    get selectedRowId() {
        return this.selectedRow?.id ?? null;
    }
    async deleteOutage() {
        const id = this.selectedRow?.id;
        if (!id) {
            return null;
        }
        try {
            this.isDeleting = true;
            const resp = await this.deleteOutageMutation(id);
            if (resp.status === 200) {
                this.$notification.add({
                    text: 'Outage deleted.',
                    type: this.AlertType.SUCCESS
                });
                this.fetchOutages();
            }
            this.isDeleting = false;
            this.onCloseModalOutage();
        }
        catch (err) {
            this.onCloseModalOutage();
            this.isDeleting = false;
            this.$errorUtility({ err });
        }
    }
    deleteOutageMutation(id) {
        return deleteOutage(id);
    }
    async fetchOutages() {
        try {
            this.isLoading = true;
            const params = {
                includeDisabled: true,
                includeInactive: true,
                take: -1
            };
            const resp = await getOutages(params);
            this.isLoading = false;
            if (resp?.data.results) {
                this.outages = resp?.data.results;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    // called from ui
    onClickOfCreateOutage() {
        this.showModalOutage = true;
    }
    onClickOfDeleteOutageRow(item) {
        this.selectedRow = item;
        this.showDeleteOutageConfirmationModal = true;
    }
    // called from ui
    onClickOfOutageRow(row) {
        this.selectedRow = row;
        this.showModalOutage = true;
    }
    // called from ui
    onCloseModalOutage() {
        this.selectedRow = null;
        this.showModalOutage = false;
        this.showDeleteOutageConfirmationModal = false;
    }
    // when the outages tab loads, fetch all the outages
    onChangeOfRouteHash() {
        this.fetchOutages();
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabAdminOutage.prototype, "onChangeOfRouteHash", null);
TabAdminOutage = __decorate([
    Component
], TabAdminOutage);
export default TabAdminOutage;
