var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { ADDENDUM_DATE_COL, ADDENDUM_NUM_COL, AS_BUILT_COL, BASE_ICON_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, NOTES_COL, QUANTITY_COL, SEQUENCE_COL, SERIAL_COL, SIDE_COL, SIZE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { HardwareListResponseWithRefsDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalHardwareListPartReferences = class ModalHardwareListPartReferences extends Mixins(BaseModal) {
    tableHeaders = [
        SEQUENCE_COL,
        composeTableHeader(BASE_ICON_COL, {
            text: 'Item type',
            value: '_entityIcon'
        }),
        DRAWING_NUMBER_COL,
        AS_BUILT_COL,
        SIDE_COL,
        SIZE_COL,
        SERIAL_COL,
        LOT_COL,
        DESCRIPTION_COL,
        QUANTITY_COL,
        ADDENDUM_NUM_COL,
        ADDENDUM_DATE_COL,
        NOTES_COL
    ];
    hardwareList;
    get tableItems() {
        return this.hardwareList._hardwareListParts || [];
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareListResponseWithRefsDisplay !== "undefined" && HardwareListResponseWithRefsDisplay) === "function" ? _a : Object)
], ModalHardwareListPartReferences.prototype, "hardwareList", void 0);
ModalHardwareListPartReferences = __decorate([
    Component
], ModalHardwareListPartReferences);
export default ModalHardwareListPartReferences;
