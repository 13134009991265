var _a;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import { ChangeSetStatus } from '~/db_types/swagger_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { BASE_DATE_COL, BASE_TEXT_COL, QUANTITY_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import { AlertType, AttachmentTypeDisplay, Maybe } from '~/nasa_ui/types';
import { deleteDeliveryOrderLineItem, getDeliveryOrder, postDeliveryOrderLineItem, putDeliveryOrder, putDeliveryOrderLineItem } from '~/nasa_ui/utils/dal';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { transformDeliveryOrderLineItemResponses, transformDeliveryOrderResponseWithRefs } from '~/nasa_ui/utils/helpers/transforms/documents';
export const DELIVERY_ORDER_FEATURED_ATTACHMENT_SLUG = 'deliveryOrderFeaturedAttachment';
let TabDeliveryOrderInformation = class TabDeliveryOrderInformation extends Mixins(BaseTab, AttachmentRepoMixin) {
    attachments = [];
    ChangeSetStatus = ChangeSetStatus;
    DEFAULT_DASH = DEFAULT_DASH;
    DocumentType = DocumentType;
    isSaving = false;
    matchingDeliveryOrders = null;
    selectedAttachmentFileForPreview = null;
    selectedAttachmentIdToFeature = null;
    selectedEntity = null;
    selectedLineItem = null;
    showModalCompleteLineItem = false;
    showModalCreateLineItem = false;
    showModalDeleteLineItem = false;
    showModalDeliveryOrderLineItemInfo = false;
    showModalEditLineItem = false;
    lineItemTableHeaders = [
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Item status',
            value: '_deliveryOrderLineItemStatus'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Item type',
            value: '_lineItemType',
            noWrap: true
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'CEI',
            value: '_contractEndingItemNumber',
            noWrap: true
        }),
        QUANTITY_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Need type',
            value: '_needType'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Need',
            value: '_needDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Est. completion',
            value: '_projectedDate'
        })
    ];
    formData = {
        attributes: {},
        contractNumber: null,
        description: null,
        estimatedCloseDate: null,
        initiatorContactId: null,
        number: null,
        overrideOpenDate: null,
        overrideRevisionNumber: null,
        tags: [],
        title: null
    };
    get attachmentSelectOptions() {
        return this.attachments.map((attachment) => ({
            displayText: `${AttachmentTypeDisplay.get(attachment.subType)} - ${attachment.description}`,
            value: attachment.id
        }));
    }
    get computedSelectedAttachment() {
        return this.attachments.find((attachment) => attachment.id === this.selectedAttachmentIdToFeature) || null;
    }
    get hasRequiredFields() {
        return this.formData.number;
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get lineItems() {
        return this.transformedSelectedEntity?._lineItems._changeSets || [];
    }
    get lineItemTableItems() {
        if (!this.lineItems.length) {
            return [];
        }
        return transformDeliveryOrderLineItemResponses(this.lineItems);
    }
    get shouldAttachmentCardStartCollapsed() {
        return !this.selectedAttachmentIdToFeature;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || this.isSaving;
    }
    get shouldInfoCardStartCollapsed() {
        return this.lineItems.some((lineItem) => lineItem.status === ChangeSetStatus.OPEN);
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformDeliveryOrderResponseWithRefs([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.id}`, this.fetchSelectedEntity);
    }
    async deleteLineItem() {
        if (!this.selectedLineItem?.partReferenceId) {
            return;
        }
        try {
            const params = {
                id: this.id,
                partReferenceId: [this.selectedLineItem.partReferenceId]
            };
            this.isSaving = true;
            const resp = await deleteDeliveryOrderLineItem(this.id, params);
            if (resp.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Line item deleted.'
                });
                this.showModalDeleteLineItem = false;
                this.fetchSelectedEntity();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    async fetchAttachmentsForDeliveryOrder() {
        if (!this.id) {
            return;
        }
        try {
            const resp = await this.getDocumentAttachments({ docId: this.id });
            if (resp?.documentById?.attachments.nodes.length) {
                this.attachments = transformAttachments(resp.documentById.attachments.nodes);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async onAddLineItem(lineItem) {
        try {
            const resp = await postDeliveryOrderLineItem(this.id, {
                lineItems: [lineItem]
            });
            if (resp.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Line item added.'
                });
                this.fetchSelectedEntity();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfCompleteLineItem(lineItem) {
        this.selectedLineItem = lineItem;
        this.showModalCompleteLineItem = true;
    }
    async onClickOfDeleteLineItem(lineItem) {
        this.selectedLineItem = lineItem;
        this.showModalDeleteLineItem = true;
    }
    onClickOfEditLineItem(lineItem) {
        this.selectedLineItem = lineItem;
        this.showModalEditLineItem = true;
    }
    onClickOfViewLineItemInfo(lineItem) {
        this.selectedLineItem = lineItem;
        this.showModalDeliveryOrderLineItemInfo = true;
    }
    async onClickOfSave() {
        if (!this.isFormValid) {
            return;
        }
        try {
            this.isSaving = true;
            const body = {
                attributes: this.formData.attributes,
                contractNumber: this.formData.contractNumber,
                description: this.formData.description,
                estimatedCloseDate: this.formData.estimatedCloseDate,
                initiatorContactId: this.formData.initiatorContactId,
                number: this.formData.number,
                overrideOpenDate: this.formData.overrideOpenDate,
                overrideRevisionNumber: this.formData.overrideRevisionNumber,
                tags: this.formData.tags || [],
                title: this.formData.title
            };
            const resp = await putDeliveryOrder(this.id, body);
            this.$message(`reload_${this.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.isSaving = false;
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onEditLineItem(lineItem) {
        if (!this.selectedLineItem?.id) {
            return;
        }
        try {
            const resp = await putDeliveryOrderLineItem(this.id, this.selectedLineItem.id, lineItem);
            if (resp.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Line item updated.'
                });
                this.showModalEditLineItem = false;
                this.fetchSelectedEntity();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    resetFromModals() {
        this.selectedLineItem = null;
        this.showModalCompleteLineItem = false;
        this.showModalCreateLineItem = false;
        this.showModalDeleteLineItem = false;
        this.showModalDeliveryOrderLineItemInfo = false;
        this.showModalEditLineItem = false;
    }
    async saveAttributes() {
        if (!this.selectedEntity) {
            return;
        }
        this.isSaving = true;
        const body = {
            attributes: this.formData.attributes,
            contractNumber: this.formData.contractNumber,
            description: this.formData.description,
            estimatedCloseDate: this.formData.estimatedCloseDate,
            initiatorContactId: this.formData.initiatorContactId,
            number: this.formData.number,
            overrideOpenDate: this.formData.overrideOpenDate,
            overrideRevisionNumber: this.formData.overrideRevisionNumber,
            tags: this.formData.tags || [],
            title: this.formData.title
        };
        await putDeliveryOrder(this.id, body);
        this.isSaving = false;
    }
    async fetchSelectedEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await getDeliveryOrder(this.id);
            this.selectedEntity = resp.data;
            await this.fetchAttachmentsForDeliveryOrder();
            this.isFetching = false;
            const featuredAttachmentId = this.selectedEntity.attributes[DELIVERY_ORDER_FEATURED_ATTACHMENT_SLUG];
            if (featuredAttachmentId) {
                this.selectedAttachmentIdToFeature = featuredAttachmentId;
            }
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    async onChangeOfSelectedAttachment(attachment) {
        if (!this.selectedEntity) {
            return;
        }
        // no featured attachment. clear the featured attachment in attributes
        if (!attachment) {
            this.formData.attributes = Object.assign({}, this.selectedEntity.attributes, {
                [DELIVERY_ORDER_FEATURED_ATTACHMENT_SLUG]: null
            });
            this.saveAttributes();
            this.selectedAttachmentFileForPreview = null;
            return;
        }
        // get the attachment file for preview and save the attachment id in attributes
        this.selectedAttachmentFileForPreview = await this.getAttachmentDownload(attachment);
        this.formData.attributes = Object.assign({}, this.selectedEntity.attributes, {
            [DELIVERY_ORDER_FEATURED_ATTACHMENT_SLUG]: attachment.id
        });
        // if the attachment isn't already the featured attachment, save attributes
        if (attachment.id !== this.selectedEntity.attributes[DELIVERY_ORDER_FEATURED_ATTACHMENT_SLUG]) {
            this.saveAttributes();
        }
    }
    onSelectedEntityChanged() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData.attributes = this.selectedEntity.attributes;
        this.formData.contractNumber = this.selectedEntity.contractNumber;
        this.formData.description = this.selectedEntity.description;
        this.formData.estimatedCloseDate = this.selectedEntity.estimatedCloseDate;
        this.formData.initiatorContactId = this.selectedEntity.initiatorContactId;
        this.formData.number = this.selectedEntity.number;
        this.formData.overrideOpenDate = this.selectedEntity.overrideOpenDate;
        this.formData.overrideRevisionNumber = this.selectedEntity.overrideRevisionNumber;
        this.formData.title = this.selectedEntity.title;
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabDeliveryOrderInformation.prototype, "fetchSelectedEntity", null);
__decorate([
    Debounce(100),
    Watch('computedSelectedAttachment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], TabDeliveryOrderInformation.prototype, "onChangeOfSelectedAttachment", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabDeliveryOrderInformation.prototype, "onSelectedEntityChanged", null);
TabDeliveryOrderInformation = __decorate([
    Component
], TabDeliveryOrderInformation);
export default TabDeliveryOrderInformation;
