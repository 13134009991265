import { __decorate, __metadata } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { deleteLocation, getLocation } from '~/nasa_ui/utils';
let DeleteLocation = class DeleteLocation extends BaseDelete {
    location = null;
    requiredPermission = UserPermission.LOCATION_ADMIN;
    get deleteButtonTitle() {
        if (this.hasInventory) {
            return 'Location cannot be deleted because it has, or previously had, Inventory.';
        }
        return 'Permanently delete this Location.';
    }
    get hasInventory() {
        // TODO: this should be provided by the api https://gitlab.com/mri-technologies/cosmic/api/-/issues/342
        // return Boolean(this.location?.inventories?.length);
        return true;
    }
    get shouldDisableDeleteButton() {
        if (!this.requiredPermission) {
            return this.isDeleting;
        }
        return !this.currentUserHasPermission(this.requiredPermission) || this.isDeleting || this.hasInventory;
    }
    deleteLocationById(id) {
        if (!id) {
            return null;
        }
        return deleteLocation(id);
    }
    async onAffirmativeClicked() {
        if (!this.id) {
            return;
        }
        try {
            this.isDeleting = true;
            await this.deleteLocationById(this.id);
            this.isDeleting = false;
            this.$notification.add({ text: 'Location deleted.', type: AlertType.SUCCESS });
            this.emitDeletionComplete();
        }
        catch (err) {
            this.isDeleting = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to delete Location.'
            });
        }
    }
    async fetchLocation() {
        if (!this.id) {
            return;
        }
        try {
            const resp = await getLocation(this.id);
            this.location = resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to initialize Delete Location button.'
            });
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], DeleteLocation.prototype, "fetchLocation", null);
DeleteLocation = __decorate([
    Component
], DeleteLocation);
export default DeleteLocation;
