var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import { EntityType as DbEntityType } from '~/db_types/swagger_types';
import { BASE_ICON_COL, COMMENT_COL, SUB_TYPE_COL, UPDATED_BY_COL, UPDATED_DATE_TIME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
import { AlertType, EntityType, UserPermission } from '~/nasa_ui/types';
import { CommentTypeDisplay, EditableCommentTypes } from '~/nasa_ui/types/enums/comment';
import { getItemInstance, sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { CommentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformComments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppComments = class AppComments extends Mixins(CurrentUserMixin, ReadOnlyMixin, WagonWheelPropsMixin) {
    commentsActivity = [];
    commentsDocument = [];
    commentsEvent = [];
    commentsHardwareList = [];
    commentsItemDrawing = [];
    commentsItemInstance = [];
    commentsItemMaster = [];
    commentsOrganization = [];
    commentsPia = [];
    commentsRisk = [];
    commentsRiskMitigation = [];
    CommentTableHeaders = CommentTableHeaders;
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    commentTypesToDisplay = [];
    documentCommentsToDisplay = [];
    isFetching = false;
    isFetchingCount = 0;
    isRemoving = false;
    selectedComment = null;
    showDeleteConfirmation = false;
    showEditModal = false;
    createCommentTypes;
    editCommentTypes;
    ignoreCommentTypes;
    tableHeaderOverrides;
    get allComments() {
        const allComments = [
            ...this.commentsActivity,
            ...this.commentsDocument,
            ...this.commentsEvent,
            ...this.commentsHardwareList,
            ...this.commentsItemDrawing,
            ...this.commentsItemInstance,
            ...this.commentsItemMaster,
            ...this.commentsOrganization,
            ...this.commentsPia,
            ...this.commentsRisk
        ];
        // return a deduped array based on the comment id
        return allComments.reduce((acc, comment) => {
            if (!acc.find((c) => c.id === comment.id)) {
                acc.push(comment);
            }
            return acc;
        }, []);
    }
    get commentTypeEnum() {
        return Object.values(CommentType).filter((commentType) => this.createCommentTypes?.includes(commentType));
    }
    get computedComments() {
        if (!this.allComments) {
            return [];
        }
        const filteredComments = this.allComments.filter((comment) => {
            return this.computedCommentTypesToDisplay.includes(comment.subType);
        });
        return transformComments(filteredComments).sort(sortObjectBy('_updatedDateTicks', true));
    }
    get computedCommentTypesToDisplay() {
        if (this.commentTypesToDisplay?.length) {
            return this.commentTypesToDisplay;
        }
        return (this.existingCommentTypes.filter((comment) => {
            return !this.ignoreCommentTypes?.includes(comment);
        }) || []);
    }
    get computedIsFetching() {
        return this.isFetchingCount > 0;
    }
    get computedTableHeaders() {
        return (this.tableHeaderOverrides || [
            composeTableHeader(UPDATED_BY_COL, { width: '5%' }),
            COMMENT_COL,
            composeTableHeader(SUB_TYPE_COL, {
                text: 'Comment<br/>type',
                value: '_subType'
            }),
            composeTableHeader(BASE_ICON_COL, {
                noEscape: true,
                text: 'System<br/>type',
                value: '_entityType',
                width: '15%'
            }),
            composeTableHeader(UPDATED_DATE_TIME_COL, {
                width: '10%'
            })
        ]);
    }
    get entityTypesAssociatedWithComment() {
        if (!this.selectedComment) {
            return [];
        }
        const entityTypes = [];
        // not trying to be fancy here
        if (this.selectedComment.activityId) {
            entityTypes.push(EntityType.ACTIVITY);
        }
        if (this.selectedComment.changeSetAuthorityId || this.selectedComment.changeSetId) {
            entityTypes.push(EntityType.CHANGE_SET);
        }
        if (this.selectedComment.documentId) {
            entityTypes.push(EntityType.DOCUMENT);
        }
        if (this.selectedComment.eventId) {
            entityTypes.push(EntityType.EVENT);
        }
        if (this.selectedComment.hardwareListId) {
            entityTypes.push(EntityType.HARDWARE_LIST);
        }
        if (this.selectedComment.itemInstanceId) {
            entityTypes.push(EntityType.ITEM_INSTANCE);
        }
        if (this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_DRAWING);
        }
        if (this.selectedComment.asBuiltNumber && this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_MASTER);
        }
        if (this.selectedComment.organizationCode) {
            entityTypes.push(EntityType.ORGANIZATION);
        }
        if (this.selectedComment.physicalInventoryAuditId || this.selectedComment.physicalInventoryAuditItemId) {
            entityTypes.push(EntityType.PHYSICAL_INVENTORY_AUDIT);
        }
        if (this.selectedComment.transactionId) {
            entityTypes.push(EntityType.TRANSACTION);
        }
        if (this.selectedComment.riskId) {
            entityTypes.push(EntityType.RISK);
        }
        if (this.selectedComment.riskMitigationId) {
            entityTypes.push(EntityType.RISK);
        }
        return entityTypes;
    }
    get existingCommentTypes() {
        return this.allComments?.reduce((output, comment) => {
            if (!(output.indexOf(comment?.subType) > -1)) {
                output.push(comment?.subType);
            }
            return output;
        }, []);
    }
    get existingValuesToBeRemoved() {
        return Object.values(CommentType).filter((commentType) => {
            return !this.existingCommentTypes.includes(commentType);
        });
    }
    get getPermissionForHardware() {
        return [...this.entityTypesAssociatedWithComment]
            .map((entityType) => {
            const perm = `${entityType}_ADMIN`;
            if (Object.hasOwn(UserPermission, perm)) {
                return perm;
            }
        })
            .filter((perm) => perm);
    }
    get rowsPerPageItems() {
        return [
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get selectedCommentId() {
        return this.selectedComment?.id;
    }
    get isUserOwnerOfComment() {
        if (this.selectedComment && this.currentUser) {
            return this.selectedComment.createdByUserId === this.currentUser.id;
        }
    }
    get userCanRemoveComment() {
        if (!this.selectedComment?.comment) {
            return true; // any user can remove empty comments
        }
        if (!this.getPermissionForHardware) {
            return false;
        }
        const hasPermission = (userPermission) => this.currentUserHasPermission(userPermission);
        return this.getPermissionForHardware.some(hasPermission) || this.isUserOwnerOfComment || false;
    }
    onClickOfDelete(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot delete SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showDeleteConfirmation = true;
    }
    onClickOfEdit(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot edit SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showEditModal = true;
    }
    onDeleteCancelled() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
    }
    // Delete the selected comment
    async onDeleteConfirmed() {
        if (!this.selectedComment) {
            return;
        }
        try {
            this.isRemoving = true;
            const resp = await this.$http.delete(`/comments/${this.selectedComment.id}`);
            this.$notification.add({
                text: 'Deleted.',
                type: AlertType.SUCCESS
            });
            this.isRemoving = false;
            this.reloadComments();
        }
        catch (err) {
            this.isRemoving = false;
            this.reloadComments();
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onModalCommentEditClose() {
        this.selectedComment = null;
        this.showEditModal = false;
    }
    commentTotalByType(commentType) {
        return this.allComments.filter((comment) => comment.subType === commentType).length;
    }
    reloadComments() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
        this.showEditModal = false;
        // Since we don't have one big fetchComments method anymore, we need to
        // call each one individually, passing the appropriate set of props.
        this.fetchCommentsForItemDrawing(this.drawingNumber);
        this.fetchCommentsForItemMaster(this.drawingNumber, this.asBuiltNumber, this.side);
        this.fetchCommentsForItemInstance(this.itemInstanceId);
        this.fetchCommentsForDocument(this.documentId);
        this.fetchCommentsForHardwareList(this.hardwareListId);
        this.fetchCommentsForEvent(this.cosmicEventId);
        this.fetchCommentsForActivity(this.activityId);
        this.fetchCommentsForOrganization(this.organizationCode);
        this.fetchCommentsForPia(this.physicalInventoryAuditId);
        this.fetchCommentsForRisk(this.riskId);
        this.fetchCommentsForRiskMitigationStep(this.riskMitigationId);
    }
    setCommentType(type) {
        this.commentTypesToDisplay = [type];
    }
    viewAllComments() {
        this.commentTypesToDisplay = this.existingCommentTypes;
    }
    emitComments() { }
    async fetchCommentsForItemDrawing(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        this.isFetchingCount++;
        try {
            const params = {
                drawingNumber: drawingNumber,
                entityType: [DbEntityType.ITEM_DRAWING],
                take: -1
            };
            const resp = await this.$http.get(`/comments`, { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for item drawing.');
                return;
            }
            this.commentsItemDrawing = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    // purposely leaving the watcher off of this method because there is a
    // composite watcher below
    async fetchCommentsForItemMaster(drawingNumber, asBuiltNumber, side) {
        if (!drawingNumber || !asBuiltNumber || !side) {
            return;
        }
        this.isFetchingCount++;
        try {
            const paramsIM = {
                asBuiltNumber: asBuiltNumber,
                drawingNumber: drawingNumber,
                side: side,
                take: -1
            };
            const respIM = await this.$http.get(`/comments`, { params: paramsIM });
            if (!respIM.data) {
                new Error('Failed to fetch comments for item master.');
                return;
            }
            this.commentsItemMaster = respIM.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForItemInstance(itemInstanceId) {
        if (!itemInstanceId) {
            return;
        }
        this.isFetchingCount++;
        try {
            // first get the item instance so we can get the primary keys
            const respII = await getItemInstance(itemInstanceId);
            if (!respII.data) {
                new Error('Failed to fetch item instance.');
                return;
            }
            const { drawingNumber, asBuiltNumber, side } = respII.data;
            await this.fetchCommentsForItemMaster(drawingNumber, asBuiltNumber, side);
            await this.fetchCommentsForItemDrawing(drawingNumber);
            const paramsIIComments = {
                itemInstanceId,
                take: -1
            };
            const respIIComments = await this.$http.get('/comments', {
                params: paramsIIComments
            });
            if (!respIIComments.data) {
                new Error('Failed to fetch comments for item instance.');
                return;
            }
            this.commentsItemInstance = respIIComments.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForDocument(documentId) {
        if (!documentId) {
            return;
        }
        this.isFetchingCount++;
        try {
            const params = {
                documentId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                this.$errorUtility({ err: 'Failed to fetch comments for document.' });
                return;
            }
            this.commentsDocument = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForHardwareList(hardwareListId) {
        if (!hardwareListId) {
            return;
        }
        this.isFetchingCount++;
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    hardwareListId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for hardware list.');
                return;
            }
            this.commentsHardwareList = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForEvent(cosmicEventId) {
        if (!cosmicEventId) {
            return;
        }
        this.isFetchingCount++;
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    cosmicEventId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for event.');
                return;
            }
            this.commentsEvent = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForActivity(activityId) {
        if (!activityId) {
            return;
        }
        this.isFetchingCount++;
        try {
            const params = {
                activityId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for activity.');
                return;
            }
            this.commentsActivity = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForOrganization(organizationCode) {
        if (!organizationCode) {
            return;
        }
        this.isFetchingCount++;
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    organizationCode,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for organization.');
                return;
            }
            this.commentsOrganization = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForPia(physicalInventoryAuditId) {
        if (!physicalInventoryAuditId) {
            return;
        }
        this.isFetchingCount++;
        try {
            const params = {
                physicalInventoryAuditId,
                take: -1
            };
            const resp = await this.$http.get('/comments', { params });
            if (!resp.data) {
                new Error('Failed to fetch comments for PIA.');
                return;
            }
            this.commentsPia = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetchingCount--;
    }
    async fetchCommentsForRisk(riskId) {
        if (!riskId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for risk.');
                return;
            }
            this.commentsRisk = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForRiskMitigationStep(riskMitigationId) {
        if (!riskMitigationId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskMitigationId,
                    take: -1
                }
            });
            if (!resp.data) {
                new Error('Failed to fetch comments for risk mitigation.');
                return;
            }
            this.commentsRiskMitigation = resp.data.results ?? [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onChangeOfItemMasterPrimaryKeys() {
        if (!this.drawingNumber || !this.asBuiltNumber || !this.side) {
            return;
        }
        this.fetchCommentsForItemMaster(this.drawingNumber, this.asBuiltNumber, this.side);
    }
};
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppComments.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppComments.prototype, "editCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return [];
        },
        type: Array
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppComments.prototype, "ignoreCommentTypes", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppComments.prototype, "tableHeaderOverrides", void 0);
__decorate([
    Emit('comments'),
    Watch('computedComments', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppComments.prototype, "emitComments", null);
__decorate([
    Watch('drawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForItemDrawing", null);
__decorate([
    Watch('itemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForItemInstance", null);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof Maybe !== "undefined" && Maybe) === "function" ? _g : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForDocument", null);
__decorate([
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_h = typeof Maybe !== "undefined" && Maybe) === "function" ? _h : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForHardwareList", null);
__decorate([
    Watch('cosmicEventId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_j = typeof Maybe !== "undefined" && Maybe) === "function" ? _j : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForEvent", null);
__decorate([
    Watch('activityId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_k = typeof Maybe !== "undefined" && Maybe) === "function" ? _k : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForActivity", null);
__decorate([
    Watch('organizationCode', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_l = typeof Maybe !== "undefined" && Maybe) === "function" ? _l : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForOrganization", null);
__decorate([
    Watch('physicalInventoryAuditId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_m = typeof Maybe !== "undefined" && Maybe) === "function" ? _m : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForPia", null);
__decorate([
    Watch('riskId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_o = typeof Maybe !== "undefined" && Maybe) === "function" ? _o : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForRisk", null);
__decorate([
    Watch('riskMitigationId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_p = typeof Maybe !== "undefined" && Maybe) === "function" ? _p : Object]),
    __metadata("design:returntype", Promise)
], AppComments.prototype, "fetchCommentsForRiskMitigationStep", null);
__decorate([
    DebounceAll(10),
    Watch('drawingNumber', { immediate: true }),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppComments.prototype, "onChangeOfItemMasterPrimaryKeys", null);
AppComments = __decorate([
    Component
], AppComments);
export default AppComments;
