var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CosmicAttachmentSlimFragment } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import Log from '~/nasa_ui/modules/Log';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppAttachmentViewer = class AppAttachmentViewer extends Mixins(BaseVue, AttachmentRepoMixin) {
    isFetching = false;
    file = null;
    attachmentById = null;
    id;
    attachment;
    get transformedAttachment() {
        const attachment = this.attachment || this.attachmentById;
        return attachment ? transformAttachments([attachment])[0] : null;
    }
    async fetchFile() {
        if (!this.transformedAttachment?.contentType) {
            return;
        }
        try {
            this.isFetching = true;
            this.file = await this.getAttachmentDownload(this.transformedAttachment);
            new Log().telemetry({
                fromUrl: this.$router.currentRoute?.fullPath,
                toUrl: this.transformedAttachment?._downloadKey,
                message: 'file-preview'
            });
            this.isFetching = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    logFileView(data) {
        new Log().telemetry(data);
    }
    async fetchById(id) {
        if (!id) {
            return;
        }
        const resp = await this.$http.get(`/attachments/${id}`);
        this.attachmentById = resp.data;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppAttachmentViewer.prototype, "id", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof CosmicAttachmentSlimFragment !== "undefined" && CosmicAttachmentSlimFragment) === "function" ? _a : Object)
], AppAttachmentViewer.prototype, "attachment", void 0);
__decorate([
    Watch('transformedAttachment', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppAttachmentViewer.prototype, "fetchFile", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppAttachmentViewer.prototype, "fetchById", null);
AppAttachmentViewer = __decorate([
    Component
], AppAttachmentViewer);
export default AppAttachmentViewer;
