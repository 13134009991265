var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
let AppCreateComment = class AppCreateComment extends Mixins(WagonWheelPropsMixin) {
    mini;
    commentTypeOverride;
    createCommentTypes;
    readOnly;
    emitReloadComments() { }
    showModalCommentCreate = false;
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCreateComment.prototype, "mini", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppCreateComment.prototype, "commentTypeOverride", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(CommentType);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppCreateComment.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppCreateComment.prototype, "readOnly", void 0);
__decorate([
    Emit('reloadComments'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCreateComment.prototype, "emitReloadComments", null);
AppCreateComment = __decorate([
    Component
], AppCreateComment);
export default AppCreateComment;
