var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { UserGroupType } from '~/db_types/swagger_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType } from '~/nasa_ui/types';
import { UserGroupTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformEntitiesToTableFormat, transformUserGroupResponseWithRefs, UserGroupResponseWithRefsDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchUserGroup = class SearchUserGroup extends Mixins(BaseSearch, HttpMixin) {
    entityType = EntityType.USER_GROUP;
    placeholder = 'search for user groups';
    hint = 'group code, group name';
    selectedUserGroup = null;
    userGroupWithMembers = null;
    get chipsFromFilters() {
        const appChips = [];
        return appChips;
    }
    get computedTableHeaders() {
        return [...UserGroupTableHeaders];
    }
    addSelectedPropIfSelected(data) {
        if (!data || !this.existingValues) {
            return data || [];
        }
        return data.map((node) => {
            if (!node.code) {
                return node;
            }
            let ev = this.existingValues || [];
            if (!Array.isArray(ev)) {
                ev = [ev];
            }
            node.__SELECTED = ev.includes(node.nodeId) || ev.includes(node.code);
            return node;
        });
    }
    async performSearch() {
        const resp = await this.$http.get(`/user-groups?query=${this.searchInput}&subType=${UserGroupType.SYSTEM}&subType=${UserGroupType.USER_MANAGED}&take=-1`);
        // Set the raw response in case we need that
        this.rawSearchResponse = resp.data?.results;
        this.emitSearchPerformed();
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? this.userGroupWithMembers?.code : this.userGroupWithMembers;
    }
    onChangeOfRawResponse(val) {
        if (!val) {
            this.$set(this, 'searchResults', []);
            return;
        }
        // transform the results
        const data = transformEntitiesToTableFormat(this.entityType, val);
        this.$set(this, 'searchResults', data);
    }
    async onGroupClick(group) {
        if (group) {
            const resp = await this.httpGet(`/user-groups/${group?.code}`);
            this.userGroupWithMembers = transformUserGroupResponseWithRefs([resp.data])[0];
        }
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchUserGroup.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof UserGroupResponseWithRefsDisplay !== "undefined" && UserGroupResponseWithRefsDisplay) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchUserGroup.prototype, "onClickOfSearchResult", null);
__decorate([
    Watch('rawSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchUserGroup.prototype, "onChangeOfRawResponse", null);
__decorate([
    Watch('selectedUserGroup'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], SearchUserGroup.prototype, "onGroupClick", null);
SearchUserGroup = __decorate([
    Component
], SearchUserGroup);
export default SearchUserGroup;
