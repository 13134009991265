import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicUserFragment } from '../user';
import { CosmicCommentSlimFragment } from './slim';
export const CosmicCommentFragment = gql `
  ${CosmicCommentSlimFragment}
  ${CosmicUserFragment}

  fragment CosmicComment on Comment {
    ...CosmicCommentSlim

    activity {
      id
    }

    changeSet {
      id
      subType
    }

    changeSetAuthority {
      id
    }

    createdByUser {
      ...CosmicUser
    }

    document {
      id
    }

    event {
      id
    }

    hardwareList {
      id
    }

    itemDrawing {
      nodeId
      drawingNumber
    }

    itemMaster {
      nodeId
    }

    itemInstance {
      id
      nodeId
    }

    organization {
      nodeId
    }

    physicalInventoryAudit {
      id
    }

    physicalInventoryAuditItem {
      id
    }

    transaction {
      id
    }

    updatedByUser {
      ...CosmicUser
    }
  }
`;
export const GetGenericComment = gql `
  ${CosmicCommentFragment}

  query GetGenericComment($id: UUID!) {
    commentById(id: $id) {
      ...CosmicComment
    }
  }
`;
export const GetItemDrawingComments = gql `
  ${CosmicCommentFragment}

  query GetItemDrawingComments($itemDrawingNodeId: ID!) {
    itemDrawing(nodeId: $itemDrawingNodeId) {
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetItemMasterComments = gql `
  ${CosmicCommentFragment}

  query GetItemMasterComments($itemMasterNodeId: ID!) {
    itemMaster(nodeId: $itemMasterNodeId) {
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetItemInstanceComments = gql `
  ${CosmicCommentFragment}

  query GetItemInstanceComments($itemInstanceNodeId: ID!) {
    itemInstance(nodeId: $itemInstanceNodeId) {
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetDigitalRecordComments = gql `
  ${CosmicCommentFragment}

  query GetDigitalRecordComments($id: UUID) {
    comments(filter: { digitalLibraryRecordId: { equalTo: $id } }) {
      nodes {
        ...CosmicComment
      }
    }
  }
`;
export const DocumentCommentFragment = gql `
  ${CosmicCommentFragment}

  fragment DocumentWithComments on Document {
    id
    nodeId

    documentComments: comments(orderBy: CREATED_DATE_TIME_DESC) {
      nodes {
        ...CosmicComment
      }
    }

    changeSets {
      nodes {
        id
        nodeId

        changeSetComments: comments(orderBy: CREATED_DATE_TIME_DESC) {
          nodes {
            ...CosmicComment
          }
        }
      }
    }

    changeSetAuthorities {
      nodes {
        id
        nodeId

        changeSetAuthorityComments: comments(orderBy: CREATED_DATE_TIME_DESC) {
          nodes {
            ...CosmicComment
          }
        }
      }
    }
  }
`;
export const GetDocumentCommentsById = gql `
  ${DocumentCommentFragment}

  query GetDocumentCommentsById($documentId: UUID!) {
    documentById(id: $documentId) {
      ...DocumentWithComments
    }
  }
`;
export const GetHardwareListComments = gql `
  ${CosmicCommentFragment}

  query GetHardwareListComments($hardwareListId: UUID!) {
    hardwareListById(id: $hardwareListId) {
      id
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetEventCommentsByEventId = gql `
  ${CosmicCommentFragment}

  query GetEventCommentsByEventId($eventId: UUID!) {
    eventById(id: $eventId) {
      id
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetActivityComments = gql `
  ${CosmicCommentFragment}

  query GetActivityComments($activityId: UUID!) {
    activityById(id: $activityId) {
      id
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetPiaComments = gql `
  ${CosmicCommentFragment}

  query GetPiaComments($physicalInventoryAuditNodeId: ID!) {
    physicalInventoryAudit(nodeId: $physicalInventoryAuditNodeId) {
      nodeId

      comments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicComment
        }
      }
    }
  }
`;
export const GetLastComment = gql `
  ${CosmicUserFragment}

  query GetLastComment($documentId: UUID!, $commentType: CommentType) {
    comments(condition: { documentId: $documentId, subType: $commentType }, first: 1, orderBy: CREATED_DATE_TIME_DESC) {
      nodes {
        nodeId
        id
        comment
        createdDateTime
        createdByUser {
          ...CosmicUser
        }
      }
    }
  }
`;
export const CreateComment = gql `
  mutation CreateComment($comment: CommentInput!) {
    createComment(input: { comment: $comment }) {
      comment {
        id
        nodeId
      }
    }
  }
`;
export const UpdateComment = gql `
  ${CosmicCommentFragment}

  mutation UpdateComment($input: UpdateCommentByIdInput!) {
    updateCommentById(input: $input) {
      comment {
        ...CosmicComment
      }
    }
  }
`;
export const DeleteComment = gql `
  mutation DeleteComment($id: UUID!) {
    deleteCommentById(input: { id: $id }) {
      clientMutationId
      deletedCommentId
    }
  }
`;
let CommentsRepoMixin = class CommentsRepoMixin extends Mixins(LoaderMixin) {
    getCommentById = queryFactory(this, GetGenericComment);
    getItemDrawingComments = queryFactory(this, GetItemDrawingComments);
    getItemMasterComments = queryFactory(this, GetItemMasterComments);
    getItemInstanceComments = queryFactory(this, GetItemInstanceComments);
    getDocumentCommentsById = queryFactory(this, GetDocumentCommentsById);
    getHardwareListComments = queryFactory(this, GetHardwareListComments);
    getEventCommentsByEventId = queryFactory(this, GetEventCommentsByEventId);
    getActivityComments = queryFactory(this, GetActivityComments);
    getPiaComments = queryFactory(this, GetPiaComments);
    getLastComment = queryFactory(this, GetLastComment);
    createComment = mutationFactory(this, CreateComment);
    updateComment = mutationFactory(this, UpdateComment);
    deleteComment = mutationFactory(this, DeleteComment);
};
CommentsRepoMixin = __decorate([
    Component
], CommentsRepoMixin);
export { CommentsRepoMixin };
