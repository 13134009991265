import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_TEXT_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, QUANTITY_COL, SERIAL_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { deleteDeliveryOrder, getDeliveryOrder } from '~/nasa_ui/utils/dal';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformDeliveryOrderLineItemResponses, transformDeliveryOrderResponseWithRefs } from '~/nasa_ui/utils/helpers/transforms/documents';
let ModalDeliveryOrderDelete = class ModalDeliveryOrderDelete extends Mixins(BaseModal) {
    entity = null;
    isDeleting = false;
    lineItemTableHeaders = [
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Item status',
            value: '_deliveryOrderLineItemStatus'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: '_partReference.drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            noWrap: true,
            value: '_partReference._asBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_partReference._description'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_partReference._serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_partReference._lotNumber'
        }),
        QUANTITY_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Need type',
            value: '_needType'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Need',
            value: '_needDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Est. completion',
            value: '_projectedDate'
        })
    ];
    id;
    get lineItems() {
        return this.entity?.lineItems?.changeSets || [];
    }
    get lineItemTableItems() {
        if (!this.lineItems.length) {
            return [];
        }
        return transformDeliveryOrderLineItemResponses(this.lineItems);
    }
    get selectedEntity() {
        return this.entity ? transformDeliveryOrderResponseWithRefs([this.entity])[0] : null;
    }
    closeDialog() {
        this.entity = null;
        this.emitModalClose();
    }
    async onClickOfDelete() {
        if (this.entity?.id) {
            this.isDeleting = true;
            try {
                await deleteDeliveryOrder(this.entity.id);
                this.$emit('delivery-order-deleted');
                this.emitModalClose();
                this.isDeleting = false;
            }
            catch (err) {
                this.$errorUtility({ err });
                this.isDeleting = false;
            }
        }
    }
    async fetchById(id) {
        if (id) {
            const resp = await getDeliveryOrder(id);
            this.entity = resp.data;
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalDeliveryOrderDelete.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderDelete.prototype, "fetchById", null);
ModalDeliveryOrderDelete = __decorate([
    Component
], ModalDeliveryOrderDelete);
export default ModalDeliveryOrderDelete;
