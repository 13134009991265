var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hide-title-section": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.selectedHardwareListTemplate ? _c('SearchHardwareListTemplate', {
          attrs: {
            "title": "Currently assigned Template",
            "existing-values": _vm.existingTemplateValues,
            "output-mode": "object"
          },
          on: {
            "input": _vm.onSearchHardwareListTemplateSelection
          }
        }) : _c('MiniHardwareListTemplate', {
          attrs: {
            "entity": _vm.selectedHardwareListTemplate
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "ml-0": ""
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }, [_vm.hardwareListHasTemplate ? [_vm._v("Reassign template")] : [_vm._v("Assign template")]], 2), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };