var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { transformUserGroupResponses, transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { reorderGroupsByGroupCodeOrder } from '~/nasa_ui/utils/helpers/user';
let AppUserGroupList = class AppUserGroupList extends Mixins(ReadOnlyMixin, UserRepoMixin) {
    userGroups = [];
    groupCodes;
    value;
    // reminder: vue will not actually wait for this async function to finish
    async created() {
        if (this.value) {
            this.userGroups = [...this.value];
            return;
        }
        if (this.groupCodes?.length) {
            const groups = await this.fetchGroupsByCode(this.groupCodes);
            this.userGroups = groups ? [...groups] : [];
        }
    }
    // called from ui
    computedGroupName(userGroup) {
        // if it has a name, use it
        if (userGroup.name) {
            return userGroup.name;
        }
        else if (userGroup.delegateGroupForUser) {
            // if it's a delegate group, return the user's full name
            const user = transformUserResponses([userGroup.delegateGroupForUser])[0];
            return user._fullName;
        }
        else {
            // as a fallback, use the group code
            return userGroup.code;
        }
    }
    // called from ui
    removeUserGroup(userGroup) {
        this.userGroups = this.userGroups.filter((group) => group.code !== userGroup.code);
    }
    onChangeOfUserGroups(userGroups) { }
    onChangeOfValueLength(userGroups) {
        if (userGroups.length !== this.userGroups.length) {
            this.userGroups = [...userGroups];
        }
    }
    async fetchGroupsByCode(groupCodes) {
        const groupsQuery = await this.getUserGroupsByGroupCodes(groupCodes);
        const transformedGroups = transformUserGroupResponses(groupsQuery?.data.results || []);
        return reorderGroupsByGroupCodeOrder(transformedGroups, groupCodes);
    }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppUserGroupList.prototype, "groupCodes", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppUserGroupList.prototype, "value", void 0);
__decorate([
    Emit('input'),
    Watch('userGroups', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppUserGroupList.prototype, "onChangeOfUserGroups", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppUserGroupList.prototype, "onChangeOfValueLength", null);
AppUserGroupList = __decorate([
    Component
], AppUserGroupList);
export default AppUserGroupList;
