var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      hasNoActions: !_vm.hasAnyActions
    },
    on: {
      "dblclick": function ($event) {
        _vm.showTemplateItemsList = !_vm.showTemplateItemsList;
      }
    }
  }, [_c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE],
      "icon-size": "32px",
      "icon-color": "var(--v-hardware_list_templates-base)"
    }
  }), _vm.transformedSelectedEntity.name ? _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name monospace_font text-truncate display-1"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.name) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down white--text"
  }, [_vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style
    }, [_c('div', {
      staticClass: "text-capitalize mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Template slots")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._slotCount) + " ")])])], 2), _c('div', {
    staticClass: "mini_actions_v2",
    staticStyle: {
      "gap": "0.75rem",
      "justify-content": "right"
    }
  }, [_vm.allowEdit ? _c('ButtonMiniActionEdit', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfEdit.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.allowEdit ? _c('ButtonMiniActionCreate', {
    attrs: {
      "tooltip": "Add item to list"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfAddItemToList.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.hasTemplateItems ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.showTemplateItemsList ? _vm.$icons.chevron_up : _vm.$icons.chevron_down,
      "tooltip": _vm.showTemplateItemsList ? 'Hide template items' : 'Show template items',
      "tooltip-position": "left",
      "icon-color": "var(--v-hardware_list_templates-base)"
    },
    on: {
      "click": _vm.onClickOfShowTemplateItemsList
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.clear,
      "icon-color": "var(--v-hardware_list_templates-base)"
    },
    on: {
      "click": _vm.onClickOfInlineClear
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showTemplateItemsList && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "hardware_list_template_mini_child_container"
  }, [_c('AppTable', {
    attrs: {
      "headers": _vm.tableHeadersForTemplateItems,
      "dense-rows": "",
      "items": _vm.transformedSelectedEntity._slots
    },
    scopedSlots: _vm._u([{
      key: "_min",
      fn: function ({
        item
      }) {
        return [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._minQuantity) + " ")])];
      }
    }, {
      key: "_max",
      fn: function ({
        item
      }) {
        return [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._maxQuantity) + " ")])];
      }
    }, {
      key: "_hardwareListTemplateParts",
      fn: function ({
        item
      }) {
        return _vm._l(item._hardwareListTemplateParts, function (part) {
          return _c('div', {
            key: part.id,
            staticClass: "part__display"
          }, [_c('div', {
            staticClass: "part__item"
          }, [_c('p', {
            staticClass: "part__label"
          }, [_vm._v("Drawing num:")]), _c('p', {
            staticClass: "part__value pl-0 font-weight-bold monospace_font"
          }, [_vm._v(_vm._s(part.drawingNumber || _vm.DEFAULT_DASH))])]), part.asBuiltNumber && part.asBuiltNumber !== _vm.DB_DEFAULT ? _c('div', {
            staticClass: "part__item"
          }, [_c('p', {
            staticClass: "part__label"
          }, [_vm._v("AsBuilt:")]), _c('p', {
            staticClass: "part__value pl-0 font-weight-bold monospace_font"
          }, [_vm._v(_vm._s(part.asBuiltNumber || _vm.DEFAULT_DASH))])]) : _vm._e(), part.side && part.side !== _vm.Side.NONE ? _c('div', {
            staticClass: "part__item"
          }, [_c('p', {
            staticClass: "part__label"
          }, [_vm._v("Side:")]), _c('p', {
            staticClass: "part__value pl-0 font-weight-bold monospace_font"
          }, [_vm._v(" " + _vm._s(part.side ? _vm.SideDisplay.get(part.side) : _vm.DEFAULT_DASH) + " ")])]) : _vm._e()]);
        });
      }
    }], null, false, 1858006167)
  })], 1) : _vm._e()]), _vm.showModalHardwareListTemplateEdit && _vm.selectedEntity ? _c('ModalHardwareListTemplateEdit', {
    attrs: {
      "hardware-list-template-id": _vm.selectedEntity.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showModalHardwareListTemplateEdit = false;
      }
    }
  }) : _vm._e(), _vm.showModalHardwareListTemplateItemCreate && _vm.selectedEntity ? _c('ModalHardwareListTemplateItemCreate', {
    key: _vm.selectedEntity.id,
    attrs: {
      "hardware-list-template-id": _vm.selectedEntity.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showModalHardwareListTemplateItemCreate = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };