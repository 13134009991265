import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DeliveryOrderLineItemStatus } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, DeliveryOrderLineItemStatusDisplay } from '~/nasa_ui/types';
import { getDeliveryOrder, putDeliveryOrderLineItem } from '~/nasa_ui/utils';
let ModalDeliveryOrderLineItemUpdate = class ModalDeliveryOrderLineItemUpdate extends Mixins(BaseModal) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    lineItem = null;
    formData = {
        attributes: {},
        deliveryOrderLineItemStatus: null,
        projectedDate: null
    };
    changeSetId;
    deliveryOrderId;
    async onClickOfSave() {
        if (!this.lineItem) {
            console.log('Could not find line item to update');
            return;
        }
        // this request body needs to list every property of the line item, even if
        // it's not being updated. It’s typed to be explicit (no undefined values)
        // because omitting a field here could lead to unintended data loss.
        const body = {
            attributes: this.lineItem.attributes,
            deliveryItemsListNumber: this.lineItem.deliveryItemsListNumber ?? null,
            deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
            jDocumentNumber: this.lineItem.jDocumentNumber ?? null,
            lineItemQuantity: this.lineItem.lineItemQuantity ?? null,
            lineItemType: null,
            need: this.lineItem.need ?? null,
            needDate: this.lineItem.needDate ?? null,
            openedDate: this.lineItem.openedDate,
            ownerContactId: this.lineItem.ownerContactId ?? null,
            partReferenceId: this.lineItem.partReferenceId ?? null,
            projectedDate: this.formData.projectedDate ?? null,
            shipperNumber: this.lineItem.shipperNumber ?? null,
            workBreakdownStructureNumber: this.lineItem.workBreakdownStructureNumber ?? null
        };
        try {
            // cast the request body because some fields don’t expect null
            const resp = await putDeliveryOrderLineItem(this.deliveryOrderId, this.changeSetId, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onDeliveryOrderIdChange() {
        try {
            const deliveryOrder = await getDeliveryOrder(this.deliveryOrderId);
            this.lineItem = deliveryOrder.data.lineItems.changeSets?.find((cs) => cs.id === this.changeSetId);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Number,
        required: true
    }),
    __metadata("design:type", Number)
], ModalDeliveryOrderLineItemUpdate.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalDeliveryOrderLineItemUpdate.prototype, "deliveryOrderId", void 0);
__decorate([
    Watch('deliveryOrderId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemUpdate.prototype, "onDeliveryOrderIdChange", null);
ModalDeliveryOrderLineItemUpdate = __decorate([
    Component
], ModalDeliveryOrderLineItemUpdate);
export default ModalDeliveryOrderLineItemUpdate;
