import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { BASE_ICON_COL, BASE_NUMBER_COL, NAME_DISPLAY_COL, NUMBER_COL, REVISION_COL, SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformHardwareListResponses } from '~/nasa_ui/utils/helpers/transforms/hardware';
let SearchHardwareList = class SearchHardwareList extends BaseSearch {
    entityType = EntityType.HARDWARE_LIST;
    hint = 'number';
    placeholder = 'search for hardware lists';
    selectedHardwareList = null;
    tableHeaders = [
        composeTableHeader(NUMBER_COL, {
            noWrap: false
        }),
        NAME_DISPLAY_COL,
        SUB_TYPE_COL,
        composeTableHeader(BASE_ICON_COL, {
            text: 'Built using<br/>Template?',
            value: '_builtUsingTemplate',
            sortable: true,
            noEscape: true
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Parts count',
            value: '_partCount'
        }),
        REVISION_COL
    ];
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformHardwareListResponses([...this.rawSearchResponse]);
        transformed.sort(sortObjectNumericallyBy('_updatedDateTimeTicks'));
        return transformed;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            let params = {
                query: this.searchInput,
                take: -1
            };
            if (this.filters) {
                params = { ...params, ...this.filters };
            }
            // Execute query
            const response = await this.$http.get(`/hardware-lists`, {
                params
            });
            this.isLoading = false;
            // Status errors are valid responses, it won't hit the catch because the Promise is fulfilled
            if (response.status > 399) {
                this.$errorUtility({
                    err: new Error('There was an error searching for this Assembly Template.'),
                    backupErrorText: 'There was an error searching for this Assembly Template.'
                });
            }
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data.results;
            this.emitSearchPerformed();
        }
        catch (error) {
            this.isLoading = false;
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchHardwareList.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchHardwareList.prototype, "onClickOfSearchResult", null);
SearchHardwareList = __decorate([
    Component
], SearchHardwareList);
export default SearchHardwareList;
