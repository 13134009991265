var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.localEntity ? _c('div', [_vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  })], 2) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      hideChips: _vm.$vuetify.breakpoint.smAndDown,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_c('div', {
    staticClass: "mini_chips"
  }, [_c('v-chip', {
    staticClass: "mini_chip seq_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.localEntity.sequence))])])])], 1), _c('div', {
    staticClass: "entity-mini-name text-truncate"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])]), _c('div', {
    staticClass: "mini_chips hidden-sm-and-down"
  }, [_c('v-chip', {
    staticClass: "mini_chip min_max_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Min/Max:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.minQuantityDisplay)))]), _vm._v("/"), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.maxQuantityDisplay)))])])]), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({
          staticClass: "hardware_list_template--parts"
        }, on), [_vm.singleHardwareListTemplatePartDisplay && _vm.shouldShowHardwareListTemplatePartsDisplay ? _c('div', {
          staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
        }, [_c('a', {
          attrs: {
            "href": _vm.singleHardwareListTemplatePartDisplayHref
          },
          on: {
            "click": _vm.onClickOfPartChip
          }
        }, [_c('v-chip', {
          staticClass: "mini_chip text-truncate"
        }, [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.iconForHardwareListTemplatePartSingle,
            "icon-size": "15px"
          }
        }), _c('strong', [_vm._v(_vm._s(_vm.singleHardwareListTemplatePartDisplay))])], 1)], 1)]) : _vm._e(), _vm.singleHardwareListTemplatePartDisplay && _vm.shouldShowHardwareListTemplatePartsDisplay && _vm.singleHardwareListTemplatePartItemDrawingDrawingNumber ? _c('div', {
          staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
        }, [_c('router-link', {
          attrs: {
            "to": _vm.buildItemDrawingManageLink(_vm.singleHardwareListTemplatePartItemDrawingDrawingNumber)
          }
        }, [_c('v-chip', {
          staticClass: "mini_chip text-truncate"
        }, [_c('strong', [_vm._v(_vm._s(_vm.hardwareListTemplatePartDescription))])])], 1)], 1) : _c('v-chip', {
          staticClass: "mini_chip text-truncate",
          attrs: {
            "disabled": ""
          }
        }, [_vm.iconForHardwareListTemplatePartMultiple ? _c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.iconForHardwareListTemplatePartMultiple,
            "icon-size": "14px"
          }
        }) : _vm._e(), _c('span', {
          staticClass: "monospace_font pr-1"
        }, [_c('strong', [_vm._v(_vm._s(_vm.myHardwareListTemplateParts.length))])]), _c('span', [_vm._v("parts selected")])], 1)], 1)];
      }
    }], null, false, 1874656847)
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.hardwareListTemplatePartsTitle,
      expression: "hardwareListTemplatePartsTitle"
    }]
  })])], 1)], 1), _c('div', {
    staticClass: "mini_actions_v2"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons['eye'],
      "tooltip": "Show/Hide detail"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfShowDetail.apply(null, arguments);
      }
    }
  }), _vm.allowEdit ? _c('ButtonMiniActionEdit', {
    attrs: {
      "tooltip": "Edit Hardware List Template slot"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfEdit.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.allowInstall ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.install,
      "icon-color": "var(--v-success-base)",
      "tooltip": "Create List item in Template slot"
    },
    on: {
      "click": _vm.onClickOfInstall
    }
  }) : _vm._e(), _vm.inlineClear ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.close,
      "tooltip": "Clear Hardware List Template slot"
    },
    on: {
      "click": _vm.onClickOfInlineClear
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.hasLocalEntity ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.entityType,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _vm.showModalHardwareListEditTemplateSlot ? _c('ModalHardwareListTemplateSlotEdit', {
    attrs: {
      "hardware-list-template-slot-id": _vm.localEntity.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showModalHardwareListEditTemplateSlot = false;
      }
    }
  }) : _vm._e(), _c('v-fade-transition', [_vm.showDetail ? _c('AppFormWrapper', {
    attrs: {
      "color": _vm.color,
      "top": "0",
      "right": "1",
      "left": "1",
      "bottom": "1"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Name"
    }
  }, [_vm._v(_vm._s(_vm.name))])], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Max qty",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$numberDisplay(_vm.maxQuantityDisplay)) + " ")])])], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Min qty",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$numberDisplay(_vm.minQuantityDisplay)) + " ")])])], 1)], 1), _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "headers": _vm.AssemblyTemplatePartTableHeaders,
      "hide-pagination": _vm.hardwareListTemplatePartTableItems.length < 5,
      "items": _vm.hardwareListTemplatePartTableItems
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_vm.buildHardwareUrl(item) ? _c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildHardwareUrl(item)
          }
        }) : _vm._e()];
      }
    }], null, false, 4281860024)
  }, [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v("Hardware shopping list")]), _vm._v(" ( "), _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.hardwareListTemplatePartTableItems.length) + " items")])]), _vm._v(" ) ")])], 2)], 1) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };