var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserSupportContextToValidLinkTypes } from '~/application.config';
import { CommentType, LinkType, Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { AlertType, LinksTypeDisplay } from '~/nasa_ui/types';
import { CommentTypeDisplay } from '~/nasa_ui/types/enums/comment';
let ModalLinkEdit = class ModalLinkEdit extends Mixins(BaseModalWithUser) {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    isSaving = false;
    selectedEntity = null;
    formData = {
        currentRevision: null,
        currentVersionUrl: null,
        referenceNumber: null,
        subType: LinkType.OTHER,
        workingVersionUrl: null
    };
    id;
    get computedSelectOptions() {
        const linkTypes = UserSupportContextToValidLinkTypes.get(this.currentUserActiveSupportContext);
        return linkTypes
            ? linkTypes.map((linkType) => {
                return {
                    displayText: LinksTypeDisplay.get(linkType),
                    value: linkType
                };
            })
            : [];
    }
    // subType and at least one other field is required
    get isFormValid() {
        return Boolean(this.formData.subType &&
            (this.formData.currentRevision ||
                this.formData.currentVersionUrl ||
                this.formData.referenceNumber ||
                this.formData.workingVersionUrl));
    }
    async onClickOfUpdate() {
        if (!this.isFormValid) {
            return;
        }
        try {
            this.isSaving = true;
            const body = {
                currentRevision: this.formData.currentRevision,
                currentVersionUrl: this.formData.currentVersionUrl,
                referenceNumber: this.formData.referenceNumber,
                subType: this.formData.subType,
                workingVersionUrl: this.formData.workingVersionUrl
            };
            const resp = await this.$http.put(`/links/${this.id}`, body);
            if (resp) {
                this.isSaving = false;
                this.$notification.add({
                    text: 'Updated.',
                    type: AlertType.SUCCESS
                });
                this.emitUpdated();
                this.emitModalClose();
            }
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    emitUpdated() { }
    async onChangeOfId(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await this.$http.get(`/links/${id}`);
            if (!resp.data) {
                new Error('Failed to fetch link.');
                return;
            }
            this.selectedEntity = resp.data;
            this.formData = {
                currentRevision: this.selectedEntity?.currentRevision || null,
                currentVersionUrl: this.selectedEntity?.currentVersionUrl || null,
                referenceNumber: this.selectedEntity?.referenceNumber || null,
                subType: this.selectedEntity?.subType || LinkType.OTHER,
                workingVersionUrl: this.selectedEntity?.workingVersionUrl || null
            };
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalLinkEdit.prototype, "id", void 0);
__decorate([
    Emit('updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkEdit.prototype, "emitUpdated", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ModalLinkEdit.prototype, "onChangeOfId", null);
ModalLinkEdit = __decorate([
    Component
], ModalLinkEdit);
export default ModalLinkEdit;
