var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DeliveryOrderLineItemResponse } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { transformDeliveryOrderLineItemResponses } from '~/nasa_ui/utils/helpers/transforms/documents';
let ModalDeliveryOrderLineItemInfo = class ModalDeliveryOrderLineItemInfo extends Mixins(BaseModal) {
    DEFAULT_DASH = DEFAULT_DASH;
    selectedEntity = null;
    lineItem;
    get computedModalWidth() {
        return this.transformedSelectedEntity?.partReference.itemInstanceId ? '99vw' : '70vw';
    }
    get transformedSelectedEntity() {
        return this.lineItem ? transformDeliveryOrderLineItemResponses([this.lineItem])[0] : null;
    }
    async fetchSelectedEntity() {
        if (!this.lineItem) {
            return;
        }
        this.selectedEntity = { ...this.lineItem };
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof DeliveryOrderLineItemResponse !== "undefined" && DeliveryOrderLineItemResponse) === "function" ? _a : Object)
], ModalDeliveryOrderLineItemInfo.prototype, "lineItem", void 0);
__decorate([
    Watch('lineItem', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalDeliveryOrderLineItemInfo.prototype, "fetchSelectedEntity", null);
ModalDeliveryOrderLineItemInfo = __decorate([
    Component
], ModalDeliveryOrderLineItemInfo);
export default ModalDeliveryOrderLineItemInfo;
