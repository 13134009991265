import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
let ModalHardwareListPartEdit = class ModalHardwareListPartEdit extends Mixins(BaseModal, BaseCosmic) {
    hardwareList = null;
    selectedPartReference = null;
    selectedPartReferenceEdited = null;
    selectedPartReferenceRequest = null;
    formData = {
        addendumDate: null,
        addendumNumber: null,
        quantity: null,
        sequence: null,
        notes: null
    };
    get hardwareListPart() {
        return this.hardwareListPartId
            ? this.hardwareList?.hardwareListParts?.find((hlp) => hlp.id === this.hardwareListPartId)
            : null;
    }
    hardwareListId;
    hardwareListPartId;
    get isFormValid() {
        return this.isSequenceValid && Boolean(this.formData.quantity) && this.formData.quantity > 0;
    }
    get isSequenceNumberAlreadyInUse() {
        // return this.hardwareList?.hardwareListParts?.some((node) => node?.sequence === this.formData.sequence) ?? false;
        return false;
    }
    get isSequenceValid() {
        const isValidNumber = Boolean(this.formData.sequence);
        return isValidNumber && !this.isSequenceNumberAlreadyInUse;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid;
    }
    async onClickOfSave() {
        if (!this.isFormValid) {
            return;
        }
        try {
            this.isSaving = true;
            const body = {
                addendumDate: this.formData.addendumDate,
                addendumNumber: this.formData.addendumNumber,
                quantity: this.formData.quantity,
                sequence: this.formData.sequence,
                notes: this.formData.notes
            };
            if (this.selectedPartReferenceEdited) {
                this.selectedPartReference = await this.updatePartReference(this.selectedPartReferenceEdited);
            }
            if (this.selectedPartReference) {
                body.partReferenceId = this.selectedPartReference.id;
            }
            if (this.selectedPartReferenceRequest) {
                body.createPartReference = { ...this.selectedPartReferenceRequest };
            }
            if (this.selectedPartReferenceEdited) {
                body.partReferenceId = this.selectedPartReferenceEdited.id;
            }
            const resp = await this.$http.put(`/hardware-lists/${this.hardwareListId}/parts/${this.hardwareListPartId}`, body);
            this.emitEntityEdited('Saved.');
            this.$message(`reload_${this.hardwareListPartId}`);
            this.$message(`reload_${this.hardwareListId}`);
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    onPartReferenceEdited(partReference) {
        this.selectedPartReferenceEdited = partReference;
    }
    onPartReferenceSelected(partReference) {
        this.selectedPartReference = partReference;
    }
    onNewPartReferenceSelected(partReferenceRequest) {
        this.selectedPartReferenceRequest = partReferenceRequest;
    }
    async updatePartReference(partReference) {
        const request = {
            attributes: {},
            asBuiltNumber: partReference?.asBuiltNumber,
            birthDate: partReference?.birthDate,
            calibrationNumber: partReference?.calibrationNumber,
            contractEndingItemNumber: partReference?.contractEndingItemNumber,
            drawingDescription: partReference?.drawingDescription,
            drawingNumber: partReference?.drawingNumber,
            isTool: partReference?.isTool ?? false,
            itemClass: partReference?.itemClass,
            lastCalibrationDate: partReference?.lastCalibrationDate,
            location: partReference?.location,
            lotNumber: partReference?.lotNumber,
            serialNumber: partReference?.serialNumber,
            shelfLifeExpirationDate: partReference?.shelfLifeExpirationDate,
            side: partReference?.side,
            usageLifeExpirationDate: partReference?.usageLifeExpirationDate
        };
        try {
            const resp = await this.$http.put(`/part-references/${partReference.id}`, request);
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchHardwareListById() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/hardware-lists/${this.hardwareListId}`);
            this.hardwareList = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onHlpFound() {
        if (!this.hardwareListPart) {
            return;
        }
        this.formData.addendumDate = this.hardwareListPart.addendumDate;
        this.formData.addendumNumber = this.hardwareListPart.addendumNumber;
        this.formData.notes = this.hardwareListPart.notes;
        this.formData.quantity = this.hardwareListPart.quantity;
        this.formData.sequence = this.hardwareListPart.sequence;
        if (this.hardwareListPart.partReference) {
            this.selectedPartReference = this.hardwareListPart.partReference;
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalHardwareListPartEdit.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalHardwareListPartEdit.prototype, "hardwareListPartId", void 0);
__decorate([
    DebounceAll(10),
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListPartEdit.prototype, "fetchHardwareListById", null);
__decorate([
    Watch('hardwareListPart'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListPartEdit.prototype, "onHlpFound", null);
ModalHardwareListPartEdit = __decorate([
    Component
], ModalHardwareListPartEdit);
export default ModalHardwareListPartEdit;
