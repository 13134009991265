var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEntity ? _c('div', {
    staticClass: "entity-mini item_instances",
    class: {
      hasNoActions: !_vm.hasAnyActions
    }
  }, [_c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1 monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._itemNumber) + " ")]), !_vm.transformedSelectedEntity.itemDrawingDescription ? _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "title": _vm.transformedSelectedEntity._description
    }
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))]) : _vm._e()]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "vertical-display-item item_instance_sub_type white--text",
    attrs: {
      "tooltip": "Multiple Inventories could exist with different classes. This field is computed at this level of Hardware.",
      "tooltip-position": "right"
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm.shouldDisplayComputedClassLabel ? _c('span', [_vm._v("Computed"), _c('br')]) : _vm._e(), _vm._v(" Class ")]), _c('div', {
    staticClass: "font-weight-bold item_class_value monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._subType))])]) : _vm._e(), _c('div', {
    staticClass: "vertical-display-items white--text",
    class: {
      is_archived: _vm.isArchived
    }
  }, [_vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), _vm.isArchived ? _c('div', {
    staticClass: "vertical-display-item text-xs-center"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-color": "white",
      "icon": _vm.$icons.archived,
      "tooltip": "This Item Instance has been archived.",
      "tooltip-position": "left"
    }
  })], 1) : _vm._e()], 2), _c('div', {
    staticClass: "mini_actions"
  }, [_vm.transformedSelectedEntity && _vm.showInfoIcon ? _c('ButtonMiniActionInfo', {
    staticClass: "mr-2",
    attrs: {
      "icon-color": "white"
    },
    on: {
      "click": _vm.emitInfoClicked
    }
  }) : _vm._e(), _vm.shouldShowManageLink ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "href": _vm.manageLink,
      "icon": _vm.$icons.MANAGE_PAGE,
      "tooltip": "Manage metadata for this Item Instance",
      "tooltip-position": "left",
      "is-link": ""
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.strong_close,
      "tooltip": "Remove this from the list",
      "tooltip-position": "left"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "center": "",
      "color": _vm.EntityType.ITEM_INSTANCE,
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };