var _a;
import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { HardwareListType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { HardwareListTypeDisplay } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let ModalHardwareListCreate = class ModalHardwareListCreate extends Mixins(BaseModal, BaseCosmic) {
    enforceNamingStandard = false;
    hardwareListExists = false;
    hardwareListTemplates = [];
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    selectedListTemplate = null;
    eventId;
    hardwareListTemplateId;
    isCritical;
    formData = {
        addendumDate: null,
        addendumNumber: null,
        authorizer: null,
        closeDate: null,
        documentId: null,
        eventId: null,
        hardwareListTemplateId: null,
        isCritical: false,
        name: null,
        number: null,
        openDate: null,
        revision: null,
        subType: null
    };
    get isFormValid() {
        return Boolean(this.formData.number && this.formData.subType);
    }
    get createHardwareListVariables() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            addendumDate: this.formData.addendumDate,
            addendumNumber: this.formData.addendumNumber,
            authorizer: this.formData.authorizer,
            closeDate: this.formData.closeDate,
            documentId: this.formData.documentId,
            eventId: this.formData.eventId,
            hardwareListTemplateId: this.formData.hardwareListTemplateId,
            isCritical: this.formData.isCritical ?? false,
            name: this.formData.name,
            number: this.formData.number,
            openDate: this.formData.openDate,
            revision: this.formData.revision,
            subType: this.formData.subType
        };
    }
    get filteredHardwareListTypes() {
        const cDocumentsHardwareListTypes = [
            'WORK_ORDER_STEP_HARDWARE',
            'WORK_ORDER_STEP_TOOLS',
            'COSMIC_DOCUMENT_HARDWARE',
            'COSMIC_DOCUMENT_TOOLS'
        ];
        return Object.values(HardwareListType)
            .map((value) => {
            return {
                displayText: HardwareListTypeDisplay.get(value) || '',
                value: value,
                disabled: false
            };
        })
            .filter((mappedValueForAppSelect) => {
            return !Object.values(cDocumentsHardwareListTypes).includes(mappedValueForAppSelect.value);
        })
            .sort(sortObjectBy('displayText'));
    }
    get numberPrefix() {
        const year = new Date().getFullYear().toString().substring(2); // YY format
        return this.enforceNamingStandard ? `HL-${year}-` : '';
    }
    get shouldDisableSaveButton() {
        return this.isSaving || !this.isFormValid || this.hardwareListExists;
    }
    async onClickOfCreate() {
        if (!this.createHardwareListVariables) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.post(`/hardware-lists`, this.createHardwareListVariables);
            if (resp) {
                this.emitEntityCreated('List created.');
                this.emitModalClose();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    onEventIdChange() {
        if (this.eventId) {
            this.formData.eventId = this.eventId;
        }
    }
    onHardwareListTemplateIdChange() {
        if (this.hardwareListTemplateId) {
            this.formData.hardwareListTemplateId = this.hardwareListTemplateId;
        }
    }
    async checkForExistence() {
        if (this.formData.number && this.formData.subType) {
            try {
                const reqObj = {
                    number: this.formData.number,
                    subType: this.formData.subType
                };
                const resp = await this.$http.post(`/hardware-lists/exists`, reqObj);
                this.hardwareListExists = resp.data.exists;
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
        else {
            this.hardwareListExists = false;
        }
    }
    async fetchHardwareListTemplateById(hardwareListTemplateId) {
        if (!hardwareListTemplateId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/hardware-list-templates/${hardwareListTemplateId}`);
            this.selectedListTemplate = resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    onIsCriticalChange() {
        this.formData.isCritical = this.isCritical;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListCreate.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListCreate.prototype, "hardwareListTemplateId", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalHardwareListCreate.prototype, "isCritical", void 0);
__decorate([
    Watch('eventId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListCreate.prototype, "onEventIdChange", null);
__decorate([
    Watch('hardwareListTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListCreate.prototype, "onHardwareListTemplateIdChange", null);
__decorate([
    DebounceAll(100),
    Watch('formData.number', { immediate: true }),
    Watch('formData.subType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListCreate.prototype, "checkForExistence", null);
__decorate([
    Watch('formData.selectedHardwareListId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ModalHardwareListCreate.prototype, "fetchHardwareListTemplateById", null);
__decorate([
    Watch('isCritical', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListCreate.prototype, "onIsCriticalChange", null);
ModalHardwareListCreate = __decorate([
    Component
], ModalHardwareListCreate);
export default ModalHardwareListCreate;
