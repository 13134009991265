import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { UserPermission, UserPermissionBlurb } from '~/nasa_ui/types/enums/user';
const initUserPermissionObject = () => Object.keys(UserPermission).reduce((obj, key) => {
    obj[key] = null;
    return obj;
}, {});
let TabUserPermissions = class TabUserPermissions extends Mixins(BaseTab, HttpMixin, UserRepoMixin) {
    permissionsPayload = null;
    previousPermissions = null;
    selectedPermissions = [];
    selectedSupportContext = null;
    user = null;
    userPermissionsForm = initUserPermissionObject();
    permissions;
    permissionTableHeaders = [
        {
            text: 'Permission',
            value: 'value'
        },
        {
            text: 'What it is for',
            value: 'whatItIsFor',
            noEscape: true
        }
    ];
    get activePermissions() {
        // Really this is the true Permissions
        return this.selectedPermissions.reduce((validPermissions, perm) => {
            validPermissions[perm.value] = true;
            return validPermissions;
        }, {});
    }
    // used to populate table with all permissions
    get allUserPermissions() {
        return this.permissions.map((permission, index) => {
            return {
                value: permission,
                name: permission,
                whatItIsFor: UserPermissionBlurb.get(permission) || NOT_APPLICABLE
            };
        });
    }
    get canEditPermissions() {
        return this.isRoleAdmin || this.currentUserHasPermission(UserPermission.USER_PERMISSIONS);
    }
    get isEditingYourself() {
        return this.currentUser?.id === this.$route.params.id;
    }
    get isRoleAdmin() {
        return this.currentUserIsAdmin;
    }
    get userId() {
        return this.user?.id;
    }
    async fetchEntity(id) {
        try {
            const resp = await this.getUserById(id);
            this.user = resp?.data;
            if (this.user) {
                // CJE:  only allowed to update based on user's current context
                this.selectedSupportContext = this.currentUserActiveSupportContext;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    mapPermissionsToNameValue(permissions) {
        return Object.keys(permissions).map((permission) => {
            return {
                value: permission,
                name: permission
            };
        });
    }
    async mutatePermissions(userSupportContext) {
        if (!this.userId) {
            throw new Error('No user id found.');
        }
        if (!this.selectedSupportContext) {
            throw new Error('No selectedSupportContext.');
        }
        if (!userSupportContext) {
            throw new Error('No UserSupportContextPatch provided.');
        }
        const resp = await this.$http.put(`/users/${this.userId}/support-context`, userSupportContext);
        return resp?.data;
    }
    async onClickOfSave() {
        try {
            await this.mutatePermissions({
                permissions: this.permissionsPayload
            });
            this.$message('reload', true);
            this.scrollToTop();
            if (this.isEditingYourself) {
                this.$notification.add({
                    text: 'Reinitializing your permissions.',
                    type: this.AlertType.INFO
                });
                localStorage.removeItem('up');
                setTimeout(() => {
                    location.reload();
                }, 3000);
            }
            else {
                this.$notification.add({
                    text: 'User permissions updated.',
                    type: this.AlertType.SUCCESS
                });
            }
            this.$emit('complete');
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
        }
    }
    syncPermissionsWithSupportContext() {
        if (this.user) {
            const supports = this.user.supports || [];
            const support = supports.find((support) => support && support.organizationCode === this.selectedSupportContext);
            this.previousPermissions = Object.assign({}, support?.permissions);
            // remove any previous permissions that will be in the form
            this.allUserPermissions.forEach((userPermission) => {
                if (this.previousPermissions?.hasOwnProperty(userPermission.value)) {
                    delete this.previousPermissions[userPermission.value];
                }
            });
            // remove false values
            if (support && support.permissions) {
                Object.keys(support.permissions).forEach((key) => {
                    if (this.user && !support.permissions[key]) {
                        delete support.permissions[key];
                    }
                });
                this.$set(this, 'selectedPermissions', this.mapPermissionsToNameValue(support.permissions));
            }
        }
    }
    onIdChange(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
    updatePermissionPayload() {
        this.permissionsPayload = Object.assign({}, this.userPermissionsForm, this.previousPermissions, this.activePermissions);
    }
};
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], TabUserPermissions.prototype, "permissions", void 0);
__decorate([
    Watch('selectedSupportContext', { immediate: false }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabUserPermissions.prototype, "syncPermissionsWithSupportContext", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TabUserPermissions.prototype, "onIdChange", null);
__decorate([
    Watch('activePermissions'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabUserPermissions.prototype, "updatePermissionPayload", null);
TabUserPermissions = __decorate([
    Component
], TabUserPermissions);
export default TabUserPermissions;
