/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./FileAttachmentDropzone.vue?vue&type=template&id=534d7ac6&scoped=true"
import script from "./FileAttachmentDropzone.ts?vue&type=script&lang=ts&external"
export * from "./FileAttachmentDropzone.ts?vue&type=script&lang=ts&external"
import style0 from "./FileAttachmentDropzone.vue?vue&type=style&index=0&id=534d7ac6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534d7ac6",
  null
  
)

export default component.exports