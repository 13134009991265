var render = function render() {
  var _vm$transformedSelect;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini hardware_lists",
    class: {
      hasNoActions: !_vm.hasAnyActions
    },
    on: {
      "dblclick": function ($event) {
        _vm.showPartsList = !_vm.showPartsList;
      }
    }
  }, [_c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }), _vm.transformedSelectedEntity.number ? _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name monospace_font text-truncate display-1"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._number) + " ")]), _vm.transformedSelectedEntity.name ? _c('small', {
    staticClass: "caption pl-1"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._name))]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down white--text"
  }, [_c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Parts")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partCount) + " ")])]), _vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style
    }, [_c('div', {
      staticClass: "text-capitalize mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  })], 2), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions_v2"
  }, [_vm.showManageLink && _vm.transformedSelectedEntity && _vm.transformedSelectedEntity._urlManage ? _c('ButtonMiniActionManage', {
    staticClass: "mr-2",
    attrs: {
      "to": _vm.transformedSelectedEntity._urlManage,
      "icon-color": "white"
    }
  }) : _vm._e(), _vm.allowEdit ? _c('ButtonMiniActionEdit', {
    staticClass: "mr-2",
    on: {
      "click": _vm.onClickOfEdit
    }
  }) : _vm._e(), _vm.allowChildCreation ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "tooltip": "Create List item",
      "icon": _vm.$icons.create_fat,
      "tooltip-position": "left",
      "icon-color": "white"
    },
    on: {
      "click": function ($event) {
        _vm.showModalHardwareListItemCreate = true;
      }
    }
  }) : _vm._e(), _vm.hasParts ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.showPartsList ? _vm.$icons.chevron_up : _vm.$icons.chevron_down,
      "tooltip": "Show / hide parts list",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfShowPartsList
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.clear
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showPartsList ? _c('div', {
    staticClass: "hardware_list_mini_child_container"
  }, [_c('AppTable', {
    attrs: {
      "headers": _vm.tableHeadersForPartsList,
      "dense-rows": "",
      "disable-initial-sort": "",
      "items": (_vm$transformedSelect = _vm.transformedSelectedEntity) === null || _vm$transformedSelect === void 0 ? void 0 : _vm$transformedSelect._hardwareListParts
    },
    scopedSlots: _vm._u([{
      key: "_entityIcon",
      fn: function ({
        item
      }) {
        return [item.itemInstanceId ? _c('div', {
          attrs: {
            "tooltip": `List item is a non-COSMIC part`,
            "tooltip-position": "right"
          }
        }, [_c('IconNotCosmic', {
          attrs: {
            "color": "black"
          }
        })], 1) : _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
            "icon-color": "var(--v-inventories-base)",
            "tooltip": "List item is a COSMIC instance",
            "tooltip-position": "right"
          }
        })];
      }
    }, {
      key: "_partReference._location",
      fn: function ({
        item
      }) {
        return [item._partReference._location && item._partReference._location.includes('not_applicable') ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.NOT_APPLICABLE)
          }
        }) : _c('span', [_vm._v(_vm._s(item._partReference._location))])];
      }
    }], null, false, 722288623)
  })], 1) : _vm._e()]), _vm.showModalHardwareListEdit ? _c('ModalHardwareListEdit', {
    attrs: {
      "hardware-list": _vm.transformedSelectedEntity
    },
    on: {
      "modal-close": _vm.onCloseOfModal
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };