import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { BASE_ICON_COL, BASE_TEXT_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, INITIALIZATION_STATUS_ICON_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformEntitiesToTableFormat } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchItemDrawing = class SearchItemDrawing extends BaseSearch {
    entityType = EntityType.ITEM_DRAWING;
    hint = 'Drawing number, description';
    placeholder = 'search item drawings';
    userSelectedMaxResults = 1000;
    tableHeaders = [
        composeTableHeader(DRAWING_NUMBER_COL, { width: '300px' }),
        composeTableHeader(DESCRIPTION_COL, { width: '450px' }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Aliases',
            value: '_aliases',
            sortable: true,
            noEscape: true
        }),
        composeTableHeader(BASE_ICON_COL, {
            text: 'EEE<br/>identifier?',
            value: '_eeeIdentifier'
        }),
        INITIALIZATION_STATUS_ICON_COL
    ];
    searchFilters = {};
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformEntitiesToTableFormat(EntityType.ITEM_DRAWING, [...this.rawSearchResponse]);
        transformed.sort(sortObjectNumericallyBy('_updatedDateTimeTicks'));
        return transformed;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                query: this.searchInput,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            };
            // Execute query
            const resp = await this.$http.get(`/hardware/item-drawings`, {
                params
            });
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = resp.data.results;
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected err: ${err}`);
            }
        }
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchItemDrawing.prototype, "performSearch", null);
SearchItemDrawing = __decorate([
    Component
], SearchItemDrawing);
export default SearchItemDrawing;
