var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "error",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDialog.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonRemove', {
          attrs: {
            "disabled": !_vm.selectedEntity,
            "isRemoving": _vm.isDeleting
          },
          on: {
            "click": _vm.onClickOfDelete
          }
        }, [_vm._t("remove-button-text", function () {
          return [_vm._v("Yes, I'm sure. DELETE it.")];
        })], 2), _c('ButtonCancel', {
          attrs: {
            "disabled": !_vm.selectedEntity || _vm.isDeleting
          },
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Cancel "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }], null, true)
  }, [_c('template', {
    slot: "content"
  }, [_c('Alert', {
    attrs: {
      "type": "warning"
    }
  }, [_c('div', [_vm._v("This is a "), _c('em', [_vm._v("permanent")]), _vm._v(" "), _c('strong', [_vm._v("DELETE")]), _vm._v(". Be careful.")])]), !_vm.selectedEntity ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": "documents"
    }
  }) : _vm._e(), _vm.selectedEntity ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Order number",
      "required": "",
      "upper-case": ""
    },
    model: {
      value: _vm.selectedEntity.number,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "number", $$v);
      },
      expression: "selectedEntity.number"
    }
  })], 1), _c('div', {
    staticClass: "col-half"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Title",
      "clearable": ""
    },
    model: {
      value: _vm.selectedEntity.title,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "title", $$v);
      },
      expression: "selectedEntity.title"
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Revision"
    },
    model: {
      value: _vm.selectedEntity.overrideRevisionNumber,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "overrideRevisionNumber", $$v);
      },
      expression: "selectedEntity.overrideRevisionNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-full mt-2 app-blockquote"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Description"
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.selectedEntity.description
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-third"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Contract number"
    },
    model: {
      value: _vm.selectedEntity.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "contractNumber", $$v);
      },
      expression: "selectedEntity.contractNumber"
    }
  })], 1)]) : _vm._e(), _vm.selectedEntity ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Date of order"
    },
    model: {
      value: _vm.selectedEntity.overrideOpenDate,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "overrideOpenDate", $$v);
      },
      expression: "selectedEntity.overrideOpenDate"
    }
  })], 1), _c('div', {
    staticClass: "col-third"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Contractual end"
    },
    model: {
      value: _vm.selectedEntity.estimatedCloseDate,
      callback: function ($$v) {
        _vm.$set(_vm.selectedEntity, "estimatedCloseDate", $$v);
      },
      expression: "selectedEntity.estimatedCloseDate"
    }
  })], 1)]) : _vm._e(), _vm.selectedEntity ? _c('AppTable', {
    attrs: {
      "headers": _vm.lineItemTableHeaders,
      "items": _vm.lineItemTableItems,
      "show-header": false,
      "color": "change_sets"
    },
    scopedSlots: _vm._u([{
      key: "_deliveryOrderLineItemStatus",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item._deliveryOrderLineItemStatus) + " ")];
      }
    }], null, false, 2622759700)
  }) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };