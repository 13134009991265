var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.readOnly ? _c('ButtonCreate', {
    staticClass: "mb-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showEditModal = true;
      }
    }
  }, [_vm._v(" Attach file ")]) : _vm._e(), _c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.linksColor,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.attachment,
      "items": _vm.computedTableItems,
      "title": _vm.computedTableTitle,
      "hide-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfEdit(item);
            }
          }
        }), item ? _c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons.download,
            "icon-color": "var(--v-primary-base)",
            "tooltip-position": "left",
            "tooltip": "Download file"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfDownload(item);
            }
          }
        }) : _vm._e(), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfDelete(item);
            }
          }
        })];
      }
    }])
  }), _vm.showDeleteConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600"
    },
    on: {
      "affirmativeClicked": _vm.deleteFileAttachment,
      "negativeClicked": _vm.reset
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will PERMANENTLY delete this file along with any associations with " + _vm._s(_vm.currentSystemName) + ". ")])]) : _vm._e(), _vm.showEditModal && !_vm.selectedAttachment ? _c('ModalFileAttachmentCreate', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "document-id": _vm.documentId,
      "event-id": _vm.eventId,
      "item-drawing": _vm.itemDrawing,
      "item-instance": _vm.itemInstance,
      "item-master": _vm.itemMaster,
      "work-order-step-id": _vm.workOrderStepId,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": _vm.reset,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e(), _vm.showEditModal && _vm.selectedAttachment ? _c('ModalFileAttachmentEdit', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "entity": _vm.selectedAttachment,
      "document-id": _vm.documentId,
      "event-id": _vm.eventId,
      "item-drawing": _vm.itemDrawing,
      "item-instance": _vm.itemInstance,
      "item-master": _vm.itemMaster,
      "read-only": _vm.isReadOnly,
      "work-order-step-id": _vm.workOrderStepId
    },
    on: {
      "modal-close": _vm.reset,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };