import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CommentType, DeliveryOrderLineItemStatus, DeliveryOrderLineItemType, NeedType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, DeliveryOrderLineItemStatusDisplay, DeliveryOrderLineItemTypeDisplay, NeedTypeDisplay } from '~/nasa_ui/types';
import { putDeliveryOrderLineItem } from '~/nasa_ui/utils';
let ModalDeliveryOrderLineItemUpdateMultiple = class ModalDeliveryOrderLineItemUpdateMultiple extends Mixins(BaseModal) {
    DeliveryOrderLineItemStatus = DeliveryOrderLineItemStatus;
    DeliveryOrderLineItemStatusDisplay = DeliveryOrderLineItemStatusDisplay;
    DeliveryOrderLineItemType = DeliveryOrderLineItemType;
    DeliveryOrderLineItemTypeDisplay = DeliveryOrderLineItemTypeDisplay;
    NeedTypeDisplay = NeedTypeDisplay;
    NeedType = NeedType;
    statusComment = null;
    formData = {
        attributes: {},
        deliveryItemsListNumber: null,
        deliveryOrderLineItemStatus: null,
        jDocumentNumber: null,
        lineItemQuantity: null,
        lineItemType: null,
        need: null,
        needDate: null,
        openedDate: null,
        ownerContactId: null,
        projectedDate: null,
        shipperNumber: null,
        workBreakdownStructureNumber: null
    };
    changeSets;
    async onClickOfSave() {
        if (!this.changeSets) {
            return;
        }
        // this request body needs to list every property of the line item, even if
        // it's not being updated. It’s typed to be explicit (no undefined values)
        // because omitting a field here could lead to unintended data loss.
        const updates = this.changeSets.map((changeSetToUpdate) => {
            const body = {
                attributes: changeSetToUpdate.attributes,
                deliveryItemsListNumber: this.formData.deliveryItemsListNumber || changeSetToUpdate.deliveryItemsListNumber || null,
                deliveryOrderLineItemStatus: this.formData.deliveryOrderLineItemStatus ?? DeliveryOrderLineItemStatus.IN_PROCESS,
                jDocumentNumber: this.formData.jDocumentNumber || changeSetToUpdate.jDocumentNumber || null,
                lineItemQuantity: this.formData.lineItemQuantity || changeSetToUpdate.lineItemQuantity || null,
                lineItemType: this.formData.lineItemType || changeSetToUpdate.lineItemType || null,
                need: this.formData.need || changeSetToUpdate.need || null,
                needDate: this.formData.needDate || changeSetToUpdate.needDate || null,
                openedDate: this.formData.openedDate || changeSetToUpdate.openedDate || null,
                ownerContactId: this.formData.ownerContactId || changeSetToUpdate.ownerContactId || null,
                partReferenceId: changeSetToUpdate.partReferenceId ?? null,
                projectedDate: this.formData.projectedDate || changeSetToUpdate.projectedDate || null,
                shipperNumber: this.formData.shipperNumber || changeSetToUpdate.shipperNumber || null,
                workBreakdownStructureNumber: this.formData.workBreakdownStructureNumber || changeSetToUpdate.workBreakdownStructureNumber || null
            };
            return putDeliveryOrderLineItem(changeSetToUpdate.documentId, changeSetToUpdate.id, body);
        });
        try {
            // cast the request body because some fields don’t expect null
            const resp = await Promise.all(updates);
            if (this.statusComment) {
                const commentUpdates = this.changeSets.map((changeSetToUpdate) => {
                    const commentPayload = {
                        comment: this.statusComment.trim(),
                        subType: CommentType.STATUS
                    };
                    const commentParams = {
                        documentId: changeSetToUpdate.documentId,
                        changeSetId: changeSetToUpdate.id
                    };
                    return this.$http.post('/comments', commentPayload, {
                        params: commentParams
                    });
                });
                await Promise.all(commentUpdates);
            }
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Saved ${this.changeSets.length} items.`
            });
            this.closeDialog();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], ModalDeliveryOrderLineItemUpdateMultiple.prototype, "changeSets", void 0);
ModalDeliveryOrderLineItemUpdateMultiple = __decorate([
    Component
], ModalDeliveryOrderLineItemUpdateMultiple);
export default ModalDeliveryOrderLineItemUpdateMultiple;
