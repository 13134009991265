import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
let ModalEventAssign = class ModalEventAssign extends Mixins(BaseModal) {
    hardwareList = null;
    selectedEvent = null;
    hardwareListId;
    get hardwareListHasEvent() {
        return Boolean(this.hardwareList?.eventId);
    }
    async onClickOfSave() {
        if (!this.selectedEvent || !this.hardwareList) {
            return;
        }
        try {
            const body = {
                addendumDate: this.hardwareList.addendumDate,
                addendumNumber: this.hardwareList.addendumNumber,
                authorizer: this.hardwareList.authorizer,
                closeDate: this.hardwareList.closeDate,
                documentId: this.hardwareList.documentId,
                eventId: this.selectedEvent.id,
                hardwareListTemplateId: this.hardwareList.hardwareListTemplateId,
                isCritical: this.hardwareList.isCritical,
                name: this.hardwareList.name || '',
                number: this.hardwareList.number,
                openDate: this.hardwareList.openDate,
                revision: this.hardwareList.revision,
                subType: this.hardwareList.subType
            };
            const resp = await this.$http.put(`/hardware-lists/${this.hardwareListId}`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Saved.'
            });
            this.$message(`reload_${this.hardwareListId}`);
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSearchEventSelection(val) {
        this.selectedEvent = val;
    }
    async fetchHardwareListById() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-lists/${this.hardwareListId}`);
            this.hardwareList = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalEventAssign.prototype, "hardwareListId", void 0);
__decorate([
    DebounceAll(10),
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalEventAssign.prototype, "fetchHardwareListById", null);
ModalEventAssign = __decorate([
    Component
], ModalEventAssign);
export default ModalEventAssign;
