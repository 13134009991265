import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ActivityType, PressurizationRange } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
// TODO: move this into types repo
import { ActivityRepoMixin } from '~/nasa_ui/DAL/activity';
import { AlertType } from '~/nasa_ui/types';
import { ActivityTypeDisplay, PressurizationRangeDisplay } from '~/nasa_ui/types/enums/activity';
import { DEFAULT_DASH } from '../constants';
let BaseActivityInformationForm = class BaseActivityInformationForm extends Mixins(BaseTab, ActivityRepoMixin) {
    PressurizationRange = PressurizationRange;
    ActivityTypeDisplay = ActivityTypeDisplay;
    PressurizationRangeDisplay = PressurizationRangeDisplay;
    formData = null;
    ogFormData = null;
    formErrorCount = 0;
    get activityPayload() {
        if (!this.formData) {
            return {};
        }
        return {
            attributes: this.formData.attributes,
            calibrationType: this.formData.calibrationType,
            cycles: this.formData.cycles,
            isManned: this.formData.isManned,
            isPreventativeMaintenance: this.formData.isPreventativeMaintenance,
            itemInstanceId: this.formData.itemInstanceId,
            name: this.formData.name,
            operationTime: this.formData.operationTime,
            crewTime: this.formData.crewTime,
            pressurizedTime: this.formData.pressurizedTime,
            pressurizationRange: this.formData.pressurizationRange,
            startDateTime: this.formData.startDateTime,
            staticTime: this.formData.staticTime,
            subType: this.formData.subType
        };
    }
    get authorityData() {
        const comments = (this.formData?.comments?.nodes || []).map((_) => _?.comment ?? '') ?? null;
        return {
            authorityCode: this.formData?.authorityCode ?? null,
            authorityNumber: this.formData?.authorityNumber ?? null,
            comments
        };
    }
    get isFormDataValid() {
        return false;
    }
    get isActvityTypePressurization() {
        return this.formData?.subType === ActivityType.PRESSURIZATION;
    }
    get activityType() {
        return this.formData ? this.formData.subType : null;
    }
    get activityTypeDisplay() {
        return this.activityType ? ActivityTypeDisplay.get(this.activityType) : null;
    }
    get failureChangeSetId() {
        return this.formData?.changeSet?.id;
    }
    get nameDisplay() {
        return this.formData?.name || DEFAULT_DASH;
    }
    get shouldDisableInformationSaveButton() {
        return this.formErrorCount > 0;
    }
    get startDateDisplay() {
        return this.$dateDisplay(this.formData?.startDateTime);
    }
    onFormError(hasErrors) {
        this.formErrorCount = this.formErrorCount + (hasErrors ? 1 : -1);
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.getActivityById({
                id: this.id
            });
            this.isFetching = false;
            this.formData = { ...resp?.activityById };
            this.ogFormData = { ...resp?.activityById };
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch base information for Activity.'
            });
        }
    }
    async onClickOfSaveInformation() {
        await this.patchActivity(this.activityPayload);
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: 'Activity information updated.'
        });
        // Tell whomever cares to reload
        this.$message('reload', true);
        this.$message(`reload_${this.id}`);
    }
    onDeletionComplete() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => this.$router.push('/'), 3000);
    }
    async patchActivity(patch) {
        if (!this.id || !patch) {
            return;
        }
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation SaveActivityOnActivityInfoTab($id: UUID!, $patch: ActivityPatch!) {
            updateActivityById(input: { id: $id, patch: $patch }) {
              activity {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    id: this.id,
                    patch
                }
            });
            return resp.data?.updateActivityById;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to update information for Activity.'
            });
        }
    }
    fetchActivity(val) {
        if (val) {
            this.fetchEntity();
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseActivityInformationForm.prototype, "fetchActivity", null);
BaseActivityInformationForm = __decorate([
    Component
], BaseActivityInformationForm);
export default BaseActivityInformationForm;
