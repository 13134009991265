var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini",
      "uppercase": false
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('div', {
    class: {
      hideBorderBottom: _vm.hideBorderBottom
    }
  }, [_vm.isItemDrawing ? _c('AppItemDrawingDisplay', {
    attrs: {
      "drawing-number": _vm.computedDrawingNumber
    }
  }) : _vm._e(), _vm.isItemMaster ? _c('AppItemMasterDisplay', {
    attrs: {
      "as-built-number": _vm.computedAsBuiltNumber,
      "drawing-number": _vm.computedDrawingNumber,
      "side": _vm.computedSide
    }
  }) : _vm._e(), _vm.isItemInstance ? _c('AppItemInstanceDisplay', {
    attrs: {
      "id": _vm.computedId
    }
  }) : _vm._e(), _vm.isInventory ? _c('AppItemInstanceDisplay', {
    attrs: {
      "id": _vm.computedItemInstanceId,
      "inventory-id": _vm.computedId
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };