import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, QUANTITY_COL, SEQUENCE_COL, SERIAL_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformHardwareListPartResponses, transformHardwareListTemplateResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppHardwareListParts = class AppHardwareListParts extends Mixins(BaseCosmic, ReadOnlyMixin) {
    DEFAULT_DASH = DEFAULT_DASH;
    hardwareList = null;
    hardwareListTemplateAssigned = null;
    isFetching = false;
    isSaving = false;
    NOT_APPLICABLE = NOT_APPLICABLE;
    selectedHardwareListPartReference = null;
    showCreateHardwareListPartModal = false;
    showHardwareListPartEditModal = false;
    showHardwareListPartInfoModal = false;
    showTemplate = false;
    hardwareListId;
    overrideTableHeaders;
    shouldDisableAddToListButton;
    // All of these are defined within the table template itself
    hardwareListAssemblyTableHeaders = [
        SEQUENCE_COL,
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hw type',
            value: '_hardwareTypeIcon'
        }),
        composeTableHeader(QUANTITY_COL, {
            noWrap: true,
            width: '100px'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: '_partReference.drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            noWrap: true,
            value: '_partReference.asBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_partReference._description'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_partReference._side'
        }),
        // composeTableHeader(SIZE_COL, {
        //   value: '_computedSize'
        // }),
        composeTableHeader(SERIAL_COL, {
            value: '_partReference._serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_partReference._lotNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_partReference._itemClasses'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_partReference._contractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_partReference._location'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_partReference._birthDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration<br/>number',
            value: '_partReference._calibrationNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Calibration',
            value: '_partReference._lastCalibrationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage<br/>expiry',
            value: '_partReference._usageLifeExpirationDate'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Addendum<br/>number',
            value: '_addendumNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Addendum',
            value: '_addendumDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Notes',
            noWrap: true,
            value: '_notesNumberOnly',
            monospacedFont: true
        })
    ];
    /**
     * Use the override if provided otherwise use the standard columns
     */
    get computedTableHeaders() {
        return this.overrideTableHeaders || this.hardwareListAssemblyTableHeaders;
    }
    get hardwareListParts() {
        return this.hardwareList?.hardwareListParts || [];
    }
    get hardwareListHasTemplate() {
        return Boolean(this.hardwareList?.hardwareListTemplateId);
    }
    get tableItems() {
        if (this.showTemplate && this.hardwareListHasTemplate && this.transformedHardwareListTemplateAssigned) {
            return [...this.transformedHardwareListParts, ...(this.transformedHardwareListTemplateAssigned._slots || [])]
                .map((item) => {
                const _item = { ...item };
                _item.rowClass = _item._isTemplate ? 'is_template_row' : '';
                return _item;
            })
                .sort(sortObjectNumericallyBy('sequence'));
        }
        return this.transformedHardwareListParts.sort(sortObjectNumericallyBy('sequence'));
    }
    get transformedHardwareListParts() {
        return transformHardwareListPartResponses(this.hardwareListParts);
    }
    get transformedHardwareListTemplateAssigned() {
        return this.hardwareListTemplateAssigned
            ? transformHardwareListTemplateResponses([this.hardwareListTemplateAssigned])[0]
            : null;
    }
    created() {
        this.$listen(`reload_${this.hardwareListId}`, this.onHardwareListIdChange);
    }
    async deleteHardwareListAssembly() {
        if (!this.selectedHardwareListPartReference) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.delete(`/hardware-lists/${this.selectedHardwareListPartReference.hardwareListId}/parts/${this.selectedHardwareListPartReference.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Deleted.`
            });
            this.reset();
            // Refetch
            this.onHardwareListIdChange();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err
            });
        }
    }
    onClickOfHardwareListPartReferenceCreate() {
        this.showCreateHardwareListPartModal = true;
    }
    onClickOfHardwareListPartReferenceInfo(hla) {
        this.showHardwareListPartInfoModal = true;
        this.selectedHardwareListPartReference = hla;
    }
    onClickOfHardwareListPartReferenceToEdit(hla) {
        this.showHardwareListPartEditModal = true;
        this.selectedHardwareListPartReference = hla;
    }
    async onClickOfHardwareListPartToRemove(hla) {
        try {
            this.isSaving = true;
            // alsoDeletePartReferenceWhenPossible is defaulted to false
            const resp = await this.$http.delete(`/hardware-lists/${this.hardwareListId}/parts/${hla.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'List item deleted.'
            });
            this.$message(`reload_${this.hardwareListId}`);
            this.isSaving = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // onClickOfTemplateSlotToFill(listItem: IHardwareListTableItem) {
    //   this.showHardwareListItemFillModal = true;
    //   this.selectedTemplateSlot = listItem.templateItem ?? null;
    // }
    // onClickOfTemplateSlotToEdit(listItem: IHardwareListTableItem) {
    //   this.showEditHardwareListTemplateSlotModal = true;
    //   this.selectedTemplateSlot = listItem.templateItem ?? null;
    // }
    reset() {
        this.showCreateHardwareListPartModal = false;
        this.showHardwareListPartEditModal = false;
        this.showHardwareListPartInfoModal = false;
    }
    async onHardwareListIdChange() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-lists/${this.hardwareListId}`);
            this.hardwareList = resp.data;
            // If it has a template fetch that
            if (resp.data.hardwareListTemplateId) {
                const respHlt = await this.$http.get(`/hardware-list-templates/${resp.data.hardwareListTemplateId}`);
                this.hardwareListTemplateAssigned = respHlt.data
                    ? transformHardwareListTemplateResponses([respHlt.data])[0]
                    : null;
            }
            else {
                this.hardwareListTemplateAssigned = null;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.isFetching = false;
    }
    layerInTemplate() {
        if (!this.hardwareListHasTemplate) {
            return this.transformedHardwareListParts;
        }
        return [
        // ...this.transformedHardwareListAssemblies(
        //   this.hardwareListTemplateSlotsUnfilled.map((templateItem) => {
        //     return {
        //       sequence: templateItem!.sequence,
        //       isTemplate: true,
        //       templateItem,
        //       rowClass: 'is_template'
        //     };
        //   })
        // ),
        // ...this.transformedHardwareListAssemblies
        ]
            .map((hardwareItem) => {
            // if (hardwareItem.isTemplate) {
            //   return hardwareItem;
            // }
            // const matchingTemplateSlot = this.hardwareListTemplateSlots.find(
            //   (templateSlot) => templateSlot?.sequence === hardwareItem.sequence
            // );
            // if (matchingTemplateSlot) {
            //   hardwareItem.hasMatchingTemplate = true;
            //   hardwareItem.rowClass = 'template_match';
            // }
            // return hardwareItem;
        })
            .sort(sortObjectNumericallyBy('sequence'));
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppHardwareListParts.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], AppHardwareListParts.prototype, "overrideTableHeaders", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareListParts.prototype, "shouldDisableAddToListButton", void 0);
__decorate([
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppHardwareListParts.prototype, "onHardwareListIdChange", null);
AppHardwareListParts = __decorate([
    Component
], AppHardwareListParts);
export default AppHardwareListParts;
