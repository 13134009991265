var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_c('p', [_vm._v(" A Hardware List is a general collection of Item instances. Each item in the Hardware List can represent a single piece of hardware. Here, you are creating a \"bucket\" to place your hardware into. ")]), _c('p', [_vm._v("Hardware Lists must be unique by "), _c('strong', [_vm._v("Number")]), _vm._v(" and "), _c('strong', [_vm._v("Type")]), _vm._v(".")])])], 1)]), _vm.eventId ? _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('MiniEvent', {
          staticClass: "my-3",
          attrs: {
            "id": _vm.eventId,
            "title": "Assigning to Event"
          }
        })], 1)]) : _vm._e(), _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppSelect', {
          attrs: {
            "chips": false,
            "select-options": _vm.filteredHardwareListTypes,
            "autocomplete": "",
            "autofocus": "",
            "label": "List type",
            "required": ""
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1), _c('div', {
          staticClass: "col-two-thirds"
        }, [_c('AppText', {
          attrs: {
            "label": "List name"
          },
          model: {
            value: _vm.formData.name,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.formData.number && _vm.formData.subType && _vm.hardwareListExists ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" Hardware list found matching "), _c('span', {
          staticClass: "monospace_font"
        }, [_c('strong', [_vm._v(_vm._s(_vm.formData.number))])]), _vm._v(" with the type "), _c('strong', [_vm._v(_vm._s(_vm.HardwareListTypeDisplay.get(_vm.formData.subType)))])])], 1) : _vm._e()]), _c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "prefix": _vm.numberPrefix,
            "label": "List number",
            "required": ""
          },
          model: {
            value: _vm.formData.number,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "number", $$v);
            },
            expression: "formData.number"
          }
        })], 1), _c('div', {
          staticClass: "col-fourth"
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Enforce naming standard"
          },
          model: {
            value: _vm.enforceNamingStandard,
            callback: function ($$v) {
              _vm.enforceNamingStandard = $$v;
            },
            expression: "enforceNamingStandard"
          }
        })], 1)], 1), _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppText', {
          attrs: {
            "label": "Authorizer"
          },
          model: {
            value: _vm.formData.authorizer,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "authorizer", $$v);
            },
            expression: "formData.authorizer"
          }
        })], 1)]), _vm.formData.hardwareListTemplateId ? _c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-full"
        }, [_c('MiniHardwareListTemplate', {
          attrs: {
            "title": "Creating Hardware List from this Template",
            "id": _vm.formData.hardwareListTemplateId,
            "inlineClear": ""
          },
          on: {
            "inlineClear": function ($event) {
              _vm.selectedListTemplate = null;
            }
          }
        })], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Hardware list ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };