import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ItemInstanceInventoryType } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let ModalInventoryQuota = class ModalInventoryQuota extends Vue {
    itemMasterNodeId;
    shouldShowModalInventoryQuota;
    modalFormData; // type `object` will cause an error below when trying to access object props
    EntityType = EntityType;
    ItemInstanceInventoryType = ItemInstanceInventoryType;
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    formDataCopy = {
        id: null,
        itemClass: null,
        _minStockNumber: 0,
        _maxStockNumber: null
    };
    existingInventoryQueryResults = [];
    queryResults = [];
    shouldShowForm = false;
    selectedInventoryQuota = null;
    get createQuotaInput() {
        return {
            inventoryQuota: {
                itemClass: this.formDataCopy.itemClass,
                itemMaster: this.itemMasterNodeId,
                minStock: this.formDataCopy._minStockNumber,
                maxStock: this.formDataCopy._maxStockNumber
            }
        };
    }
    get editItemClassFixedOption() {
        const itemClass = this.formDataCopy?.itemClass;
        return [
            {
                displayText: itemClass ? ItemInstanceInventoryTypeDisplay.get(itemClass) : DEFAULT_DASH,
                value: this.formDataCopy?.itemClass
            }
        ];
    }
    get existingInventoryQuotaItemClasses() {
        return this.existingInventoryQueryResults.reduce((itemClasses, quota) => {
            const itemClass = quota?.itemClass;
            if (itemClass && !itemClasses.includes(itemClass)) {
                itemClasses.push(itemClass);
            }
            return itemClasses;
        }, []);
    }
    get filteredItemClasses() {
        return Object.values(this.ItemInstanceInventoryType)
            .filter((value) => !this.existingInventoryQuotaItemClasses.includes(value) &&
            value !== ItemInstanceInventoryType.MULTIPLE &&
            value !== ItemInstanceInventoryType.UNKNOWN)
            .map((value) => {
            return {
                displayText: ItemInstanceInventoryTypeDisplay.get(value),
                value
            };
        });
    }
    get isEditing() {
        return Boolean(this.modalFormData?.id);
    }
    get isFormValid() {
        const _maxStockNumber = this.formDataCopy._maxStockNumber;
        return Boolean(this.formDataCopy.itemClass &&
            _maxStockNumber !== null &&
            this.formDataCopy._minStockNumber !== null &&
            this.formDataCopy._minStockNumber <= _maxStockNumber);
    }
    get maxStockErrorText() {
        const maxStock = this.formDataCopy?._maxStockNumber;
        return maxStock !== null && this.formDataCopy?._minStockNumber > maxStock
            ? ['Max stock cannot be less than min stock.']
            : null;
    }
    get minStockErrorText() {
        const maxStock = this.formDataCopy?._maxStockNumber;
        return maxStock !== null && this.formDataCopy?._minStockNumber > maxStock
            ? ['Min stock cannot be greater than max stock.']
            : null;
    }
    get modalTitle() {
        return this.isEditing ? 'Edit Inventory Quota' : 'Create Inventory Quota';
    }
    get updateQuotaInput() {
        return {
            id: this.formDataCopy.id,
            patch: {
                itemClass: this.formDataCopy.itemClass,
                minStock: this.formDataCopy._minStockNumber,
                maxStock: this.formDataCopy._maxStockNumber
            }
        };
    }
    resetFormData() {
        this.formDataCopy.itemClass = null;
        this.formDataCopy._minStockNumber = 0;
        this.formDataCopy._maxStockNumber = null;
    }
    async createQuota() {
        try {
            await this.$apollo.mutate({
                mutation: gql `
          mutation CreateInventoryQuota($input: CreateInventoryQuotaInput!) {
            createInventoryQuota(input: $input) {
              inventoryQuota {
                nodeId
                minStock
                maxStock
                itemClass
              }
            }
          }
        `,
                variables: {
                    input: this.createQuotaInput
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Inventory quota created.'
            });
            this.resetFormData();
            this.$message('reload_inventory_quota_alerts', true);
            this.hideModal();
            this.$emit('submit-quota-change');
        }
        catch (error) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'An error occurred when creating inventory quota.'
            });
            this.$errorUtility({ err: error });
        }
    }
    async deleteQuota() {
        try {
            await this.$apollo.mutate({
                mutation: gql `
          mutation DeleteInventoryQuotaById($input: DeleteInventoryQuotaByIdInput!) {
            deleteInventoryQuotaById(input: $input) {
              deletedInventoryQuotaId
            }
          }
        `,
                variables: {
                    input: {
                        id: this.formDataCopy.id
                    }
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Inventory quota deleted.'
            });
            this.$message('reload_inventory_quota_alerts', true);
            this.hideModal();
            this.$emit('submit-quota-change');
        }
        catch (error) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'An error occurred when trying to delete this inventory quota.'
            });
            this.$errorUtility({ err: error });
        }
    }
    async fetchInventoryQuotas() {
        try {
            const condition = {
                itemMaster: [this.itemMasterNodeId]
            };
            const resp = await this.$apollo.query({
                query: gql `
          fragment InventoryQuotaOnModal on InventoryQuota {
            id
            itemClass
            nodeId
            minStock
            maxStock
            itemMaster {
              nodeId
              subType
            }
          }

          query GetInventoryQuotasForModal($condition: InventoryQuotaCondition!) {
            inventoryQuotas(condition: $condition) {
              nodes {
                ...InventoryQuotaOnModal
              }
            }
          }
        `,
                fetchPolicy: 'network-only',
                variables: {
                    condition
                }
            });
            this.existingInventoryQueryResults = resp.data.inventoryQuotas?.nodes || [];
        }
        catch (error) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'An error occurred when fetching inventory quotas.'
            });
            this.$errorUtility({ err: error });
        }
    }
    async updateQuota() {
        try {
            await this.$apollo.mutate({
                mutation: gql `
          mutation UpdateInventoryQuotaById($input: UpdateInventoryQuotaByIdInput!) {
            updateInventoryQuotaById(input: $input) {
              inventoryQuota {
                nodeId
                minStock
                maxStock
                itemClass
              }
            }
          }
        `,
                variables: {
                    input: this.updateQuotaInput
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Inventory quota updated.'
            });
            this.resetFormData();
            this.$message('reload_inventory_quota_alerts', true);
            this.hideModal();
            this.$emit('submit-quota-change');
        }
        catch (error) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'An error occurred when updating inventory quota.'
            });
            this.$errorUtility({ err: error });
        }
    }
    hideModal() {
        this.$emit('update:shouldShowModalInventoryQuota', false);
    }
    onClickOfCancel() {
        this.hideModal();
    }
    onClickOfRemove() {
        this.deleteQuota();
    }
    onClickOfSave() {
        this.isEditing ? this.updateQuota() : this.createQuota();
    }
    onShouldShowModalInventoryQuotaChanged(newValue, oldValue) {
        this.formDataCopy = Object.assign({}, {
            id: null,
            itemClass: null,
            _minStockNumber: 0,
            _maxStockNumber: null
        }, JSON.parse(JSON.stringify(this.modalFormData)));
        if (this.shouldShowModalInventoryQuota) {
            this.fetchInventoryQuotas();
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalInventoryQuota.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalInventoryQuota.prototype, "shouldShowModalInventoryQuota", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], ModalInventoryQuota.prototype, "modalFormData", void 0);
__decorate([
    Watch('shouldShowModalInventoryQuota', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], ModalInventoryQuota.prototype, "onShouldShowModalInventoryQuotaChanged", null);
ModalInventoryQuota = __decorate([
    Component
], ModalInventoryQuota);
export default ModalInventoryQuota;
