var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "modal-width": "99vw",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.hasParent ? _c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_vm._v("No parent found. Please close this and try again.")]) : _vm._e(), _vm.hasParent ? _c('div', [_vm.isParentHardwareListTemplate ? _c('MiniHardwareListTemplate', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.parentId,
            "show-manage-link": false,
            "show-slots-initially": false,
            "title": "Adding to list..."
          }
        }) : _vm._e(), _vm.isParentHardwareListTemplate ? _c('hr', {
          staticClass: "my-3"
        }) : _vm._e(), _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppText', {
          attrs: {
            "label": "Template item name",
            "clearable": ""
          },
          model: {
            value: _vm.formData.name,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid my-2"
        }, [_c('div', {
          staticClass: "col-sixth"
        }, [_c('AppNumber', {
          attrs: {
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "autofocus": "",
            "label": "Sequence",
            "required": ""
          },
          model: {
            value: _vm.formData.sequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Min qty",
            "max": _vm.formData.maxQuantity || 1,
            "pre-computed-rules": [_vm.minLessThanOrEqualToMax]
          },
          model: {
            value: _vm.formData.minQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "minQuantity", $$v);
            },
            expression: "formData.minQuantity"
          }
        })], 1), _c('div', {
          staticClass: "col-sixth"
        }, [_c('AppNumber', {
          attrs: {
            "label": "Max qty",
            "max": _vm.formData.maxQuantity || 0,
            "pre-computed-rules": [_vm.minLessThanOrEqualToMax],
            "required": ""
          },
          model: {
            value: _vm.formData.maxQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "maxQuantity", $$v);
            },
            expression: "formData.maxQuantity"
          }
        })], 1)]), _c('div', {
          staticClass: "c-grid"
        }, [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.hardwareItemTemplatePartTableItems.length ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info",
            "remove-margin-bottom": ""
          }
        }, [_vm._v(" Search below to add to your \"shopping list\" of selectable hardware for this templated slot ")])], 1) : _vm._e()]), _c('div', {
          staticClass: "col-full mb-2"
        }, [_vm.hardwareItemTemplatePartTableItems.length ? _c('AppTableActions', {
          attrs: {
            "headers": _vm.hardwareItemTemplatePartTableHeaders,
            "hide-pagination": _vm.hardwareItemTemplatePartTableItems.length < 5,
            "items": _vm.hardwareItemTemplatePartTableItems,
            "rows-per-page-items": _vm.rowsPerPageItems,
            "color": _vm.EntityType.ITEM_MASTER,
            "title": "Hardware shopping list",
            "show-count": "",
            "hide-icon": "",
            "dense-rows": "",
            "disable-initial-sort": ""
          },
          scopedSlots: _vm._u([{
            key: "_entityType",
            fn: function ({
              item
            }) {
              return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('c-icon-fa', {
                attrs: {
                  "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
                  "icon-color": "var(--v-item_instances-base)"
                }
              }) : _vm._e(), item._entityType === _vm.EntityType.ITEM_MASTER ? _c('c-icon-fa', {
                attrs: {
                  "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
                  "icon-color": "var(--v-item_instances-base)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "_asBuiltNumber",
            fn: function ({
              item
            }) {
              return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('span', {
                staticClass: "not_applicable monospace_font",
                attrs: {
                  "tooltip": "This is not applicable."
                }
              }, [_vm._v("N/A")]) : _c('span', [_vm._v(_vm._s(item._asBuiltNumber))])];
            }
          }, {
            key: "_side",
            fn: function ({
              item
            }) {
              return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('span', {
                staticClass: "not_applicable monospace_font",
                attrs: {
                  "tooltip": "This is not applicable."
                }
              }, [_vm._v("N/A")]) : _c('span', {
                staticClass: "monospace_font"
              }, [_vm._v(_vm._s(item._side))])];
            }
          }, {
            key: "actions",
            fn: function ({
              item
            }) {
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function ($event) {
                    return _vm.onClickOfHardwareItemTemplatePartTableItem(item);
                  }
                }
              })];
            }
          }], null, false, 1905721964)
        }) : _vm._e()], 1), _c('div', {
          staticClass: "col-full"
        }, [_c('SearchItemDrawingItemMaster', {
          attrs: {
            "existing-values": _vm.existingItemDrawingNodeIds,
            "rows-per-page-items": _vm.rowsPerPageItems,
            "keep-search-results-open": "",
            "keep-search-value-after-selection": "",
            "output-mode": "object"
          },
          on: {
            "input": _vm.onSearchItemDrawingChange
          }
        })], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v("Add Item to List")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };