var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { EntityType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
import { AlertType, UserPermission } from '~/nasa_ui/types';
import { getItemInstance } from '~/nasa_ui/utils';
import { transformLinks } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppLinks = class AppLinks extends Mixins(BaseCosmic, CurrentUserMixin, ReadOnlyMixin, WagonWheelPropsMixin) {
    linksDocument = [];
    linksEvent = [];
    linksItemDrawing = [];
    linksItemInstance = [];
    linksItemMaster = [];
    linksOrganization = [];
    linksRisk = [];
    linksColor = 'grey darken-1';
    selectedLink = null;
    shouldShowForm = false;
    showAreYouSure = false;
    get allLinks() {
        return [
            ...this.linksDocument,
            ...this.linksEvent,
            ...this.linksItemDrawing,
            ...this.linksItemInstance,
            ...this.linksItemMaster,
            ...this.linksOrganization,
            ...this.linksRisk
        ];
    }
    get computedTableItems() {
        return transformLinks(this.allLinks);
    }
    get isUserOwnerOfLinksLink() {
        return this.selectedLink?.createdByUserId === this.currentUser?.id;
    }
    get selectedLinkId() {
        return this.selectedLink?.id;
    }
    get tableHeaders() {
        return [
            SUB_TYPE_COL,
            {
                text: `System<br/>type`,
                value: '_entityTypeIcon',
                noEscape: true,
                width: '15%'
            },
            {
                text: 'Current revision',
                value: '_currentRevision'
            },
            {
                text: 'Current version URL',
                value: '_currentVersionUrl',
                noEscape: true
            },
            {
                align: 'right',
                monospacedFont: true,
                text: 'Reference number',
                value: '_referenceNumber'
            },
            {
                text: 'Working version URL',
                value: '_workingVersionUrl',
                noEscape: true
            }
        ];
    }
    get userCanRemoveLink() {
        // If you created it, you can delete it
        if (this.isUserOwnerOfLinksLink) {
            return true;
        }
        // if for some reason we cant get the currentUser perms
        if (!this.currentUserPermissions) {
            return false;
        }
        // Any doc admin will work for now
        if (this.documentId) {
            return Object.entries(this.currentUserPermissions)
                .filter((perm) => perm[0].startsWith('DOCUMENT_'))
                .some((perm) => perm[1] === true);
        }
        // Event admin
        if (this.cosmicEventId) {
            return this.currentUserHasPermission(UserPermission.EVENT_ADMIN);
        }
        // Risk open for now i guess
        if (this.riskId) {
            return true;
        }
        // Any hardware perm admin will work for now
        if (this.drawingNumber || this.asBuiltNumber || this.side || this.itemInstanceId) {
            return (this.currentUserHasPermission(UserPermission.ITEM_DRAWING_ADMIN) ||
                this.currentUserHasPermission(UserPermission.ITEM_MASTER_ADMIN) ||
                this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
        }
        return false;
    }
    // called from ui
    onClickOfTableRow(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedLink = row;
        this.shouldShowForm = true;
    }
    onModalClose() {
        this.shouldShowForm = false;
        this.showAreYouSure = false;
        this.selectedLink = null;
    }
    // called from ui
    onClickOfRemoveLinksButton(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedLink = row;
        this.showAreYouSure = true;
    }
    // called from ui
    async onAffirmativeClicked() {
        this.showAreYouSure = false;
        try {
            if (!this.selectedLinkId) {
                return;
            }
            const resp = await this.$http.delete(`/links/${this.selectedLinkId}`);
            if (resp) {
                this.reloadLinks();
                this.$notification.add({
                    text: 'Deleted.',
                    type: AlertType.SUCCESS
                });
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    // called from ui
    onNegativeClicked() {
        this.showAreYouSure = false;
    }
    reloadLinks() {
        this.fetchLinksForDocument(this.documentId);
        this.fetchLinksForEvent(this.cosmicEventId);
        this.fetchLinksForItemDrawing(this.drawingNumber);
        this.fetchLinksForItemInstance(this.itemInstanceId);
        this.fetchLinksForItemMaster(this.drawingNumber, this.asBuiltNumber, this.side);
        this.fetchLinksForOrganization(this.organizationCode);
        this.fetchLinksForRisk(this.riskId);
    }
    emitUpdated() { }
    async fetchLinksForDocument(documentId) {
        if (!documentId) {
            return;
        }
        try {
            const params = {
                documentId,
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksDocument = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchLinksForEvent(cosmicEventId) {
        if (!cosmicEventId) {
            return;
        }
        try {
            const params = {
                cosmicEventId,
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksEvent = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchLinksForItemDrawing(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        try {
            const params = {
                drawingNumber,
                entityType: [EntityType.ITEM_DRAWING],
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksItemDrawing = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    // purposely leaving the watcher off of this method because there is a
    // composite watcher below
    async fetchLinksForItemMaster(drawingNumber, asBuiltNumber, side) {
        if (!drawingNumber || !asBuiltNumber || !side) {
            return;
        }
        try {
            await this.fetchLinksForItemDrawing(drawingNumber);
            const params = {
                drawingNumber,
                asBuiltNumber,
                side,
                entityType: [EntityType.ITEM_DRAWING, EntityType.ITEM_MASTER],
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksItemMaster = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchLinksForItemInstance(itemInstanceId) {
        if (!itemInstanceId) {
            return;
        }
        try {
            // first get the item instance so we can get the primary keys
            const respII = await getItemInstance(itemInstanceId);
            if (!respII.data) {
                new Error('Failed to fetch item instance.');
                return;
            }
            const { drawingNumber, asBuiltNumber, side } = respII.data;
            await this.fetchLinksForItemMaster(drawingNumber, asBuiltNumber, side);
            const params = {
                itemInstanceId,
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksItemInstance = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchLinksForOrganization(organizationCode) {
        if (!organizationCode) {
            return;
        }
        try {
            const params = {
                organizationCode: organizationCode,
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksOrganization = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchLinksForRisk(riskId) {
        if (!riskId) {
            return;
        }
        try {
            const params = {
                riskId,
                take: -1
            };
            const resp = await this.$http.get(`/links`, { params });
            if (!resp.data.results) {
                new Error('Failed to fetch links.');
                return;
            }
            this.linksRisk = resp.data.results;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onChangeOfItemMasterPrimaryKeys() {
        if (!this.drawingNumber || !this.asBuiltNumber || !this.side) {
            return;
        }
        this.fetchLinksForItemMaster(this.drawingNumber, this.asBuiltNumber, this.side);
    }
};
__decorate([
    Emit('updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppLinks.prototype, "emitUpdated", null);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForDocument", null);
__decorate([
    Watch('cosmicEventId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForEvent", null);
__decorate([
    Watch('drawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForItemDrawing", null);
__decorate([
    Watch('itemInstanceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForItemInstance", null);
__decorate([
    Watch('organizationCode', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForOrganization", null);
__decorate([
    Watch('riskId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", Promise)
], AppLinks.prototype, "fetchLinksForRisk", null);
__decorate([
    DebounceAll(10),
    Watch('drawingNumber', { immediate: true }),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppLinks.prototype, "onChangeOfItemMasterPrimaryKeys", null);
AppLinks = __decorate([
    Component
], AppLinks);
export default AppLinks;
