var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { CommentsRepoMixin, CosmicCommentFragment } from '~/nasa_ui/DAL/comment';
import { ErrorUtility } from '~/nasa_ui/utils/ErrorUtility';
/**
 * This control displays the latest comment of the specified type related to a
 * specified entity. Ex: latest CLOSURE comment on a document. It optionally
 * provides the ability to create a new comment of said type and entity. It
 * currently supports:
 *
 * - ChangeSets
 * - Documents
 * - YOUR ENTITY HERE
 */
let AppLatestComment = class AppLatestComment extends Mixins(BaseCosmic, CommentsRepoMixin) {
    changeSetId;
    commentType;
    displayType;
    documentId;
    inlineCreate;
    readOnly;
    title;
    latestComment = null;
    textOnForm = null;
    isFetching = false;
    isSaving = false;
    showModal = false;
    get computedTitle() {
        return this.title || 'comment';
    }
    get gqlCommentMutationVariables() {
        // comment required
        if (!this.textOnForm) {
            return null;
        }
        // uuid of some sort required
        if (!this.documentId && !this.changeSetId) {
            return null;
        }
        const commentInput = {
            comment: {
                comment: this.textOnForm,
                subType: this.commentType
            }
        };
        // change set?
        if (this.changeSetId) {
            commentInput.comment.changeSetId = this.changeSetId;
        }
        // document
        if (this.documentId) {
            commentInput.comment.documentId = this.documentId;
        }
        return commentInput;
    }
    get shouldDisableCreateButton() {
        return !this.textOnForm;
    }
    async onClickOfCreate() {
        if (!this.gqlCommentMutationVariables) {
            return;
        }
        try {
            this.isSaving = true;
            await this.createComment(this.gqlCommentMutationVariables, {
                success: `${this.computedTitle} updated.`,
                error: 'Failed to update.'
            });
            this.latestComment = this.textOnForm;
            this.textOnForm = null;
            this.isSaving = false;
            this.onModalClose();
        }
        catch (err) {
            ErrorUtility({ err });
            this.isSaving = false;
        }
    }
    onClickOfUpdate() {
        this.textOnForm = this.latestComment || '';
        this.showModal = true;
    }
    onModalClose() {
        this.showModal = false;
    }
    async fetchLatestChangeSetComment() {
        if (!this.changeSetId || !this.commentType) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicCommentFragment}

          query AppLatestChangeSetComment($changeSetId: Int!, $commentType: String!) {
            changeSetById(id: $changeSetId) {
              nodeId
              id

              computedLastComment(commentType: $commentType) {
                ...CosmicComment
              }
            }
          }
        `,
                variables: {
                    commentType: this.commentType,
                    changeSetId: this.changeSetId
                },
                fetchPolicy: 'network-only'
            });
            this.latestComment = resp.data?.changeSetById?.computedLastComment?.comment || null;
            this.isFetching = false;
        }
        catch (err) {
            ErrorUtility({ err });
            this.isFetching = false;
        }
    }
    async fetchLatestDocumentComment() {
        if (!this.documentId || !this.commentType) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicCommentFragment}

          query AppLatestDocumentComment($documentId: UUID!, $commentType: String!) {
            documentById(id: $documentId) {
              nodeId
              id

              computedLastComment(commentType: $commentType) {
                ...CosmicComment
              }
            }
          }
        `,
                variables: {
                    commentType: this.commentType,
                    documentId: this.documentId
                },
                fetchPolicy: 'network-only'
            });
            this.latestComment = resp.data?.documentById?.computedLastComment?.comment || null;
            this.isFetching = false;
        }
        catch (err) {
            ErrorUtility({ err });
            this.isFetching = false;
        }
    }
};
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Number)
], AppLatestComment.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: String,
        default: CommentType.GENERAL,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof CommentType !== "undefined" && CommentType) === "function" ? _a : Object)
], AppLatestComment.prototype, "commentType", void 0);
__decorate([
    Prop({
        type: String,
        default: AppDynamicWrapperType.EXPANSION_PANEL
    }),
    __metadata("design:type", typeof (_b = typeof AppDynamicWrapperType !== "undefined" && AppDynamicWrapperType) === "function" ? _b : Object)
], AppLatestComment.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", Object)
], AppLatestComment.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppLatestComment.prototype, "inlineCreate", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppLatestComment.prototype, "readOnly", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", typeof (_c = typeof String !== "undefined" && String) === "function" ? _c : Object)
], AppLatestComment.prototype, "title", void 0);
__decorate([
    Watch('changeSetId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppLatestComment.prototype, "fetchLatestChangeSetComment", null);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppLatestComment.prototype, "fetchLatestDocumentComment", null);
AppLatestComment = __decorate([
    Component
], AppLatestComment);
export default AppLatestComment;
