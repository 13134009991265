import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicChangeSetStatusFragment } from '../changeSet';
import { CosmicItemInstanceSlimFragment } from '../itemInstance/slim';
export const DocumentWorkOrderStepHardwareListFragment = gql `
  ${CosmicItemInstanceSlimFragment}

  fragment DocumentWorkOrderStepHardwareList on CombinedHardwareListAssemblyNode {
    listedOnWorkOrderSteps
    hardwareListAssemblies {
      addendumDate
      addendumNumber
      externalAsBuiltNumber
      externalBirthDate
      externalCalibrationDate
      externalCalibrationNumber
      externalClass
      externalDescription
      externalDrawingNumber
      externalIsTool
      externalLotNumber
      externalSerialNumber
      externalShelfLifeDate
      externalUsageLifeDate
      id
      isExternal
      notes

      hardwareList {
        id
        name
      }

      itemInstance {
        ...CosmicItemInstanceSlim
      }
    }
  }
`;
export const DocumentWithLineItemsFragment = gql `
  ${DocumentWorkOrderStepHardwareListFragment}
  ${CosmicChangeSetStatusFragment}
  ${CosmicItemInstanceSlimFragment}

  fragment DocumentWithLineItems on Document {
    id
    nodeId
    subType
    overrideCloseDate

    # not sure why you need change sets?
    changeSets {
      nodes {
        authorityCode
        authorityNumber
        computedClosedDate
        computedStatus
        id
        nodeId
        openedDate
        projectedDate
        subType

        changeSetInventoryTransaction {
          nodeId
        }

        changeSetAuthorities {
          nodes {
            nodeId
          }
        }

        itemInstance {
          ...CosmicItemInstanceSlim
        }
        status {
          ...CosmicChangeSetStatus
        }
      }
    }

    cosmicDocumentDr {
      id
      nodeId
      computedStatus
      combinedHardwareList {
        nodes {
          ...DocumentWorkOrderStepHardwareList
        }
      }
      combinedToolsList {
        nodes {
          ...DocumentWorkOrderStepHardwareList
        }
      }
    }

    cosmicDocumentTps {
      id
      nodeId
      computedStatus
      combinedHardwareList {
        nodes {
          ...DocumentWorkOrderStepHardwareList
        }
      }
      combinedToolsList {
        nodes {
          ...DocumentWorkOrderStepHardwareList
        }
      }
    }
  }
`;
export const CreateDocumentLineItem = gql `
  mutation CreateDocumentLineItem(
    $documentId: UUID!
    $lineItems: [DocumentLineItemInputRecordInput]!
    $openedDate: Date!
    $projectedDate: Date
  ) {
    createDocumentLineItems(
      input: {
        documentLineItems: {
          documentId: $documentId
          lineItems: $lineItems
          openedDate: $openedDate
          projectedDate: $projectedDate
        }
      }
    ) {
      clientMutationId
    }
  }
`;
export const GetDocumentByNodeIdWithLineItems = gql `
  ${DocumentWithLineItemsFragment}

  query GetDocumentByNodeIdWithLineItems($nodeId: ID!) {
    document(nodeId: $nodeId) {
      ...DocumentWithLineItems
    }
  }
`;
export const GetDocumentByIdWithLineItems = gql `
  ${DocumentWithLineItemsFragment}

  query GetDocumentByIdWithLineItems($uuid: UUID!) {
    documentById(id: $uuid) {
      ...DocumentWithLineItems
    }
  }
`;
export const UpdateChangeSetAuthorityOnDocumentLineItem = gql `
  mutation UpdateChangeSetAuthorityOnDocumentLineItem($input: UpdateChangeSetAuthorityInput!) {
    updateChangeSetAuthority(input: $input) {
      changeSetAuthority {
        nodeId
      }
    }
  }
`;
export const CloseAllDocumentLineItems = gql `
  mutation CloseAllDocumentLineItems($input: CloseDocumentLineItemsInput!) {
    closeDocumentLineItems(input: $input) {
      changeSets {
        id
        nodeId
      }
    }
  }
`;
export const ReopenAllChangeSetsForDocuments = gql `
  mutation ReopenAllChangeSetsForDocuments($documentIds: [UUID!]) {
    reopenChangeSets(input: { documentIds: $documentIds, reopenApproved: true }) {
      changeSets {
        id
        nodeId
      }
    }
  }
`;
export const ReopenDocumentChangeSet = gql `
  mutation ReopenDocumentChangeSet($changeSetIds: [Int!], $authoritiesOverride: [ChangeSetAuthorityOverrideInput]) {
    reopenChangeSets(
      input: { changeSetIds: $changeSetIds, authoritiesOverride: $authoritiesOverride, reopenApproved: true }
    ) {
      changeSets {
        nodeId
        id
      }
    }
  }
`;
export const ReopenDocumentForEdits = gql `
  mutation ReopenDocumentForEdits($uuid: UUID!) {
    reopenCosmicDocumentForEdits(input: { documentId: $uuid }) {
      clientMutationId
    }
  }
`;
let DocumentLineItemsRepoMixin = class DocumentLineItemsRepoMixin extends Mixins(LoaderMixin) {
    getDocumentByNodeIdWithLineItems = queryFactory(this, GetDocumentByNodeIdWithLineItems);
    getDocumentByIdWithLineItems = queryFactory(this, GetDocumentByIdWithLineItems);
    createDocumentLineItem = mutationFactory(this, CreateDocumentLineItem);
    updateChangeSetAuthorityOnDocumentLineItem = mutationFactory(this, UpdateChangeSetAuthorityOnDocumentLineItem);
    closeAllDocumentLineItems = mutationFactory(this, CloseAllDocumentLineItems);
    reopenAllChangeSetsForDocuments = mutationFactory(this, ReopenAllChangeSetsForDocuments);
    reopenChangeSetsForDocuments = mutationFactory(this, ReopenDocumentChangeSet);
    reopenDocumentForEdits = mutationFactory(this, ReopenDocumentForEdits);
};
DocumentLineItemsRepoMixin = __decorate([
    Component
], DocumentLineItemsRepoMixin);
export { DocumentLineItemsRepoMixin };
