import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { UserSupportContextToValidLinkTypes } from '~/application.config';
import { LinkType } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
import { LinksTypeDisplay } from '~/nasa_ui/types';
let ModalLinkCreate = class ModalLinkCreate extends Mixins(BaseModalWithUser, WagonWheelPropsMixin) {
    formData = {
        currentRevision: null,
        currentVersionUrl: null,
        referenceNumber: null,
        subType: LinkType.OTHER,
        workingVersionUrl: null
    };
    get computedSelectOptions() {
        const linkTypes = UserSupportContextToValidLinkTypes.get(this.currentUserActiveSupportContext);
        return linkTypes
            ? linkTypes.map((linkType) => {
                return {
                    displayText: LinksTypeDisplay.get(linkType),
                    value: linkType
                };
            })
            : [];
    }
    // subType and at least one other field is required
    get isFormValid() {
        return Boolean(this.formData.subType &&
            (this.formData.currentRevision ||
                this.formData.currentVersionUrl ||
                this.formData.referenceNumber ||
                this.formData.workingVersionUrl));
    }
    async onClickOfCreate() {
        try {
            const params = this.getRelationshipsObj();
            if (!this.isFormValid || !params) {
                return;
            }
            const body = {
                currentRevision: this.formData.currentRevision,
                currentVersionUrl: this.formData.currentVersionUrl,
                referenceNumber: this.formData.referenceNumber,
                subType: this.formData.subType,
                workingVersionUrl: this.formData.workingVersionUrl
            };
            const resp = await this.$http.post('/links', body, { params });
            if (!resp.data) {
                new Error('Failed to create link.');
                return;
            }
            this.$notification.add({
                text: 'Created link.',
                type: 'success'
            });
            this.emitModalClose();
            this.emitCreated();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitCreated() { }
};
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkCreate.prototype, "emitCreated", null);
ModalLinkCreate = __decorate([
    Component
], ModalLinkCreate);
export default ModalLinkCreate;
