var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.isReadOnly ? _c('ButtonCreate', {
    staticClass: "mb-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.shouldShowForm = true;
      }
    }
  }, [_vm._v("Create link")]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.linksColor,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.link,
      "items": _vm.computedTableItems,
      "hide-pagination": "",
      "title": "Links"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfTableRow(item);
            }
          }
        }), _vm.userCanRemoveLink ? _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfRemoveLinksButton(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 3752463421)
  })], 1) : _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.linksColor,
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.link,
      "items": _vm.computedTableItems,
      "hide-pagination": "",
      "title": "Links"
    }
  })], 1)], 1), _vm.shouldShowForm && _vm.selectedLinkId ? _c('ModalLinkEdit', {
    attrs: {
      "id": _vm.selectedLinkId
    },
    on: {
      "modal-close": _vm.onModalClose,
      "updated": _vm.reloadLinks
    }
  }) : _vm._e(), _vm.shouldShowForm && !_vm.selectedLinkId ? _c('ModalLinkCreate', {
    attrs: {
      "as-built-number": _vm.asBuiltNumber,
      "cosmic-event-id": _vm.cosmicEventId,
      "document-id": _vm.documentId,
      "drawing-number": _vm.drawingNumber,
      "item-instance-id": _vm.itemInstanceId,
      "organization-code": _vm.organizationCode,
      "risk-id": _vm.riskId,
      "side": _vm.side
    },
    on: {
      "created": _vm.reloadLinks,
      "modal-close": _vm.onModalClose
    }
  }) : _vm._e(), _vm.showAreYouSure ? _c('AreYouSureDialog', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };