import { InventoryMethod } from '~/db_types/swagger_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { EntityType, EquipmentGroupDisplay, EquipmentGroupNumberOnlyDisplay, FederalSupplyClassDisplay, FederalSupplyClassFCSRef, FederalSupplyClassToObjectClass, HardwareListTypeDisplay, InventoryClassDisplay, InventoryControlDisplay, InventoryMethodDisplay, InventoryStatusDisplay, InventoryUnitTypeAbbreviationDisplay, ItemInstanceInventoryTypeDisplay, ItemMasterCriticalityCodeDisplay, ItemMasterPropertyClassDisplay, ReturnableStatusDisplay } from '~/nasa_ui/types';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
import { periodResponseDisplay } from '../../dates/periodResponse';
import { CosmicIcons } from '../../ui/CosmicIcons';
import { addAsBuiltNumberDisplay, addAsDesignNumberDisplay, addBuildingStockBinDisplay, addComputedLastInspectionDate, addContactDisplays, addCrossContextLocationDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addInitializationStatusDisplay, addInventoryPlusMethodDisplay, addInventoryQuantityDisplay, addIsToolIcon, addItemNumberDisplay, addLastServiceDate, addLocationDisplay, addLotNumberDisplay, addMannedPressurizedTime, addMannedPressurizedTimeSinceLastService, addNameDisplay, addOverrideSoftwareVersionDisplay, addProjectCodeDisplay, addProjectCodesDisplay, addQuantitiesDisplay, addQuantityDisplay, addSequenceNumberDisplay, addSerialNumberDisplay, addServicePressurizedTime, addSideDisplay, addSinceLastInspection, addSizeDisplay, addSoftwareVersionDisplay, addSubTypeDisplay, addUnitCost, addUserDisplays } from '../displayMappers';
import { booleanIconDisplay, numberDisplay } from '../displays';
import { isNullOrUndefined } from '../isNullOrUndefined';
import { sortObjectNumericallyBy } from '../sortObjectNumericallyBy';
const isUniformInventoryClass = (input) => {
    if (Array.isArray(input)) {
        if (input.length === 0) {
            return true;
        }
        const firstClass = input[0];
        return input.every((item) => item === firstClass);
    }
    else {
        // If the input is a single string
        return true;
    }
};
export const transformInventories = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addBuildingStockBinDisplay)
        .map(addCrossContextLocationDisplay)
        .map(addDescriptionDisplay)
        .map(addInventoryPlusMethodDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLocationDisplay)
        .map(addLotNumberDisplay)
        .map(addOverrideSoftwareVersionDisplay)
        .map(addProjectCodeDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addSoftwareVersionDisplay)
        .map(addSubTypeDisplay(ItemInstanceInventoryTypeDisplay))
        .map(addUnitCost)
        .map((item) => {
        const _item = { ...item };
        let icon = '';
        if (item.isInstalled) {
            icon = `
          <i
            class="fa-2x ${CosmicIcons['true']} success--text"
            title="This item is INSTALLED on another item"
          ></i>`;
        }
        else {
            icon = `<i class="fa-2x ${CosmicIcons['false']} secondary--text" title="Not installed"></i>`;
        }
        item._isInstalled = icon;
        // status
        _item._status = item.status ? InventoryStatusDisplay.get(item.status) : DEFAULT_DASH;
        return _item;
    });
};
export const transformInventoryResponses = (items) => {
    return transformInventories(items);
};
export const transformItemInstances = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addAsDesignNumberDisplay)
        .map(addComputedLastInspectionDate)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addInitializationStatusDisplay)
        .map(addInventoryQuantityDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLastServiceDate)
        .map(addLotNumberDisplay)
        .map(addMannedPressurizedTime)
        .map(addMannedPressurizedTimeSinceLastService)
        .map(addNodeIdToResponseObj)
        .map(addProjectCodesDisplay)
        .map(addSerialNumberDisplay)
        .map(addServicePressurizedTime)
        .map(addSideDisplay)
        .map(addSinceLastInspection)
        .map(addSizeDisplay)
        .map((item) => {
        const _item = { ...item };
        // CEI
        _item._ceiNumber = _item.itemMaster?.contractEndingItemNumber ?? DEFAULT_DASH;
        _item._entityType = EntityType.ITEM_INSTANCE;
        // equipmentControlNumber (ECN)
        _item._equipmentControlNumber = _item.equipmentControlNumber || DEFAULT_DASH;
        // subType
        if (!_item.subType) {
            _item._subType = 'Multiple';
        }
        // if all InventoryClass in the [] are the same we say the subType is "that" type even though its stored on the Inventory
        // if all Inventory for this Instance arent the same we say its "Multiple"
        else if (_item.subType && isUniformInventoryClass(_item.subType) && Array.isArray(_item.subType)) {
            _item._subType = InventoryClassDisplay.get(_item.subType[0]);
        }
        else if (_item.subType && isUniformInventoryClass(_item.subType)) {
            _item._subType = InventoryClassDisplay.get(_item.subType);
        }
        else {
            _item._subType = `Multiple`;
        }
        _item._overrideHazardousMaterialText = _item.overrideHazardousMaterialText || DEFAULT_DASH;
        _item._overrideMeanTimeToRepair = periodResponseDisplay(_item.overrideMeanTimeToRepair);
        _item._overrideSoftwareVersion = _item.overrideSoftwareVersion || DEFAULT_DASH;
        // Project codes
        // api2 its called projectCode in gql its called projectCodes
        _item._projectCode = _item.projectCode || [];
        _item._projectCode.sort();
        // Quantities
        _item._quantityAvailable = numberDisplay(_item.availableQuantity);
        _item._quantityInstalled = numberDisplay(_item.installedQuantity);
        _item._quantityInventory = numberDisplay(_item.inventoryQuantity);
        _item._quantityManaged = numberDisplay(_item.managedQuantity);
        _item._quantityUnmanaged = numberDisplay(_item.unmanagedQuantity);
        // ReturnableStatus
        _item._returnableStatus = _item.returnableStatus
            ? ReturnableStatusDisplay.get(_item.returnableStatus)
            : DEFAULT_DASH;
        _item._urlManage = _item.id ? `/hardware/item_instances/manage/${_item.id}#information` : null;
        return _item;
    });
};
export const transformItemInstanceResponses = (items) => {
    return transformItemInstances(items);
};
export const transformItemInstanceResponseWithRefs = (items) => {
    return transformItemInstances(items);
};
export const transformItemMasters = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addInitializationStatusDisplay)
        .map(addItemNumberDisplay)
        .map(addQuantitiesDisplay)
        .map(addSideDisplay)
        .map(addIsToolIcon)
        .map(addSubTypeDisplay(EquipmentGroupDisplay))
        .map((item) => {
        const _item = { ...item };
        // Approved AD
        _item._approvalStatusIcon = !isNullOrUndefined(_item.isApprovedAsDesign)
            ? booleanIconDisplay(_item.isApprovedAsDesign)
            : DEFAULT_DASH;
        // CEI
        _item._ceiNumber = _item.contractEndingItemNumber || DEFAULT_DASH;
        _item._ceiLevel = _item.contractEndingItemLevel || DEFAULT_DASH;
        _item._cleanlinessTrackingText = _item.cleanlinessTrackingText || DEFAULT_DASH;
        // Crit code
        _item._criticalityCode = _item.criticalityCode
            ? ItemMasterCriticalityCodeDisplay.get(_item.criticalityCode)
            : DEFAULT_DASH;
        _item._criticalityCodeShort =
            _item._criticalityCode === DEFAULT_DASH ? DEFAULT_DASH : _item._criticalityCode.split(':')[0];
        _item._entityType = EntityType.ITEM_MASTER;
        // FederalSupplyClass
        _item._federalSupplyClass = _item.federalSupplyClass
            ? FederalSupplyClassDisplay.get(_item.federalSupplyClass)
            : DEFAULT_DASH;
        _item._federalSupplyClassNumber = _item.federalSupplyClass
            ? FederalSupplyClassFCSRef.get(_item.federalSupplyClass)
            : DEFAULT_DASH;
        _item._objectClass = _item.federalSupplyClass
            ? FederalSupplyClassToObjectClass.get(_item.federalSupplyClass)
            : DEFAULT_DASH;
        _item._hazardousMaterialText = _item.hazardousMaterialText || DEFAULT_DASH;
        _item._inventoryControl = _item.inventoryControl
            ? InventoryControlDisplay.get(_item.inventoryControl)
            : DEFAULT_DASH;
        _item._inventoryMethod = _item.inventoryMethod ? InventoryMethodDisplay.get(_item.inventoryMethod) : DEFAULT_DASH;
        _item._inventoryUnitTypeShort = _item.inventoryUnitType
            ? InventoryUnitTypeAbbreviationDisplay.get(_item.inventoryUnitType)
            : DEFAULT_DASH;
        _item._opsNom = _item.opsNom || DEFAULT_DASH;
        // Property class
        _item._propertyClass = _item.propertyClass
            ? ItemMasterPropertyClassDisplay.get(_item.propertyClass)
            : DEFAULT_DASH;
        _item._sizes = _item.sizes ? _item.sizes : [];
        _item._sizes.sort();
        _item._softwareVersion = _item.softwareVersion || DEFAULT_DASH;
        _item._showInventoryUnitType = _item.inventoryMethod && _item.inventoryMethod === InventoryMethod.UNIT;
        _item._specialHandlingText = _item.specialHandlingText || DEFAULT_DASH;
        _item._specialPackagingText = _item.specialPackagingText || DEFAULT_DASH;
        _item._subTypeNumberOnly = _item.subType ? EquipmentGroupNumberOnlyDisplay.get(_item.subType) : DEFAULT_DASH;
        _item._urlManage =
            _item.drawingNumber && _item.asBuiltNumber && _item.side
                ? `/hardware/item_masters/manage/${encodeURIComponent(_item.drawingNumber)}/${encodeURIComponent(_item.asBuiltNumber)}/${_item.side}#information`
                : null;
        return _item;
    });
};
export const transformItemMasterResponses = (items) => {
    return transformItemMasters(items);
};
export const transformItemMasterResponseWithRefs = (items) => {
    return transformItemMasters(items);
};
export const transformItemDrawings = (items) => {
    return items
        .map(addContactDisplays)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addInitializationStatusDisplay)
        .map(addItemNumberDisplay)
        .map(addQuantitiesDisplay)
        .map((item) => {
        // _eeeIdentifier
        const icon = booleanIconDisplay(item.eeeIdentifier);
        item._eeeIdentifier = icon;
        return item;
    })
        .map((item) => {
        const _item = { ...item };
        // _aliases
        _item._aliases = _item.aliases ? _item.aliases : [];
        _item._aliases.sort();
        _item._entityType = EntityType.ITEM_DRAWING;
        // item classes
        _item._itemClasses = _item.itemClasses
            ? _item.itemClasses.map((itemClass) => InventoryClassDisplay.get(itemClass))
            : [];
        _item._itemClasses.sort();
        _item._meanTimeBetweenFailure = periodResponseDisplay(_item.meanTimeBetweenFailure);
        _item._meanTimeToRepair = periodResponseDisplay(_item.meanTimeToRepair);
        _item._sizes = _item.sizes ? _item.sizes : [];
        _item._sizes.sort();
        _item._urlManage = _item.drawingNumber
            ? `/hardware/item_drawings/manage/${encodeURIComponent(_item.drawingNumber)}`
            : null;
        return _item;
    });
};
export const transformItemDrawingResponses = (items) => {
    return transformItemDrawings(items);
};
export const transformItemDrawingResponseWithRefs = (items) => {
    return transformItemDrawings(items);
};
export const transformAutoHardwareListResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map(addDescriptionDisplay)
        .map(addNameDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._drawingNumberPrefix = _item.drawingNumberPrefix || [];
        _item._drawingNumberPrefix.sort();
        return _item;
    });
};
export const transformHardwareListResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addSubTypeDisplay(HardwareListTypeDisplay))
        .map(addUserDisplays)
        .map(addNameDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._addendumNumber = _item.addendumNumber ? _item.addendumNumber.toString() : DEFAULT_DASH;
        _item._authorizer = _item.authorizer || DEFAULT_DASH;
        _item._number = _item.number || DEFAULT_DASH;
        _item._partCount = numberDisplay(_item.partCount);
        _item._revision = _item.revision || DEFAULT_DASH;
        _item._urlManage = _item.id ? `/hardware/lists/manage/${_item.id}#information` : '';
        return _item;
    });
};
export const transformHardwareListResponseWithRefs = (items) => {
    return items
        .map(addDateDisplays)
        .map(addSubTypeDisplay(HardwareListTypeDisplay))
        .map(addUserDisplays)
        .map(addNameDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._addendumNumber = _item.addendumNumber ? _item.addendumNumber.toString() : DEFAULT_DASH;
        _item._authorizer = _item.authorizer || DEFAULT_DASH;
        _item._hardwareListParts = _item.hardwareListParts
            ? transformHardwareListPartResponses(_item.hardwareListParts).sort(sortObjectNumericallyBy('sequence'))
            : [];
        _item._number = _item.number || DEFAULT_DASH;
        _item._partCount = numberDisplay(_item.partCount);
        _item._revision = _item.revision || DEFAULT_DASH;
        _item._urlManage = _item.id ? `/hardware/lists/manage/${_item.id}#information` : '';
        return _item;
    });
};
export const transformHardwareListPartResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map(addQuantityDisplay)
        .map(addSequenceNumberDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._addendumNumber = _item.addendumNumber ? _item.addendumNumber.toString() : DEFAULT_DASH;
        _item._notesNumberOnly = _item.notes
            ? _item.notes
                .sort((a, b) => {
                return parseFloat(a) - parseFloat(b);
            })
                .join(', ')
            : DEFAULT_DASH;
        _item._partReference = _item.partReference
            ? transformPartReferenceResponseDisplay([_item.partReference])[0]
            : null;
        return _item;
    });
};
export const transformHardwareListTemplateResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addNameDisplay)
        .map(addUserDisplays)
        .map(addSubTypeDisplay(HardwareListTypeDisplay))
        .map((item) => {
        const _item = { ...item };
        _item._slotCount = (_item.hardwareListTemplateSlots || []).length.toString();
        _item._slots = _item.hardwareListTemplateSlots
            ? transformHardwareListTemplateSlotResponses(_item.hardwareListTemplateSlots).sort(sortObjectNumericallyBy('sequence'))
            : [];
        return _item;
    });
};
export const transformHardwareListTemplateSlotResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map(addSequenceNumberDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._hardwareListTemplateParts = _item.hardwareListTemplateParts
            ? transformHardwareListTemplatePartResponses(_item.hardwareListTemplateParts)
            : [];
        _item._minQuantity = isNullOrUndefined(_item._minQuantity) ? DEFAULT_DASH : numberDisplay(_item.minQuantity);
        _item._maxQuantity = isNullOrUndefined(_item._maxQuantity) ? DEFAULT_DASH : numberDisplay(_item.maxQuantity);
        _item._name = _item.name || DEFAULT_DASH;
        _item._isTemplate = true;
        return _item;
    });
};
export const transformHardwareListTemplatePartResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        return _item;
    });
};
export const transformPartReferenceResponseDisplay = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addLotNumberDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._contractEndingItemNumber = _item.contractEndingItemNumber || DEFAULT_DASH;
        _item._calibrationNumber = _item.calibrationNumber || DEFAULT_DASH;
        _item._itemClasses =
            _item.itemClass && _item.itemClass.length > 0
                ? _item.itemClass.map((cls) => InventoryClassDisplay.get(cls))
                : [];
        _item._itemClasses.sort();
        _item._location = _item.location || _item.itemInstanceId ? NOT_APPLICABLE : DEFAULT_DASH;
        _item._size = _item.size || DEFAULT_DASH;
        return _item;
    });
};
//#endregion
