var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType, EntityType, UserPermission } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
import { getCdocsUrl, getOgUrl } from '~/nasa_ui/utils/url';
import pkg from '../../../package.json';
const ADMIN_MENU = {
    displayName: 'Admin',
    icon: CosmicIcons.cog,
    children: [
        {
            displayName: 'Admin area',
            to: '/admin',
            icon: CosmicIcons['ADMIN'],
            textColor: `var(--v-secondary-base)`
        },
        {
            displayName: 'Manage Users',
            to: `/${EntityType.USER}/search`,
            icon: CosmicIcons[EntityType.USER],
            textColor: `var(--v-${EntityType.USER}-base)`
        },
        {
            displayName: 'Manage User Groups',
            to: `/${EntityType.USER}/groups/search`,
            icon: CosmicIcons[EntityType.USER_GROUP],
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`
        },
        {
            displayName: 'Approval templates',
            to: `/approval_templates/manage`,
            icon: 'fa-duotone fa-solid fa-face-awesome',
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`
        }
    ]
};
const ADMIN_ONLY_ROUTES = ['Admin'];
let TheSideNav = class TheSideNav extends Mixins(CurrentUserMixin, BaseCosmic) {
    AlertType = AlertType;
    appVersion = pkg.version;
    filterMenu = null;
    showChangeLog = false;
    userIsMriDevTeam; // to show demo menu item
    value;
    outageAlertState;
    get cDocsLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/documents_app_logo_light.svg`;
    }
    get cDocsUrl() {
        return getCdocsUrl();
    }
    get combinedRoutes() {
        const flattenedRoutes = this.$flatten(this.filteredTheSideNavigationMenu.map((menuItem) => {
            return menuItem.children;
        })).sort(sortObjectBy('displayName'));
        return flattenedRoutes;
    }
    get cosmicLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/main_app_logo_light.svg`;
    }
    get cosmicDocsLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/documents_app_logo_light.svg`;
    }
    get isLogisticsAdmin() {
        return this.currentUserHasPermission(UserPermission.LOGISTICS_ADMIN);
    }
    get isRouteDeliveryMatrix() {
        return this.$route.path.startsWith(`/delivery_matrix`);
    }
    get isRouteDeliveryOrder() {
        return this.$route.path.startsWith(`/delivery_orders`);
    }
    get ogUrl() {
        return getOgUrl();
    }
    get filteredTheSideNavigationMenu() {
        let filteredMenu = [ADMIN_MENU];
        // filter out any admin only routes if user role is not ADMIN
        if (!this.currentUserIsAdmin) {
            filteredMenu = this.filterOutAdminOnlyRoutes(filteredMenu);
        }
        return filteredMenu;
    }
    filterOutAdminOnlyRoutes(menuItems) {
        return menuItems.reduce((filterMenu, menuItem) => {
            if (menuItem.children) {
                menuItem.children = menuItem.children?.filter((child) => {
                    return !ADMIN_ONLY_ROUTES.includes(child.displayName);
                });
            }
            filterMenu.push(menuItem);
            return filterMenu;
        }, []);
    }
    closeSideNav() {
        this.$message('closeTheSideNav');
    }
    onClickOfShowChangeLog() {
        this.showChangeLog = true;
    }
    emitInput(val) {
        return val;
    }
    focusEvent(event) { }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], TheSideNav.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", Object)
], TheSideNav.prototype, "outageAlertState", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "emitInput", null);
__decorate([
    Emit('openSideNav'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof FocusEvent !== "undefined" && FocusEvent) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "focusEvent", null);
TheSideNav = __decorate([
    Component
], TheSideNav);
export default TheSideNav;
