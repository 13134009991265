import { AssemblyTemplateType } from '~/db_types/swagger_types';
import { AssemblyTemplateTypeDisplay, EntityType } from '~/nasa_ui/types';
import { buildIconWithLabelAsString } from '../../ui/buildIconAsString';
import { CosmicIcons } from '../../ui/CosmicIcons';
import { addDateDisplays, addNameDisplay, addPbsItemIdDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { transformDocuments } from '../transformEntityToTableFormat';
// Old transform
export const transformAssemblyTemplates = (items) => {
    return items
        .map(addDateDisplays)
        .map(addSubTypeDisplay(AssemblyTemplateTypeDisplay))
        .map(addUserDisplays)
        .map(addNameDisplay)
        .map(addPbsItemIdDisplay)
        .map((at) => {
        const _at = { ...at };
        const computeColor = (sr) => {
            if (sr.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE) {
                return EntityType.ASSEMBLY_TEMPLATE;
            }
            else if (sr.subType === AssemblyTemplateType.EVENT_ASSEMBLY) {
                return EntityType.EVENT;
            }
            else if (sr.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE) {
                return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
            }
            return EntityType.ASSEMBLY_TEMPLATE;
        };
        const computeIcon = (sr) => {
            if (sr.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE) {
                return CosmicIcons[EntityType.ASSEMBLY_TEMPLATE];
            }
            else if (sr.subType === AssemblyTemplateType.EVENT_ASSEMBLY) {
                return CosmicIcons[EntityType.EVENT];
            }
            else if (sr.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE) {
                return CosmicIcons[EntityType.PRODUCT_BREAKDOWN_STRUCTURE];
            }
            return CosmicIcons[EntityType.ASSEMBLY_TEMPLATE];
        };
        _at._entityTypeIcon = buildIconWithLabelAsString({
            label: AssemblyTemplateTypeDisplay.get(at.subType) || '?',
            labelAlignment: 'center',
            title: AssemblyTemplateTypeDisplay.get(at.subType) || '?',
            iconClass: computeIcon(at),
            color: computeColor(at)
        });
        if (at.document) {
            _at._document = transformDocuments([at.document])[0];
        }
        return _at;
    });
};
export const transformAssemblyTemplateResponses = (items) => {
    return transformAssemblyTemplates(items);
};
export const transformAssemblyTemplateResponseWithChildrens = (items) => {
    return transformAssemblyTemplates(items);
};
