var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppDynamicWrapper', {
    attrs: {
      "color": "primary",
      "is-open": "",
      "display-type": _vm.displayType
    }
  }, [_c('v-flex', [_vm._t("title", function () {
    return [_c('span', {
      staticClass: "computed_title"
    }, [_vm._v(_vm._s(_vm.computedTitle))])];
  })], 2), _vm.latestComment && !_vm.isFetching ? _c('AppMarkdownDisplay', {
    attrs: {
      "title": _vm.computedTitle,
      "markdown": _vm.latestComment
    }
  }) : _vm._e(), _vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e(), !_vm.latestComment && !_vm.isFetching ? _c('div', [_vm._t("no-data", function () {
    return [_c('Alert', {
      attrs: {
        "type": _vm.AlertType.INFO
      }
    }, [_vm._v("No " + _vm._s(_vm.computedTitle) + " provided.")])];
  })], 2) : _vm._e(), !_vm.readOnly && !_vm.inlineCreate ? _c('ButtonSave', {
    attrs: {
      "disabled": _vm.showModal
    },
    on: {
      "click": _vm.onClickOfUpdate
    }
  }, [_vm._v(" Update " + _vm._s(_vm.computedTitle) + " ")]) : _vm._e(), !_vm.readOnly && _vm.inlineCreate && !_vm.showModal ? _c('ButtonSave', {
    attrs: {
      "disabled": _vm.showModal
    },
    on: {
      "click": _vm.onClickOfUpdate
    }
  }, [_vm._v(" Update " + _vm._s(_vm.computedTitle) + " ")]) : _vm._e(), _vm.inlineCreate && _vm.showModal ? _c('div', [_c('div', {
    staticClass: "mb-2",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    }
  }, [_c('ButtonSave', {
    attrs: {
      "disabled": _vm.shouldDisableCreateButton,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfCreate
    }
  }, [_vm._v(" Save " + _vm._s(_vm.computedTitle) + " ")]), !_vm.readOnly && _vm.inlineCreate && _vm.showModal ? _c('ButtonCancel', {
    on: {
      "click": _vm.onModalClose
    }
  }) : _vm._e()], 1), _c('AppMarkdown', {
    attrs: {
      "title": _vm.computedTitle
    },
    model: {
      value: _vm.textOnForm,
      callback: function ($$v) {
        _vm.textOnForm = $$v;
      },
      expression: "textOnForm"
    }
  })], 1) : _vm._e(), _vm.showModal && !_vm.inlineCreate ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.onModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "x12": "",
            "mb-3": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "title": _vm.computedTitle
          },
          model: {
            value: _vm.textOnForm,
            callback: function ($$v) {
              _vm.textOnForm = $$v;
            },
            expression: "textOnForm"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Save " + _vm._s(_vm.computedTitle) + " ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.onModalClose
          }
        })];
      },
      proxy: true
    }], null, false, 3422447829)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };