var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "modal-width": "50vw",
      "persistent": "",
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "input": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          staticClass: "ml-0",
          attrs: {
            "disabled": _vm.shouldDisableCreateCommentButton
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Comment ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('div', [!_vm.commentTypeOverride ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-half mb-2"
  }, [_c('AppSelect', {
    attrs: {
      "required": "",
      "chips": false,
      "disabled": _vm.readOnly,
      "selectOptions": _vm.computedCommentTypeOptions,
      "display": _vm.CommentTypeDisplay,
      "label": "Comment type"
    },
    model: {
      value: _vm.formData.subType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "subType", $$v);
      },
      expression: "formData.subType"
    }
  })], 1)]) : _vm._e(), _c('AppMarkdown', {
    attrs: {
      "required": "",
      "label": "Comment",
      "read-only": _vm.readOnly
    },
    model: {
      value: _vm.formData.comment,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "comment", $$v);
      },
      expression: "formData.comment"
    }
  })], 1)])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };