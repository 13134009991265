import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EntityType } from '~/nasa_ui/types';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
let AppHardwareDisplay = class AppHardwareDisplay extends Vue {
    fetchedInventory = null;
    hideBorderBottom;
    nodeId;
    title;
    get computedAsBuiltNumber() {
        if (!this.isItemMaster) {
            return null;
        }
        return this.parsedNodeId.asBuiltNumber;
    }
    get computedDrawingNumber() {
        if (!this.isItemDrawing && !this.isItemMaster) {
            return null;
        }
        if (this.isItemDrawing) {
            return this.parsedNodeId.drawingNumber;
        }
        if (this.isItemMaster) {
            return this.parsedNodeId.drawingNumber;
        }
        return null;
    }
    // only valid for inventory
    get computedItemInstanceId() {
        if (!this.isInventory) {
            return null;
        }
        return this.fetchedInventory?.itemInstanceId || null;
    }
    get computedId() {
        if (!this.isItemInstance && !this.isInventory) {
            return null;
        }
        if (this.isInventory) {
            return this.parsedNodeId.id;
        }
        return this.parsedNodeId.id;
    }
    get computedSide() {
        if (!this.isItemMaster) {
            return null;
        }
        return this.parsedNodeId.side;
    }
    get computedTitle() {
        return this.title;
    }
    get hardwareType() {
        if (!this.nodeId) {
            return null;
        }
        return this.parsedNodeId?.entityType;
    }
    get parsedNodeId() {
        return parseNodeId(this.nodeId);
    }
    get hasComputedTitleOrSlot() {
        return this.hasTitleSlot || Boolean(this.computedTitle);
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get isInventory() {
        return this.hardwareType === EntityType.INVENTORY;
    }
    get isItemDrawing() {
        return this.hardwareType === EntityType.ITEM_DRAWING;
    }
    get isItemInstance() {
        return this.hardwareType === EntityType.ITEM_INSTANCE;
    }
    get isItemMaster() {
        return this.hardwareType === EntityType.ITEM_MASTER;
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
    async onIsInventoryChange() {
        if (!this.isInventory) {
            return;
        }
        const resp = await this.$http.get(`/inventory/${this.computedId}`);
        console.log(resp);
        this.fetchedInventory = resp.data;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "title", void 0);
__decorate([
    Watch('isInventory', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppHardwareDisplay.prototype, "onIsInventoryChange", null);
AppHardwareDisplay = __decorate([
    Component
], AppHardwareDisplay);
export default AppHardwareDisplay;
