var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseSearch, { srFilterOutSelected } from '~/nasa_ui/base/BaseSearch';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { LocationContractOptions, LocationContractOptionsDisplay } from '~/nasa_ui/types/enums/location';
import { getLocations } from '~/nasa_ui/utils';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { LocationTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { LocationResponseWithRefsDisplay, transformLocations } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchLocation = class SearchLocation extends Mixins(BaseSearch, CurrentUserMixin) {
    entityType = EntityType.LOCATION;
    hint = 'Stock, Bin, Building, or Org code';
    tableHeaders = [...LocationTableHeaders];
    LocationContractOptions = LocationContractOptions;
    LocationContractOptionsDisplay = LocationContractOptionsDisplay;
    locationContractOptionLocal = LocationContractOptions.ALL;
    placeholder = 'search for locations';
    searchFilters = {
        bin: null,
        building: null,
        contractNumber: null,
        organizationCode: null,
        query: '',
        room: null,
        stock: null,
        isManaged: true,
        hasInventory: true,
        includeArchived: false
    };
    locationContractOption;
    get userContext() {
        return this.currentUserActiveSupportContext;
    }
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('contractNumber', 'contracts'), this.buildAppChipForProperty('organizationCode', 'organizations'));
        if (this.searchFilters.isManaged) {
            appChips.push({
                color: EntityType.CONTRACT,
                disabled: true,
                filterType: 'isManaged',
                outline: false,
                text: 'Including managed',
                textColor: 'white',
                value: 'true'
            });
        }
        if (this.searchFilters.hasInventory) {
            appChips.push({
                color: EntityType.INVENTORY,
                disabled: true,
                filterType: 'hasInventory',
                outline: false,
                text: 'Has Inventory',
                textColor: 'white',
                value: 'true'
            });
        }
        if (this.searchFilters.includeArchived) {
            appChips.push({
                color: AlertType.WARNING,
                disabled: true,
                filterType: 'includeArchived',
                outline: false,
                text: 'Including archived',
                textColor: 'black',
                value: 'true'
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    /**
     * Builds up the right wording based on whether it's cross-context and what
     * locationContractOptionLocal is set to.
     */
    get defaultChipText() {
        const contractOption = this.locationContractOptionLocal;
        const managedText = contractOption.substring(0, 1) + contractOption.substring(1).toLowerCase();
        return `${managedText} ${this.userContext} Locations`;
    }
    get formattedLocations() {
        return this.searchResults ? transformLocations(this.searchResults) : null;
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    onChangeOfTransformedResponse(locations) {
        if (!locations) {
            this.$set(this, 'searchResults', []);
            return;
        }
        locations = locations.map((d) => {
            if (d.isArchived) {
                d.rowClass = 'archived';
            }
            return d;
        });
        locations = this.addSelectedPropIfSelected(locations);
        this.$set(this, 'searchResults', locations.filter(srFilterOutSelected));
    }
    /**
     * Returns the variables required to perform the search query
     */
    get gqlSearchQueryVariables() {
        const query = {};
        query.query = this.searchInput || null;
        const filters = this.pureSearchFilters;
        const deleteFalsePropertyFromSearchFilters = function (object, propertiesToFilter) {
            for (const property in object) {
                if (object[property] === false) {
                    delete object[property];
                }
            }
        };
        deleteFalsePropertyFromSearchFilters(filters, ['hasInventory', 'includeArchived', 'isManaged']);
        const variables = Object.assign({}, filters, query);
        return variables;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            // Execute query
            const response = await getLocations({
                query: this.SEARCH_QUERY,
                ...this.gqlSearchQueryVariables,
                skip: 0,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            });
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.emitSearchPerformed();
        }
        catch (error) {
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    initializelocationContractOption() {
        this.locationContractOptionLocal = this.locationContractOption;
    }
};
__decorate([
    Prop({
        type: [String, String],
        default: LocationContractOptions.ALL
    }),
    __metadata("design:type", typeof (_a = typeof LocationContractOptions !== "undefined" && LocationContractOptions) === "function" ? _a : Object)
], SearchLocation.prototype, "locationContractOption", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof LocationResponseWithRefsDisplay !== "undefined" && LocationResponseWithRefsDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SearchLocation.prototype, "onClickOfSearchResult", null);
__decorate([
    Watch('transformedSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SearchLocation.prototype, "onChangeOfTransformedResponse", null);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchLocation.prototype, "performSearch", null);
__decorate([
    Watch('locationContractOption', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchLocation.prototype, "initializelocationContractOption", null);
SearchLocation = __decorate([
    Component
], SearchLocation);
export default SearchLocation;
