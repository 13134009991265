var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.transformedChangeSet ? _c('v-card', {
    staticClass: "my-1",
    attrs: {
      "hover": ""
    }
  }, [_c('v-card-title', {
    staticClass: "white--text",
    class: _vm.EntityType.CHANGE_SET
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET],
      "icon-color": "white"
    }
  }), _c('h3', [_vm._v(_vm._s(_vm.transformedChangeSet._subType))])], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-subheader', [_c('c-icon-fa', {
    staticClass: "subheading",
    attrs: {
      "icon": _vm.$icons.calendar,
      "icon-color": "var(--v-events-base)"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Dates")])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Created")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedChangeSet._createdDateTime) + " ")]), _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._createdDateTimeDistanceInWordsFromToday) + " ")])])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Opened")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedChangeSet._openedDateTime) + " ")]), _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._openedDateDistanceInWordsFromToday) + " ")])])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Proj. closure")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedChangeSet._projectedDateTime) + " ")]), _vm.transformedChangeSet.projectedDate ? _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._projectedDateDistanceInWordsFromToday) + " ")])]) : _vm._e()])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Closed")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedChangeSet._computedClosedDateTime) + " ")]), _vm.transformedChangeSet.computedClosedDate ? _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._computedClosedDateDistanceInWordsFromToday) + " ")])]) : _vm._e()])], 1), _c('v-divider'), _c('section', [_c('v-subheader', [_c('c-icon-fa', {
    staticClass: "subheading",
    attrs: {
      "icon": "fad fa-gavel",
      "icon-color": "var(--v-change_sets-base)"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Authority")])], 1), _vm.hasAuthority ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Code")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._authorityCode) + " ")])])], 1) : _vm._e(), _vm.hasAuthority ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Number")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._authorityNumber) + " ")])])])], 1) : _vm._e(), !_vm.hasAuthority ? _c('v-list-tile', [_c('v-list-tile-content', [_c('div', {
    staticClass: "white--text change_sets pa-2 font-weight-bold"
  }, [_vm._v("No authority provided.")])])], 1) : _vm._e()], 1), _c('v-divider'), _c('section', [_c('v-subheader', {
    staticClass: "status_subheading"
  }, [_c('c-icon-fa', {
    staticClass: "subheading",
    attrs: {
      "icon-color": "var(--v-change_sets-base)",
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET]
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Change set status")])], 1), _vm.isAutoApproved ? _c('v-list-tile', [_c('v-list-tile-content', [_c('div', {
    staticClass: "white--text success pa-2 font-weight-bold"
  }, [_vm._v("Auto approved.")])])], 1) : _c('div', _vm._l(_vm.transformedChangeSet._changeSetAuthorities, function (csa, index) {
    return _c('v-list-tile', {
      key: index
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_c('v-list-tile-content', [_c('div', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: csa._statusIcon,
              expression: "csa._statusIcon"
            }]
          })]), csa._userGroup && csa._userGroup.name ? _c('v-list-tile-content', _vm._g({
            staticClass: "align-end data_item"
          }, on), [_c('strong', {}, [_vm._v(" " + _vm._s(csa._userGroup.name) + " ")])]) : _vm._e()];
        }
      }], null, true)
    }, [csa._userGroup && csa._userGroup._users ? _c('AppFormWrapper', {
      attrs: {
        "size": "1",
        "color": _vm.EntityType.USER
      }
    }, [csa.status === _vm.ChangeSetStatus.OPEN ? _c('div', {
      staticClass: "mb-2"
    }, [_vm._v(" This approval is "), _c('strong', {
      staticClass: "success--text"
    }, [_vm._v("OPEN")]), _vm._v(". One of the members below can approve/reject this. ")]) : _vm._e(), csa.status === _vm.ChangeSetStatus.REJECTED ? _c('div', {
      staticClass: "mb-2"
    }, [_vm._v(" This approval has been "), _c('strong', {
      staticClass: "error--text"
    }, [_vm._v("REJECTED")]), _vm._v(" by "), _c('u', [_vm._v(_vm._s(csa._authorizedBy))]), _vm._v(" on "), _c('u', [_vm._v(_vm._s(csa._authorizedDate))])]) : _vm._e(), csa.status === _vm.ChangeSetStatus.APPROVED ? _c('div', {
      staticClass: "mb-2"
    }, [_vm._v(" This approval has been "), _c('strong', {
      staticClass: "success--text"
    }, [_vm._v("APPROVED")]), _vm._v(" by "), _c('u', [_vm._v(_vm._s(csa._authorizedBy))]), _vm._v(" on "), _c('u', [_vm._v(_vm._s(csa._authorizedDate))])]) : _vm._e(), _c('AppHeader', {
      staticClass: "mb-1",
      attrs: {
        "uppercase": false
      }
    }, [_vm._v(_vm._s(csa._userGroup.name) + " members")]), _c('ul', _vm._l(csa._userGroup._users, function (user, index) {
      return _c('li', {
        key: index
      }, [_vm._v(" " + _vm._s(user._fullName) + " ")]);
    }), 0)], 1) : _vm._e()], 1)], 1);
  }), 1)], 1), _vm.isDocumentRelated ? _c('v-divider') : _vm._e(), _vm.isDocumentRelated && _vm.getDocument ? _c('section', [_c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-subheader', [_c('c-icon-fa', {
    staticClass: "subheading",
    attrs: {
      "icon": _vm.documentIconClass,
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Related Document (" + _vm._s(_vm.getDocument._subType) + ")")])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('div', {
    staticClass: "white--text documents pa-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.getDocument._title))])])], 1)], 1)], 1) : _vm._e(), _vm.isHardwareRelated ? _c('v-divider') : _vm._e(), _vm.isHardwareRelated ? _c('section', [_c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-subheader', [_c('c-icon-fa', {
    staticClass: "subheading",
    attrs: {
      "icon": _vm.hardwareIconClass,
      "icon-color": "var(--v-item_drawings-base)"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Related Hardware")])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Drawing number")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedChangeSet.drawingNumber) + " ")])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("AsBuilt")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item monospace_font"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedChangeSet._asBuiltNumber) + " ")])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Serial")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.transformedChangeSet._serialNumber,
      expression: "transformedChangeSet._serialNumber"
    }]
  })])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Lot")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.transformedChangeSet._lotNumber,
      expression: "transformedChangeSet._lotNumber"
    }]
  })])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Side")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedChangeSet.side) + " ")])])], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };