import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import BaseView from '~/nasa_ui/base/BaseView';
import { EntityType, EntityTypePluralDisplay } from '~/nasa_ui/types';
import { fetchCurrentUser } from '../router/utils';
let BaseManageView = class BaseManageView extends Mixins(BaseView) {
    ADMIN_PERMISSION = null;
    activeTabIndex = 0;
    entityType = null;
    selectedEntity = null;
    get favorite() {
        return this.selectedEntity?.id || this.id;
    }
    get favoriteTooltip() {
        return `${this.isFavorite ? 'Remove from' : 'Add to'} favorites`;
    }
    get hasSelectedEntity() {
        return this.selectedEntity !== null;
    }
    get id() {
        return this.$route.params.id;
    }
    get isFavorite() {
        if (!this.entityType || !this.currentUserActiveSupportContext) {
            console.warn('Missing entityType on manage page.');
            return;
        }
        let favoritesForThisEntityType = this.currentUser?.attributes.favorites &&
            this.currentUser?.attributes.favorites[this.currentUserActiveSupportContext] &&
            this.currentUser.attributes.favorites[this.currentUserActiveSupportContext][this.entityType];
        if (!favoritesForThisEntityType) {
            favoritesForThisEntityType =
                this.currentUser?.attributes.favorites && this.currentUser?.attributes.favorites[this.entityType];
        }
        return Boolean(favoritesForThisEntityType?.includes(this.favorite));
    }
    get isReadOnly() {
        if (this.ADMIN_PERMISSION === null) {
            console.warn('ADMIN_PERMISSION is not set');
        }
        return this.ADMIN_PERMISSION ? !this.currentUserHasPermission(this.ADMIN_PERMISSION) : false;
    }
    get nodeId() {
        return this.$route.params.nodeId;
    }
    get rowsPerPageItems() {
        return [
            25,
            50,
            100,
            500,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get searchIconColor() {
        if (!this.entityType) {
            return 'var(--primary-base)';
        }
        return `var(--v-${this.entityType}-base)`;
    }
    get searchIconLabel() {
        if (!this.entityType) {
            return 'Search';
        }
        return `Search ${EntityTypePluralDisplay.get(this.entityType)}`;
    }
    get shouldShowTab() {
        return (name) => this.activeTabIndex === this.tabNames.indexOf(name);
    }
    get shouldShowInformationTab() {
        return this.activeTabIndex === this.tabNames.indexOf('information');
    }
    get shouldShowApprovalsTab() {
        return this.activeTabIndex === this.tabNames.indexOf('approvals');
    }
    get shouldShowClosureTab() {
        return this.activeTabIndex === this.tabNames.indexOf('closure');
    }
    get shouldShowCommentsTab() {
        return this.activeTabIndex === this.tabNames.indexOf('comments');
    }
    get shouldShowChangeSetsTab() {
        return this.activeTabIndex === this.tabNames.indexOf(EntityType.CHANGE_SET);
    }
    get shouldShowHistoryTab() {
        return this.activeTabIndex === this.tabNames.indexOf('history');
    }
    get shouldShowStepOverviewTab() {
        return this.activeTabIndex === this.tabNames.indexOf('overview');
    }
    get shouldShowStepsTab() {
        return this.activeTabIndex === this.tabNames.indexOf('steps');
    }
    get shouldShowTasksTab() {
        return this.activeTabIndex === this.tabNames.indexOf('tasks');
    }
    get shouldShowHardwareTab() {
        return this.activeTabIndex === this.tabNames.indexOf('hardware');
    }
    get shouldShowToolsTab() {
        return this.activeTabIndex === this.tabNames.indexOf('tools');
    }
    get shouldShowLinksTab() {
        return this.activeTabIndex === this.tabNames.indexOf('links');
    }
    get tabNames() {
        return [];
    }
    /**
     * Should set the selectedEntity
     */
    async fetchSelectedEntity() { }
    routeToSearch() {
        console.log('needs to navigate to route');
    }
    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    setActiveTabIndex() {
        const hash = this.$route.hash;
        if (!this.tabNames) {
            return;
        }
        if (!hash) {
            this.activeTabIndex = 0;
            return;
        }
        const tabName = hash.substring(1); // remove '#'
        const tabIndex = this.tabNames.indexOf(tabName);
        // set active tab if found
        this.activeTabIndex = tabIndex > -1 ? tabIndex : 0;
    }
    async toggleFavorite() {
        if (this.isFavorite) {
            try {
                await this.removeFavorite(this.favorite, this.entityType);
                this.$notification.add({ text: 'Removed from favorites.', type: this.AlertType.SUCCESS });
                fetchCurrentUser();
            }
            catch (err) {
                this.$errorUtility({ err });
            }
        }
        else {
            try {
                await this.addFavorite(this.favorite, this.entityType);
                this.$notification.add({ text: 'Added to favorites.', type: this.AlertType.SUCCESS });
                fetchCurrentUser();
            }
            catch (err) {
                this.$errorUtility({ err });
                this.$notification.add({ text: 'Unable to update favorites at this time.', type: this.AlertType.ERROR });
            }
        }
    }
    checkForTab(hash) {
        this.setActiveTabIndex();
    }
    setHash(activeTabIndex) {
        const tabName = this.tabNames[activeTabIndex];
        const nextLocation = {
            name: this.$route.name || '',
            params: { ...this.$route.params },
            query: this.$route.query,
            hash: `#${tabName}`
        };
        const needsDefaultHash = Boolean(tabName && !this.$route.hash);
        const isChangingTabs = Boolean(tabName && this.$route.hash !== `#${tabName}`);
        nextLocation.path = this.$route.path + `#${tabName}`;
        if (needsDefaultHash) {
            this.$router.replace(nextLocation);
        }
        else if (isChangingTabs) {
            this.$router.push(nextLocation.path);
        }
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseManageView.prototype, "checkForTab", null);
__decorate([
    DebounceAll(10),
    Watch('activeTabIndex', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], BaseManageView.prototype, "setHash", null);
BaseManageView = __decorate([
    Component
], BaseManageView);
export default BaseManageView;
