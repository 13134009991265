var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "modal-width": _vm.modalWidth,
      "color": "error darken-1",
      "persistent": "",
      "title": "Are you sure?"
    },
    on: {
      "input": _vm.onClickOfNo,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.onClickOfNo.apply(null, arguments);
      },
      "modal-close": _vm.onClickOfNo
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.showDeleteAlert ? _c('Alert', {
          attrs: {
            "type": "warning"
          }
        }, [_c('div', [_vm._v("This is a "), _c('em', [_vm._v("permanent")]), _vm._v(" "), _c('strong', [_vm._v("DELETE")]), _vm._v(". Be careful.")])]) : _vm._e(), _c('div', {
          staticClass: "constrain_body"
        }, [_vm._t("html")], 2)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.onClickOfNo
          }
        }), _c('v-spacer'), _c('ButtonRemove', {
          attrs: {
            "disabled": _vm.disableRemoveButton
          },
          on: {
            "click": _vm.onClickOfYes
          }
        }, [_vm._t("remove-button-text", function () {
          return [_vm._v("Yes, I'm sure. DELETE it.")];
        })], 2)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };