var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserSupportContextToValidEntityTypes } from '~/application.config';
import { Maybe, Side } from '~/db_types';
import { AssemblyTemplateResponseWithChildren, AssemblyTemplateType, HardwareListType } from '~/db_types/swagger_types';
import BaseModalAssemblyTemplate from '~/nasa_ui/base/BaseModalAssemblyTemplate';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType, UserPermission } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { AssemblyTemplatePartTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let ModalAssemblyTemplateEdit = class ModalAssemblyTemplateEdit extends Mixins(BaseModalAssemblyTemplate, CurrentUserMixin, HttpMixin) {
    HardwareListType = HardwareListType;
    selectedEntity = null;
    selectedHardwareList = null;
    // this is populated when submitting the edit form, provided the user has
    // elected to mirror an assembly tree and the selected inventory actually has
    // a working assembly.
    selectedInventoryWorkingAssemblyUuid = null;
    formData = {
        documentId: null,
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        pbsItemId: null,
        sequence: null,
        subType: null
    };
    assemblyTemplateId;
    get canDeleteAssemblyTemplate() {
        return this.selectedEntity && this.currentUserHasPermission(UserPermission.ASSEMBLY_TEMPLATE_ADMIN);
    }
    get computedAssemblyTemplatePartTableHeaders() {
        if (this.isEventAssemblyTemplate) {
            return [...this.AssemblyTemplateTreeTableHeaders];
        }
        return [...AssemblyTemplatePartTableHeaders];
    }
    get computedColor() {
        if (this.isGenericAssemblyTemplate) {
            return EntityType.ASSEMBLY_TEMPLATE;
        }
        else if (this.isEventAssemblyTemplate) {
            return EntityType.EVENT;
        }
        else if (this.isPbsAssemblyTemplate) {
            return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
        }
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    get editAssemblyTemplateVariables() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            attributes: {},
            parts: this.computedAssemblyTemplateParts,
            documentId: this.formData.documentId,
            hardwareListId: this.selectedHardwareList?.id ?? undefined,
            installedOn: this.formData.installedOn,
            maxQuantity: this.formData.maxQuantity,
            minQuantity: this.formData.minQuantity,
            name: this.formData.name,
            parentId: this.parentAssemblyTemplateId ? this.parentData?.id : undefined,
            pbsItemId: this.formData.pbsItemId,
            sequence: this.formData.sequence || 0,
            subType: this.formData.subType // required by api
        };
    }
    get hasChildren() {
        return Boolean(this.numberOfChildren);
    }
    get numberOfChildren() {
        return this.selectedEntity?.children?.length ?? 0;
    }
    get isDuplicateBySequenceByParent() {
        const duplicateIds = this.duplicateQueryResponse?.nodes || [];
        return Boolean(duplicateIds.filter((dupeAssyTemplate) => dupeAssyTemplate?.id !== this.assemblyTemplateId).length);
    }
    get isEventAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isGenericAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isPbsAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get isFormValid() {
        return this.isQuantityValid && this.isSequenceValid;
    }
    get isQuantityValid() {
        return this.isNotMoreThanMaxQuantity(this.formData.minQuantity, this.formData.maxQuantity);
    }
    get isRoot() {
        return Boolean(!this.selectedEntity?.parentId && this.selectedEntity?.sequence === 0);
    }
    get isSequenceValid() {
        const sequenceIsZeroOrMore = Boolean(this.formData.sequence >= 0);
        return Boolean(sequenceIsZeroOrMore && !this.isDuplicateBySequenceByParent);
    }
    get manageAssemblyTemplateLink() {
        if (!this.assemblyTemplateId) {
            return null;
        }
        return `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage/${this.assemblyTemplateId}`;
    }
    get minIsLessThanMax() {
        return this.formData.minQuantity < this.formData.maxQuantity;
    }
    get shouldAllowOnlyOneAssemblyTemplatePart() {
        return Boolean(this.selectedEntity?.parentId === null);
    }
    get shouldDisableEditButton() {
        return !this.isFormValid;
    }
    get shouldOpenHardwareSection() {
        return Boolean(this.computedAssemblyTemplateParts.length);
    }
    get shouldOpenTrackingDocumentSection() {
        return Boolean(this.selectedEntity?.documentId);
    }
    get shouldShowTrackingDocuments() {
        return this.currentUserActiveSupportContext
            ? (UserSupportContextToValidEntityTypes.get(this.currentUserActiveSupportContext) || []).includes(EntityType.DOCUMENT)
            : false;
    }
    get subTypeDisplay() {
        return this.formData.subType ? AssemblyTemplateTypeDisplay.get(this.formData.subType) : DEFAULT_DASH;
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    isSequenceZeroOrMore(quantity) {
        return quantity >= 0;
    }
    async fetchAssemblyTemplate(assemblyTemplateId) {
        if (!assemblyTemplateId) {
            return;
        }
        try {
            const resp = await this.httpGet(`/assembly-templates/${assemblyTemplateId}`);
            this.selectedEntity = resp.data ?? null;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Assembly Template'
            });
        }
    }
    async onClickOfDeleteAssemblyTemplate() {
        if (!this.selectedEntity?.id) {
            return;
        }
        this.deleteEntity(this.selectedEntity?.id);
    }
    async onClickOfEditAssemblyTemplate() {
        try {
            if (this.isFormValid && this.editAssemblyTemplateVariables) {
                await this.editAssemblyTemplate(this.assemblyTemplateId, this.editAssemblyTemplateVariables);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onMetaPlusEnter() {
        this.onClickOfEditAssemblyTemplate();
    }
    /**
     *  This is here rather than v-model bc that gives you the nodeId
     *
     * @param document DocumentsDisplay
     */
    onSelectionOfTrackingDocument(document) {
        if (document.id) {
            this.formData.documentId = document.id;
        }
    }
    onAssemblyTemplateChange(val) {
        if (val) {
            this.fetchAssemblyTemplate(val);
        }
    }
    onSelectedEntityChange(selectedEntity) {
        if (selectedEntity) {
            const val = { ...selectedEntity };
            this.formData = Object.assign({}, this.formData, val);
            this.selectedAssemblyTemplateParts =
                val.parts?.map((part) => {
                    return {
                        asBuiltNumber: part?.partReference.asBuiltNumber ?? null,
                        description: part?.partReference.drawingDescription ?? '',
                        drawingNumber: part?.partReference.drawingNumber ?? '',
                        side: part?.partReference.side ?? null,
                        serialNumber: part?.partReference.serialNumber ?? null
                    };
                }) || [];
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAssemblyTemplateEdit.prototype, "assemblyTemplateId", void 0);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateEdit.prototype, "onAssemblyTemplateChange", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof AssemblyTemplateResponseWithChildren !== "undefined" && AssemblyTemplateResponseWithChildren) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateEdit.prototype, "onSelectedEntityChange", null);
ModalAssemblyTemplateEdit = __decorate([
    Component
], ModalAssemblyTemplateEdit);
export default ModalAssemblyTemplateEdit;
