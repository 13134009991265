import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
let ModalConfirmationDelete = class ModalConfirmationDelete extends BaseModal {
    disableRemoveButton;
    showDeleteAlert;
    onClickOfYes() {
        this.emitModalClose();
    }
    onClickOfNo() {
        this.emitModalClose();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalConfirmationDelete.prototype, "disableRemoveButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ModalConfirmationDelete.prototype, "showDeleteAlert", void 0);
__decorate([
    Emit('affirmativeClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalConfirmationDelete.prototype, "onClickOfYes", null);
__decorate([
    Emit('negativeClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalConfirmationDelete.prototype, "onClickOfNo", null);
ModalConfirmationDelete = __decorate([
    Component
], ModalConfirmationDelete);
export default ModalConfirmationDelete;
