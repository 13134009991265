import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let IconAutoHardwareList = class IconAutoHardwareList extends Vue {
    iconSize;
    get computedBracketIconSize() {
        return this.iconSize;
    }
    get computedPartReferenceIconSize() {
        const sz = this.iconSize.replace('px', '');
        const iSz = parseInt(sz, 10);
        const newSize = iSz * 0.7;
        return `${newSize}px`;
    }
};
__decorate([
    Prop({
        type: String,
        default: '20px'
    }),
    __metadata("design:type", String)
], IconAutoHardwareList.prototype, "iconSize", void 0);
IconAutoHardwareList = __decorate([
    Component
], IconAutoHardwareList);
export default IconAutoHardwareList;
