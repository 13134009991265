import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let DeleteHardwareList = class DeleteHardwareList extends Mixins(BaseDelete) {
    // uses the prop id from the base
    get shouldDisableDeleteButton() {
        return !this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN) || this.isDeleting;
    }
    // called from ui
    onAffirmativeClicked() {
        this.deleteHardwareList();
    }
    async deleteHardwareList() {
        try {
            this.isDeleting = true;
            const resp = await this.$http.delete(`/hardware-lists/${this.id}`);
            if (resp) {
                this.$notification.add({
                    text: 'Hardware list deleted.',
                    type: AlertType.SUCCESS
                });
                this.emitDeletionComplete();
                this.showDialog = false;
            }
        }
        catch (err) {
            this.isDeleting = false;
            this.$notification.add({
                type: AlertType.ERROR,
                text: `This Hardware list is in use and cannot be deleted.`
            });
            this.onNegativeClicked();
        }
    }
};
DeleteHardwareList = __decorate([
    Component
], DeleteHardwareList);
export default DeleteHardwareList;
