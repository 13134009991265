var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import WagonWheelPropsMixin from '~/nasa_ui/mixins/WagonWheelPropsMixin';
import { CommentTypeDisplay } from '~/nasa_ui/types';
let ModalCommentCreate = class ModalCommentCreate extends Mixins(BaseModalWithUser, WagonWheelPropsMixin) {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    formData = {
        comment: '',
        subType: CommentType.GENERAL
    };
    commentTypeOverride;
    createCommentTypes;
    readOnly;
    replyToText;
    get computedCommentTypeOptions() {
        return this.createCommentTypes.map((commentType) => {
            return {
                displayText: CommentTypeDisplay.get(commentType),
                value: commentType
            };
        });
    }
    get shouldDisableCreateCommentButton() {
        return !this.formData.comment || !this.formData.subType;
    }
    created() {
        if (this.replyToText) {
            const lines = this.replyToText.split('\n');
            const newLines = lines.reduce((acc, curr) => {
                if (curr.startsWith('>')) {
                    const indentation = curr.split(' ')[0].length;
                    return { lines: [...acc.lines, curr], maxIndentation: indentation };
                }
                const newlyIndentedLine = `${'>'.repeat(acc.maxIndentation + 1)} ${curr}`;
                return { lines: [...acc.lines, newlyIndentedLine], maxIndentation: acc.maxIndentation };
            }, { lines: [], maxIndentation: 0 });
            this.formData.comment = newLines.lines.join('\n');
        }
    }
    async onClickOfCreate() {
        const params = this.getRelationshipsObj();
        if (!this.formData.comment || !this.formData.subType || !params) {
            return;
        }
        const commentPayload = {
            comment: this.formData.comment.trim(),
            subType: this.formData.subType
        };
        const resp = await this.$http.post('/comments', commentPayload, { params });
        if (!resp.data) {
            this.$errorUtility({ err: 'Failed to create comment.' });
            return;
        }
        this.emitModalClose();
        this.emitCommentCreated();
    }
    emitCommentCreated() {
        this.$notification.add({
            type: this.AlertType.SUCCESS,
            text: 'Created.'
        });
    }
    onChangeOfCommentTypeOverride(val) {
        if (val && this.formData) {
            this.formData.subType = val;
        }
    }
};
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalCommentCreate.prototype, "commentTypeOverride", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(CommentType);
        },
        type: Array
    }),
    __metadata("design:type", Array)
], ModalCommentCreate.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ModalCommentCreate.prototype, "readOnly", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], ModalCommentCreate.prototype, "replyToText", void 0);
__decorate([
    Emit('commentCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCommentCreate.prototype, "emitCommentCreated", null);
__decorate([
    Watch('commentTypeOverride', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ModalCommentCreate.prototype, "onChangeOfCommentTypeOverride", null);
ModalCommentCreate = __decorate([
    Component
], ModalCommentCreate);
export default ModalCommentCreate;
