var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import uniqBy from 'lodash/uniqBy';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserGroupType } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { objectArraysMatchOrder } from '~/nasa_ui/utils/helpers/objectArraysMatchOrder';
import { transformUserGroupResponses, transformUserGroupResponseWithRefs, transformUserGroupResponseWithSupportContexts } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { reorderGroupsByGroupCodeOrder } from '~/nasa_ui/utils/helpers/user';
let AppCosmicUserGroups = class AppCosmicUserGroups extends Mixins(ReadOnlyMixin, CurrentUserMixin, UserRepoMixin, BaseVue, HttpMixin) {
    matchingApprovalRoutingTemplates = [];
    selectedUserGroups = [];
    snackbar = false;
    snackbarMessage = '';
    showApprovalRoutingTemplateModal = false;
    showApprovalRoutingTemplateSearch = false;
    showUserSearch = false;
    showUserGroupSearch = false;
    // Use this if you are using this control to edit an ART. It will prevent an
    // unnecessary alert about your selected groups match said ART.
    currentArtId;
    disableToolbar;
    displayType;
    hideSaveAsArtButton;
    required;
    size;
    value;
    get computedFormWrapperSize() {
        return this.size;
    }
    get computedTableItems() {
        return transformUserGroupResponses(this.uniqueSetOfUserGroupsToSelect);
    }
    get existingUserGroups() {
        return this.uniqueSetOfUserGroupsToSelect.map((group) => group.code);
    }
    get existingUsers() {
        return this.selectedUserGroups
            .filter((group) => group.subType === UserGroupType.USER_DELEGATE && group.delegateGroupForUser?.id)
            .map((group) => group.delegateGroupForUser.id);
    }
    get isDisplayTypeExpansionPanel() {
        return this.displayType === AppDynamicWrapperType.EXPANSION_PANEL;
    }
    get isDisplayTypeFormWrapper() {
        return this.displayType === AppDynamicWrapperType.FORM_WRAPPER;
    }
    get isDisplayTypeNone() {
        return this.displayType === AppDynamicWrapperType.NONE;
    }
    get hasAppPageDescription_above_SearchUser__Slot() {
        return this.hasSlot('AppPageDescription_above_SearchUser');
    }
    get has_above_results__Slot() {
        return this.hasSlot('above_results');
    }
    get hasAppHeader__Slot() {
        return this.hasSlot('AppHeader');
    }
    get listContainsYourself() {
        // todo
        // return Boolean(this.storage.cache.users.find((user) => user.auid === this.currentUser?.auid));
        return false;
    }
    get shouldDisableCancelAddButton() {
        return (this.disableToolbar ||
            (!this.showApprovalRoutingTemplateSearch && !this.showUserGroupSearch && !this.showUserSearch));
    }
    get uniqueSetOfUserGroupsToSelect() {
        return uniqBy(this.selectedUserGroups, 'code').sort(sortObjectBy('name'));
    }
    created() {
        if (!this.value?.length) {
            return;
        }
        this.selectedUserGroups = [...this.value];
    }
    // allow external components to call this
    onClickOfCancelAdd() {
        this.showApprovalRoutingTemplateSearch = false;
        this.showUserGroupSearch = false;
        this.showUserSearch = false;
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
    onClickOfAddUser() {
        if (this.showUserSearch) {
            this.onClickOfCancelAdd();
            return;
        }
        this.showApprovalRoutingTemplateSearch = false;
        this.showUserGroupSearch = false;
        this.showUserSearch = true;
    }
    onClickOfAddUserGroup() {
        if (this.showUserGroupSearch) {
            this.onClickOfCancelAdd();
            return;
        }
        this.showApprovalRoutingTemplateSearch = false;
        this.showUserGroupSearch = true;
        this.showUserSearch = false;
    }
    onClickOfAddYourself() {
        if (!this.currentUserDelegateGroup) {
            console.error('Current user has missing delegate group.');
            return;
        }
        const delegateGroup = transformUserGroupResponseWithSupportContexts([this.currentUserDelegateGroup])[0];
        if (this.isUserGroupSelected(delegateGroup)) {
            return;
        }
        // array of one
        this.addSelectedUserGroups([delegateGroup]);
        this.snackbarMessage = 'Added your Delegate Group.';
        this.snackbar = true;
    }
    onClickOfAddApprovalRoutingTemplate() {
        if (this.showApprovalRoutingTemplateSearch) {
            this.onClickOfCancelAdd();
            return;
        }
        this.showApprovalRoutingTemplateSearch = true;
        this.showUserGroupSearch = false;
        this.showUserSearch = false;
    }
    async onClickOfApprovalRoutingTemplateResult(art) {
        if (!art.groupCodes?.length) {
            return;
        }
        try {
            const resp = await this.getUserGroupsByGroupCodes(art.groupCodes);
            if (resp?.data.results) {
                let transformedGroups = transformUserGroupResponses(resp.data.results);
                transformedGroups = reorderGroupsByGroupCodeOrder(transformedGroups, art.groupCodes) || [];
                if (transformedGroups.length) {
                    this.addSelectedUserGroups(transformedGroups);
                }
                this.snackbarMessage = 'Added User Groups from Template';
                this.snackbar = true;
            }
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: 'Failed to add User Groups. Unable to fetch User Group data.' });
        }
    }
    onClickOfClearAll() {
        this.selectedUserGroups = [];
    }
    onClickOfSaveAsApprovalRoutingTemplate() {
        this.showApprovalRoutingTemplateModal = true;
    }
    onClickOfUserGroupResult(userGroup) {
        if (this.isUserGroupSelected(userGroup)) {
            return;
        }
        this.snackbarMessage = 'Added User Group';
        this.snackbar = true;
        this.addSelectedUserGroups([userGroup]);
    }
    /**
     * Query for the user's delegate group and add that to the list of selected groups.
     */
    async onClickOfUserResult(user) {
        const resp = await this.httpGet(`/user-groups/delegate/${user.id}`, {
            message: { error: 'Failed to add Delegate User Group.' }
        });
        if (resp.data) {
            // an array of one
            const delegateUserGroup = transformUserGroupResponseWithRefs([resp.data]);
            this.addSelectedUserGroups(delegateUserGroup);
            this.snackbarMessage = `Added Delegate Group for ${user._fullName}.`;
            this.snackbar = true;
        }
    }
    emitInput(value) {
        return value;
    }
    async onChangeOfSelectedUserGroups(selectedUserGroups) {
        this.emitInput(selectedUserGroups);
        if (!selectedUserGroups.length) {
            this.matchingApprovalRoutingTemplates = [];
            return;
        }
        try {
            const groupCodes = selectedUserGroups.map((group) => group.code).filter((_) => _);
            const resp = await this.queryForIdenticalGroups(groupCodes);
            if (resp.data.approvalRoutingTemplates?.nodes.length) {
                this.matchingApprovalRoutingTemplates = resp.data.approvalRoutingTemplates.nodes
                    .filter((art) => art?.id !== this.currentArtId)
                    .map((art) => art?.name || '');
                return;
            }
            this.matchingApprovalRoutingTemplates = [];
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onChangeOfValue(userGroups) {
        // if value is different from the selected user groups, reassign the
        // selected user groups
        if (!objectArraysMatchOrder(userGroups, this.selectedUserGroups, 'code')) {
            this.selectedUserGroups = userGroups;
        }
    }
    /**
     * Adds to the selected user groups, disallowing dupes.
     */
    addSelectedUserGroups(userGroups) {
        const combinedGroups = [...this.selectedUserGroups, ...userGroups];
        this.selectedUserGroups = uniqBy(combinedGroups, (group) => group.code);
    }
    isUserGroupSelected(userGroup) {
        return Boolean(this.selectedUserGroups.find((selectedGroup) => selectedGroup.code === userGroup.code));
    }
    queryForIdenticalGroups(groupCodes) {
        return this.$apollo.query({
            query: gql `
        query GetApprovalRoutingTemplatesWithGroupCodes($groupCodes: [String]) {
          approvalRoutingTemplates(filter: { groupCodes: { equalTo: $groupCodes } }) {
            nodes {
              id
              name
              nodeId
            }
          }
        }
      `,
            variables: { groupCodes },
            fetchPolicy: 'network-only'
        });
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppCosmicUserGroups.prototype, "currentArtId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCosmicUserGroups.prototype, "disableToolbar", void 0);
__decorate([
    Prop({
        type: String,
        default: AppDynamicWrapperType.FORM_WRAPPER
    }),
    __metadata("design:type", String)
], AppCosmicUserGroups.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCosmicUserGroups.prototype, "hideSaveAsArtButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCosmicUserGroups.prototype, "required", void 0);
__decorate([
    Prop({
        type: String,
        default: '1'
    }),
    __metadata("design:type", String)
], AppCosmicUserGroups.prototype, "size", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppCosmicUserGroups.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppCosmicUserGroups.prototype, "emitInput", null);
__decorate([
    Watch('selectedUserGroups'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", Promise)
], AppCosmicUserGroups.prototype, "onChangeOfSelectedUserGroups", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppCosmicUserGroups.prototype, "onChangeOfValue", null);
AppCosmicUserGroups = __decorate([
    Component
], AppCosmicUserGroups);
export default AppCosmicUserGroups;
