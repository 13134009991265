var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-change_sets-base)",
      "modal-width": _vm.computedModalWidth,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(" Close "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_vm.transformedSelectedEntity ? _c('div', [_c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Item status"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._deliveryOrderLineItemStatus) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Item type"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._lineItemType) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Task owner"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._ownerContact) + " ")])])], 1)]), _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "DIL #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._deliveryItemsListNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-twelveth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "J Doc #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._jDocumentNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Shipper #"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._shipperNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "WBS"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._workBreakdownStructureNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Need"
    }
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._needType) + " ")])])], 1)]), _c('hr', {
    staticClass: "my-2"
  }), _c('AppFormWrapper', {
    attrs: {
      "size": "1",
      "color": "item_instances"
    }
  }, [_vm.transformedSelectedEntity.partReference && _vm.transformedSelectedEntity.partReference.itemInstanceId ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('AppItemInstanceDisplay', {
    attrs: {
      "id": _vm.transformedSelectedEntity.partReference.itemInstanceId,
      "hide-border-bottom": ""
    }
  })], 1)]) : _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Drawing number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference.drawingNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "AsBuilt"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._asBuiltNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Side"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._side) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Size"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._size) + " ")])])], 1), _c('div', {
    staticClass: "col-half"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Description"
    }
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._partReference._description))])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Serial"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._serialNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Lot"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._lotNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Class"
    }
  }, [_vm.transformedSelectedEntity._partReference._itemClasses.length ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._itemClasses.join(', ')) + " ")]) : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.DEFAULT_DASH))])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Tool?"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedSelectedEntity._partReference.isTool,
      "icon-size": "18px"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Calbration number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._calibrationNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Birth"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._birthDate) + " ")]), _vm.transformedSelectedEntity._partReference.birthDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._birthDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last calibration"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._lastCalibrationDate) + " ")]), _vm.transformedSelectedEntity._partReference.lastCalibrationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._lastCalibrationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Usage life expiry"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._usageLifeExpirationDate) + " ")]), _vm.transformedSelectedEntity._partReference.usageLifeExpirationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._usageLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Shelf life expiry"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._shelfLifeExpirationDate) + " ")]), _vm.transformedSelectedEntity._partReference.shelfLifeExpirationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._partReference._shelfLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Location"
    }
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._partReference._location))])], 1)])]), _c('hr', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._createdDateTime) + " ")]), _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._createdDateTimeDistanceInWordsFromToday) + " ")])])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Opened"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._openedDate) + " ")]), _vm.transformedSelectedEntity.openedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._openedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Closed"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._closedDate) + " ")]), _vm.transformedSelectedEntity.closedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._closedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Est. completion"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._projectedDate) + " ")]), _vm.transformedSelectedEntity.projectedDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._projectedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Need"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._needDate) + " ")]), _vm.transformedSelectedEntity.needDate ? _c('small', {
    staticClass: "d-block grey--text font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._needDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1)])], 1) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };