import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DESCRIPTION_COL, FILE_NAME_COL, FILE_TYPE_ICON_COL, SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { AttachmentRepoMixin, GetWorkOrderStepAttachments } from '~/nasa_ui/DAL/attachments';
import EntityLinksMixin from '~/nasa_ui/mixins/EntityLinksMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppFileAttachments = class AppFileAttachments extends Mixins(BaseCosmic, ReadOnlyMixin, EntityLinksMixin, AttachmentRepoMixin) {
    associatedAttachments = [];
    attachments = [];
    selectedAttachment = null;
    showDeleteConfirmation = false;
    showEditModal = false;
    tableTitle;
    hideTableTitle;
    get computedTableTitle() {
        return this.hideTableTitle ? null : this.tableTitle;
    }
    get tableHeaders() {
        return [{ ...SUB_TYPE_COL, width: '200px' }, DESCRIPTION_COL, FILE_NAME_COL, FILE_TYPE_ICON_COL];
    }
    get attachmentIds() {
        return this.attachments.map((a) => a.id);
    }
    get computedTableItems() {
        return transformAttachments(this.attachments);
    }
    created() {
        this.$listen('attachment-saved', this.fetchAttachments);
    }
    onClickOfDelete(row) {
        this.selectedAttachment = row;
        this.showDeleteConfirmation = true;
    }
    onClickOfEdit(row) {
        this.selectedAttachment = row;
        this.showEditModal = true;
    }
    onClickOfDownload(row) {
        window.open(row._downloadKey, '_blank');
    }
    async deleteFileAttachment() {
        if (!this.selectedAttachment) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'No file attachment selected.'
            });
            return;
        }
        try {
            // Nothing valuable returned
            await this.$http.delete(`/attachments/${this.selectedAttachment.id}`);
            this.reset();
            this.fetchAttachments();
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'File attachment deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    reset() {
        this.selectedAttachment = null;
        this.showEditModal = false;
        this.showDeleteConfirmation = false;
    }
    async fetchDocumentAttachments(docId) {
        const variables = { docId };
        const res = await this.getDocumentAttachments(variables);
        return (res?.documentById?.attachments?.nodes ?? []);
    }
    async fetchItemDrawingAttachments(itemDrawing) {
        const variables = { drawingNumber: itemDrawing.drawingNumber };
        const res = await this.getItemDrawingAttachments(variables);
        return (res?.itemDrawingByDrawingNumber?.attachments?.nodes ?? []);
    }
    async fetchItemMasterAttachments(itemMaster) {
        const variables = {
            drawingNumber: itemMaster.drawingNumber,
            asBuilt: itemMaster.asBuiltNumber,
            side: itemMaster.side
        };
        const res = await this.getItemMasterAttachments(variables);
        return (res?.itemMasterByDrawingNumberAndAsBuiltNumberAndSide?.attachments?.nodes ??
            []);
    }
    async fetchItemInstanceAttachments(itemInstance) {
        const variables = { id: itemInstance.id };
        const res = await this.getItemInstanceAttachments(variables);
        return (res?.itemInstanceById?.attachments?.nodes ?? []);
    }
    async fetchWorkOrderStepAttachments(workOrderStepId) {
        const variables = { id: workOrderStepId };
        const res = await this.$apollo.query({
            query: GetWorkOrderStepAttachments,
            variables
        });
        return (res.data?.workOrderStepById?.attachments?.nodes ?? []);
    }
    async fetchAttachments() {
        if (this.documentId) {
            this.attachments = await this.fetchDocumentAttachments(this.documentId);
        }
        else if (this.itemDrawing) {
            this.attachments = await this.fetchItemDrawingAttachments(this.itemDrawing);
        }
        else if (this.itemMaster) {
            this.attachments = await this.fetchItemMasterAttachments(this.itemMaster);
        }
        else if (this.itemInstance) {
            this.attachments = await this.fetchItemInstanceAttachments(this.itemInstance);
        }
        else if (this.workOrderStepId) {
            this.attachments = await this.fetchWorkOrderStepAttachments(this.workOrderStepId);
        }
    }
    async populateTable(val) {
        if (!val) {
            return;
        }
        this.fetchAttachments();
    }
};
__decorate([
    Prop({
        type: String,
        default: 'File attachments'
    }),
    __metadata("design:type", String)
], AppFileAttachments.prototype, "tableTitle", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachments.prototype, "hideTableTitle", void 0);
__decorate([
    Watch('documentId', { immediate: true }),
    Watch('itemDrawing', { immediate: true }),
    Watch('itemMaster', { immediate: true }),
    Watch('itemInstance', { immediate: true }),
    Watch('workOrderStepId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], AppFileAttachments.prototype, "populateTable", null);
AppFileAttachments = __decorate([
    Component
], AppFileAttachments);
export default AppFileAttachments;
