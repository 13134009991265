import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { IS_EXTERNAL_ICON_COL, SHELF_LIFE_EXP_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { DocumentHardwareListRepo } from '~/nasa_ui/DAL/document/HardwareLists';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType } from '~/nasa_ui/types';
import { getNextSequenceNumber } from '~/nasa_ui/utils/hardwareLists';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformCombinedHardwareListAssemblies, transformHardwareListAssemblies } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalWorkOrderStepHardwareManage = class ModalWorkOrderStepHardwareManage extends Mixins(BaseModal, BaseCosmic, DocumentHardwareListRepo, HardwareListRepo) {
    document = null;
    hardwareSelectionMode = 0;
    selectedCalloutHardware = [];
    snackbar = false;
    snackbarMessage = '';
    step = null;
    computedHardwarePickListTableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        IS_EXTERNAL_ICON_COL,
        SHELF_LIFE_EXP_DATE_COL
    ];
    docId;
    stepId;
    isToolsList;
    get existingCalloutHardwareValues() {
        if (!this.selectedCalloutHardware) {
            return null;
        }
        return this.selectedCalloutHardware.filter((hw) => Boolean(hw.id)).map((inv) => inv.id);
    }
    get selectedCalloutHardwareTableItems() {
        return transformHardwareListAssemblies(this.selectedCalloutHardware ?? []);
    }
    get combinedList() {
        if (this.isToolsList) {
            const doc = this.document;
            const cosmicDoc = doc?.cosmicDocumentDr || doc?.cosmicDocumentTps;
            return cosmicDoc?.combinedToolsList.nodes ?? [];
        }
        else {
            const doc = this.document;
            const cosmicDoc = doc?.cosmicDocumentDr || doc?.cosmicDocumentTps;
            return cosmicDoc?.combinedHardwareList.nodes ?? [];
        }
    }
    get computedHardwarePickListItems() {
        const combinedHardwareList = this.combinedList;
        const transformedItems = transformCombinedHardwareListAssemblies(combinedHardwareList);
        const unpickedItems = transformedItems.filter((item) => {
            return !this.selectedCalloutHardware?.find((selectedItem) => {
                if (selectedItem.inventory?.id && item.inventory?.id) {
                    return selectedItem.inventory?.id === item.inventory?.id;
                }
                else {
                    return this.areToolsEqual(item, selectedItem);
                }
            });
        });
        return unpickedItems;
    }
    get overrideHardwareSelectorViewToManualMode() {
        return this.hardwareSelectionMode === 2;
    }
    get isHardwareSelectionModeInventory() {
        return this.hardwareSelectionMode === 1 || this.hardwareSelectionMode === 2;
    }
    async created() {
        if (this.isToolsList) {
            const data = await this.getDocumentAndWorkOrderStepTools({ docId: this.docId, stepId: this.stepId });
            this.document = data?.documentById;
            this.step = data?.workOrderStepById;
            this.selectedCalloutHardware = transformHardwareListAssemblies(data?.workOrderStepById?.toolsList?.hardwareListAssemblies.nodes ?? []);
        }
        else {
            const data = await this.getDocumentAndWorkOrderStepHardware({ docId: this.docId, stepId: this.stepId });
            this.document = data?.documentById;
            this.step = data?.workOrderStepById;
            this.selectedCalloutHardware = transformHardwareListAssemblies(data?.workOrderStepById?.hardwareList?.hardwareListAssemblies.nodes ?? []);
        }
    }
    onClickOfHardwarePickListItem(item) {
        if (this.selectedCalloutHardware) {
            this.selectedCalloutHardware.push(item);
        }
        else {
            this.selectedCalloutHardware = [item];
        }
        this.showSnackbar('Hardware added');
    }
    onClickOfRemoveHardwarePickListItem(item) {
        if (item.isExternal && !item.id) {
            this.selectedCalloutHardware =
                this.selectedCalloutHardware?.filter((i) => {
                    return (i._description !== item._description &&
                        i.drawingNumber !== item.drawingNumber &&
                        i.serialNumber !== item.serialNumber);
                }) ?? [];
        }
        else {
            this.selectedCalloutHardware =
                this.selectedCalloutHardware?.filter((i) => {
                    return i.id !== item.id;
                }) ?? [];
        }
    }
    showSnackbar(message) {
        this.snackbar = true;
        this.snackbarMessage = message;
    }
    onHardwareViewModeChange(mode) {
        if (this.isHardwareSelectionModeInventory) {
            if (mode === 'MANUAL') {
                this.hardwareSelectionMode = 2;
            }
            else if (mode === 'INVENTORY') {
                this.hardwareSelectionMode = 1;
            }
        }
    }
    async onClickOfSave() {
        let list;
        if (this.isToolsList) {
            const step = this.step;
            list = step?.toolsList;
        }
        else {
            const step = this.step;
            list = step?.hardwareList;
        }
        if (!list?.id) {
            return Promise.resolve([]);
        }
        const assemblies = list.hardwareListAssemblies?.nodes;
        const itemsToAdd = this.selectedCalloutHardware?.filter((formItem) => {
            return !list.hardwareListAssemblies?.nodes.find((existingItem) => existingItem?.id === formItem.id);
        });
        const itemsToDelete = list.hardwareListAssemblies?.nodes.filter((existingItem) => {
            return !this.selectedCalloutHardware?.find((formItem) => existingItem?.id === formItem.id);
        });
        const addRequests = itemsToAdd?.map((item, i) => {
            const sequenceAddOn = i + 1;
            return this.createHardwareListAssembly({
                input: this.buildCreateAssemblyPayload(list.id, item, assemblies, sequenceAddOn)
            });
        }) ?? [];
        const deleteRequests = itemsToDelete?.map((item) => {
            return this.deleteHardwareListAssemblyById({ id: item?.id });
        }) ?? [];
        const responses = await Promise.all([...deleteRequests, ...addRequests]);
        this.$notification.add({ type: AlertType.SUCCESS, text: 'Hardware updated.' });
        this.$emit('saved');
        return responses;
    }
    buildCreateAssemblyPayload(hardwareListId, calloutItem, currentAssemblyList, sequenceAddOn = 1) {
        const nextSequence = getNextSequenceNumber(currentAssemblyList, sequenceAddOn);
        return {
            hardwareListAssembly: {
                hardwareListId,
                externalCalibrationDate: calloutItem.externalCalibrationDate,
                externalCalibrationNumber: calloutItem.externalCalibrationNumber,
                externalClass: calloutItem.externalClass ? [calloutItem.externalClass] : [],
                externalDescription: calloutItem.externalDescription,
                externalDrawingNumber: calloutItem.externalDrawingNumber,
                externalIsTool: calloutItem.externalIsTool,
                externalLotNumber: calloutItem.externalLotNumber,
                externalSerialNumber: calloutItem.externalSerialNumber,
                externalShelfLifeDate: calloutItem.externalShelfLifeDate,
                itemInstanceId: calloutItem.itemInstanceId ? null : calloutItem.itemInstanceId || calloutItem.id,
                quantity: calloutItem.quantity ?? calloutItem.inventory?.quantity ?? 1,
                sequence: nextSequence
            }
        };
    }
    areToolsEqual(a, b) {
        if (a?.itemInstanceId || b?.itemInstanceId) {
            return false;
        }
        return (a?.externalDescription === b?.externalDescription &&
            a?.externalSerialNumber === b?.externalSerialNumber &&
            a?.externalDrawingNumber === b?.externalDrawingNumber);
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], ModalWorkOrderStepHardwareManage.prototype, "docId", void 0);
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepHardwareManage.prototype, "stepId", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalWorkOrderStepHardwareManage.prototype, "isToolsList", void 0);
ModalWorkOrderStepHardwareManage = __decorate([
    Component
], ModalWorkOrderStepHardwareManage);
export default ModalWorkOrderStepHardwareManage;
