var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "modal-width": "50vw",
      "persistent": "",
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "input": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', [_vm.isCommentEmpty ? _c('div', [_vm._v(" > "), _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("This Comment is empty and can be deleted by any User.")])], 1) : _vm._e(), _vm.isCommentImmutable ? [_c('div', [_c('Alert', {
          staticClass: "mx-2",
          attrs: {
            "type": _vm.AlertType.ERROR
          }
        }, [_vm._v("This Comment cannot be updated.")])], 1), _c('div', [_c('AppBlockquote', {
          staticClass: "ml-2",
          attrs: {
            "title": "Comment type",
            "value": _vm.subTypeDisplay
          }
        })], 1), _vm.selectedComment ? _c('div', [_c('AppBlockquote', {
          staticClass: "ml-2",
          attrs: {
            "title": "Comment"
          }
        }, [_c('AppMarkdownDisplay', {
          attrs: {
            "title": "Comment",
            "markdown": _vm.selectedComment.comment
          }
        })], 1)], 1) : _vm._e()] : _vm._e(), !_vm.isCommentImmutable ? _c('div', {
          staticClass: "c-grid"
        }, [_c('div', {
          staticClass: "col-half mb-2"
        }, [_c('AppSelect', {
          attrs: {
            "required": "",
            "chips": false,
            "disabled": _vm.readOnly,
            "selectOptions": _vm.computedCommentTypeOptions,
            "display": _vm.CommentTypeDisplay,
            "label": "Comment type"
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1)]) : _vm._e(), !_vm.isCommentImmutable ? _c('AppMarkdown', {
          attrs: {
            "read-only": _vm.readOnly,
            "label": "Comment",
            "required": ""
          },
          model: {
            value: _vm.formData.comment,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "comment", $$v);
            },
            expression: "formData.comment"
          }
        }) : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [!_vm.isCommentImmutable ? _c('ButtonSave', {
          staticClass: "ml-0",
          attrs: {
            "disabled": !_vm.isFormValid || _vm.isCommentImmutable
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfUpdate();
            }
          }
        }) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(_vm._s(_vm.isCommentImmutable ? 'Close' : 'Cancel'))])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };