import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { BASE_NUMBER_COL, NAME_DISPLAY_COL, SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformHardwareListTemplateResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchHardwareListTemplate = class SearchHardwareListTemplate extends Mixins(BaseSearch) {
    rawSearchResponse = null;
    selectedHardwareListTemplate = null;
    tableHeaders = [
        NAME_DISPLAY_COL,
        composeTableHeader(SUB_TYPE_COL, {
            text: 'Template<br/>type',
            width: '100px'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Templated<br/>items',
            value: '_slotCount',
            width: '100px'
        })
    ];
    searchFilters = {};
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformHardwareListTemplateResponses(this.rawSearchResponse);
        transformed.sort(sortObjectNumericallyBy('_createdDateTimeTicks'));
        return transformed;
    }
    onClickOfHardwareListTemplate(val) {
        this.selectedHardwareListTemplate = val;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                query: this.searchInput,
                take: -1
            };
            // Execute query
            const resp = await this.$http.get(`/hardware-list-templates`, {
                params
            });
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = resp.data.results;
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected err: ${err}`);
            }
        }
    }
};
__decorate([
    DebounceAll(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchHardwareListTemplate.prototype, "performSearch", null);
SearchHardwareListTemplate = __decorate([
    Component
], SearchHardwareListTemplate);
export default SearchHardwareListTemplate;
