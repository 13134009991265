import { __decorate, __metadata } from "tslib";
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { transformHardwareListPartResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
/**
 * This component either shows an ItemInstance level AHD
 *  or
 * shows the  properties of the model "HardwareListPartReference"
 */
let ModalHardwareListPartInfo = class ModalHardwareListPartInfo extends Mixins(BaseModal, BaseCosmic) {
    hardwareList = null;
    DEFAULT_DASH = DEFAULT_DASH;
    hardwareListId;
    hardwareListPartId;
    get isExternal() {
        return !this.transformedHardwareListPart?.partReference.itemInstanceId;
    }
    get hardwareListPart() {
        return this.hardwareListPartId
            ? this.hardwareList?.hardwareListParts?.find((hlp) => hlp.id === this.hardwareListPartId)
            : null;
    }
    /**
     * The idea here is to differentiate a "real" II from a external ii
     */
    get computedModalWidth() {
        return this.isExternal ? '60vw' : '99vw';
    }
    get transformedHardwareListPart() {
        return this.hardwareListPart ? transformHardwareListPartResponses([this.hardwareListPart])[0] : null;
    }
    async fetchHardwareListById() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware-lists/${this.hardwareListId}`);
            this.hardwareList = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalHardwareListPartInfo.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalHardwareListPartInfo.prototype, "hardwareListPartId", void 0);
__decorate([
    DebounceAll(10),
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListPartInfo.prototype, "fetchHardwareListById", null);
ModalHardwareListPartInfo = __decorate([
    Component
], ModalHardwareListPartInfo);
export default ModalHardwareListPartInfo;
